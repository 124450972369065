/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:c0336e95-dbc0-489d-b990-02b8e48b9325",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_Zjz3pxGpw",
    "aws_user_pools_web_client_id": "jlo6atf3i86sfvcqgenal8ftl",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://pmzrxuwtd5g2ndnxmu43zwiudq.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://wwusant8zj.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ],
    "aws_user_files_s3_bucket": "vqaas-storage100620-prod",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
