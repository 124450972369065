import React from "react"

function S3FolderEntry(props) {
    return (
        <tr>
            <td>
                <h5
                    className="btn-link"
                    data-folder={props.s3_object.path}
                    onClick={props.onFolderClick}>
                    {
                        props.s3_object.path ?
                            props.s3_object.path:
                            <div className="uil-reload-css reload-small mr-1 "><div /></div>
                    }
                </h5>
            </td>
            <td/>
            <td/>
            <td/>
        </tr>
    )
}

export default S3FolderEntry