import React from "react";

// reactstrap components
import {
  Table,
} from "reactstrap";
import ResolutionCell from "../../components/vqaas/ResolutionCell";
import FramerateCell from "./FramerateCell";
import PixelFormatCell from "./PixelFormatCell";
import CodecCell from "./CodecCell";
import DurationTimeCell from "./DurationTimeCell";
import DurationFramesCell from "./DurationFramesCell";
import CreatedAtCell from "./CreatedAtCell";
import VmafCell from "./VmafCell";
import PsnrCell from "./PsnrCell";


function CompareSpecs(props) {
  return (
      <>
        <h4 className="title">
          <small>Specifications</small>
        </h4>
        <Table responsive>
          <thead>
          <tr>
            <th/>
            <th>{props.distorted1 && props.distorted1.filename}</th>
            <th>{props.distorted2 && props.distorted2.filename}</th>
            <th>{props.distorted3 && props.distorted3.filename}</th>
            <th>{props.distorted4 && props.distorted4.filename}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Legend</td>
            <td>{(props.distorted1) ? <div className="box syna_black" /> : ''}</td>
            <td>{(props.distorted2) ? <div className="box syna_yellow" /> : ''}</td>
            <td>{(props.distorted3) ? <div className="box syna_blue" /> : ''}</td>
            <td>{(props.distorted4) ? <div className="box syna_red" /> : ''}</td>
          </tr>
          <tr>
            <td>Resolution</td>
            <ResolutionCell distorted={props.distorted1}/>
            <ResolutionCell distorted={props.distorted2}/>
            <ResolutionCell distorted={props.distorted3}/>
            <ResolutionCell distorted={props.distorted4}/>
          </tr>
          <tr>
            <td>Framerate</td>
            <FramerateCell distorted={props.distorted1}/>
            <FramerateCell distorted={props.distorted2}/>
            <FramerateCell distorted={props.distorted3}/>
            <FramerateCell distorted={props.distorted4}/>
          </tr>
          <tr>
            <td>Pixel format</td>
            <PixelFormatCell distorted={props.distorted1}/>
            <PixelFormatCell distorted={props.distorted2}/>
            <PixelFormatCell distorted={props.distorted3}/>
            <PixelFormatCell distorted={props.distorted4}/>
          </tr>
          <tr>
            <td>Codec</td>
            <CodecCell distorted={props.distorted1}/>
            <CodecCell distorted={props.distorted2}/>
            <CodecCell distorted={props.distorted3}/>
            <CodecCell distorted={props.distorted4}/>
          </tr>
          <tr>
            <td>Duration</td>
            <DurationTimeCell distorted={props.distorted1}/>
            <DurationTimeCell distorted={props.distorted2}/>
            <DurationTimeCell distorted={props.distorted3}/>
            <DurationTimeCell distorted={props.distorted4}/>
          </tr>
          <tr>
            <td>Duration</td>
            <DurationFramesCell distorted={props.distorted1}/>
            <DurationFramesCell distorted={props.distorted2}/>
            <DurationFramesCell distorted={props.distorted3}/>
            <DurationFramesCell distorted={props.distorted4}/>
          </tr>
          <tr>
            <td>Upload Date</td>
            <CreatedAtCell distorted={props.distorted1}/>
            <CreatedAtCell distorted={props.distorted2}/>
            <CreatedAtCell distorted={props.distorted3}/>
            <CreatedAtCell distorted={props.distorted4}/>
          </tr>
          <tr>
            <td>VMAF mean</td>
            <VmafCell distorted={props.distorted1}/>
            <VmafCell distorted={props.distorted2}/>
            <VmafCell distorted={props.distorted3}/>
            <VmafCell distorted={props.distorted4}/>
          </tr>
          <tr>
            <td>PSNR mean</td>
            <PsnrCell distorted={props.distorted1}/>
            <PsnrCell distorted={props.distorted2}/>
            <PsnrCell distorted={props.distorted3}/>
            <PsnrCell distorted={props.distorted4}/>
          </tr>
          </tbody>
        </Table>
      </>
  );
}

export default CompareSpecs;
