import React, {useState} from "react";
// reactstrap components
import {
    Container,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import MediaPageHeader from "components/Headers/MediaPageHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import {API, Auth, graphqlOperation} from "aws-amplify";
import S3Browser from "../../components/vqaas/S3Browser";
import {createReference} from "../../graphql/mutations";
import {v4 as uuid} from "uuid";

const getAccountWithSubscriptionsTeamsS3 = /* GraphQL */ `
    query GetAccount(
      $id: ID!
      $crossNextToken: String) {
          getAccount(id: $id) {
            id
            email
            owner
            s3_bucket
            s3_bucket_region
            subscriptions {
              items {
                subscription {
                  name
                  id
                }
              }
            }
            teams {
              items {
                team {
                  name
                  id
                }
              }
            }
            following {
              name
              ownerID
              accepted
            }
            followed_by {
              name
              ownerID
              accepted
            }
            cross_account_s3(nextToken: $crossNextToken) {
              items {
                id
                Key
                ETag
                Size
                LastModified
                LastSeen
                accountID
                referenceID
                createdAt
                updatedAt
              }
              nextToken
            }
            credits
            createdAt
            updatedAt
          }
    }
  `;
const onCreateCrossAccountS3 = /* GraphQL */ `
  subscription OnCreateCrossAccountS3 {
    onCreateCrossAccountS3 {
      id
      Key
      ETag
      Size
      LastModified
      LastSeen
      accountID
      referenceID
      createdAt
      updatedAt
    }
  }
`;
const onDeleteCrossAccountS3 = /* GraphQL */ `
  subscription OnDeleteCrossAccountS3 {
    onDeleteCrossAccountS3 {
      id
      Key
      ETag
      Size
      LastModified
      LastSeen
    }
  }
`;
const onUpdateCrossAccountS3 = /* GraphQL */ `
  subscription OnUpdateCrossAccountS3 {
    onUpdateCrossAccountS3 {
      id
      Key
      ETag
      Size
      LastModified
      LastSeen
    }
  }
`;

function S3Page() {
    const [account, setAccount] = useState();
    const [s3References, setS3References] = useState([])

    function dynamicSort(property) {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            /* next line works with strings and numbers,
             * and you may want to customize it to your needs
             */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    React.useEffect(() => {
        async function getMyAccount() {
            let cross_items = []
            let nextToken = null
            let myAccount
            const user = await Auth.currentAuthenticatedUser()
            do {
                const result = await API.graphql(graphqlOperation(getAccountWithSubscriptionsTeamsS3, {
                    id: user.username,
                    crossNextToken: nextToken
                }))
                nextToken = result.data.getAccount.cross_account_s3.nextToken
                myAccount = result.data.getAccount
                cross_items = cross_items.concat(result.data.getAccount.cross_account_s3.items)
            } while (nextToken != null)
            setAccount(myAccount)
            console.log(cross_items)
            setS3References(cross_items.sort(dynamicSort("Key")))
        }
        getMyAccount().then()
    }, [])

    React.useEffect(() => {
        let create_subscription
        let delete_subscription
        let update_subscription

        let mounted = true
        async function setupSubscription() {
            console.log(account.id)
            create_subscription = API.graphql(graphqlOperation(onCreateCrossAccountS3)).subscribe({
                next: (data) => {
                    if (mounted) {
                        const cross = data.value.data.onCreateCrossAccountS3
                        console.log(data)
                        if (!cross) return
                        setS3References(prevCrosses => prevCrosses.concat([cross]))
                    }
                },
                error: error => console.warn(error)
            })
            update_subscription = API.graphql(graphqlOperation(onUpdateCrossAccountS3)).subscribe({
                next: (data) => {
                    if (mounted) {
                        console.log(data)
                    }
                },
                error: error => console.warn(error)
            })
            delete_subscription = API.graphql(graphqlOperation(onDeleteCrossAccountS3)).subscribe({
                next: (data) => {
                    if (mounted){
                        const cross = data.value.data.onDeleteCrossAccountS3
                        if (!cross) return
                        setS3References(prevCrosses => [...prevCrosses.filter(r => {
                            return (
                                r.id !== cross.id
                            )
                        })])
                    }
                }
            })
        }
        if (account) {
            setupSubscription()
        }
        return () => {
            mounted = false
            if (create_subscription){
                create_subscription.unsubscribe()
            }
            if (delete_subscription){
                delete_subscription.unsubscribe()
            }
            if (update_subscription){
                update_subscription.unsubscribe()
            }
        }
    }, [account])

    const onImportHandle = async (e) => {
        console.log(e.target.attributes)
        const s3_object_id = e.target.attributes.getNamedItem('data-s3_object_id').value
        const s3_object_key = e.target.attributes.getNamedItem('data-s3_object_key').value
        const addReference = {
            cross_account_s3_id: s3_object_id,
            id: uuid(),
            accountID: account.id,
            owner: account.id,
            filename: "arn:aws:s3:::" + account.s3_bucket + "/" + s3_object_key
        }
        console.log("Import S3", addReference)
        //setShowButton(false)
        await API.graphql({ query: createReference, variables: { input: addReference } });
        setS3References(prevState => {
            return prevState.map(prevS3Ref => {
                if (prevS3Ref.id === s3_object_id) {
                    prevS3Ref.Uploaded = true
                }
                return prevS3Ref
            })
        })
    }

    return (
        <>
            <ColorNavbar account={account} />
            <MediaPageHeader />
            <div className="section">
                <Container className="tim-container">
                    <div className="title">
                        <h3 id="ReferenceLibrary">Cross account S3 bucket</h3>
                    </div>
                    {account ?
                        <S3Browser
                            onImportHandle={onImportHandle}
                            account={account}
                            s3_objects={s3References}
                        />
                        : <></>}
                </Container>
            </div>
            <FooterBlack />
        </>
    );
}

export default S3Page;
