import React from "react";

// reactstrap components
import {
    Table,
} from "reactstrap";

// core components
import AccountTableEntry from "../../components/vqaas/AccountTableEntry";

function AccountTable(props) {
    // console.log(props)

    const tableEntries = props.accounts.Users.map(userData =>
        <AccountTableEntry
            key={userData.Username}
            userData={userData} />)

    return (
        <>
            <Table responsive>
                <thead>
                <tr>
                    <th className="text-center">#</th>
                    <th>Email</th>
                    <th>User Status</th>
                    <th>Create Date</th>
                    <th className="text-right">Actions</th>
                </tr>
                </thead>
                <tbody>
                {tableEntries}
                </tbody>
            </Table>
        </>
    );
}

export default AccountTable;
