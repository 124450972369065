import React from "react";

function FramerateCell(props) {
  return (
      <>
        <td>
          {
            props.distorted ?
                props.distorted.spec_frame_rate + ' fps'
                :
                ''
          }
        </td>
      </>
  );
}

export default FramerateCell;
