import React, {useState} from "react";
// reactstrap components
import {
    Row,
    Col, Button,
} from "reactstrap";

// core components
import {API} from "aws-amplify";
import S3Table from "../../components/vqaas/S3Table";
import {updateAccount} from "../../graphql/mutations";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

function S3Browser(props) {
    const [showRefresh, setShowRefresh] = useState(true)
    const [s3Path, setS3Path] = useState([])
    const [s3ActivePath, setS3ActivePath] = useState('')
    const [s3Folders, setS3Folders] = useState([])
    const [s3Objects, setS3Objects] = useState([])

    React.useEffect(() => {
        let currentPath= ''
        if (s3Path.length){
            currentPath= s3Path.join('/')
            if (s3ActivePath){
                currentPath = currentPath + '/' + s3ActivePath + '/'
            }
        } else {
            if (s3ActivePath){
                currentPath = s3ActivePath + '/'
            }
        }
        let lookForLevel = currentPath.split('/').length
        let objectList = []
        let folderList = []
        let folderSet = new Set()
        for (let i=0; i < props.s3_objects.length; i++){
            if (props.s3_objects[i].Key.startsWith(currentPath)){
                let level = props.s3_objects[i].Key.split('/').length
                if (props.s3_objects[i].Key.endsWith('/')){
                    if (level > lookForLevel){
                        folderSet.add(props.s3_objects[i].Key.split('/')[lookForLevel-1])
                    }
                } else {
                    if (level === lookForLevel){
                        objectList = objectList.concat(props.s3_objects[i])
                    }
                    if (level > lookForLevel){
                        folderSet.add(props.s3_objects[i].Key.split('/')[lookForLevel-1])
                    }
                }
            }
        }
        folderList = []
        folderSet.forEach(folder => {
            let folderObject = {
                id: folder,
                path: folder
            }
            folderList.push(folderObject)}
        )
        setS3Objects(objectList)
        setS3Folders(folderList)
        // console.log(folderList, objectList)
    }, [s3ActivePath, s3Path, props.s3_objects])

    const onRefresh = async () => {
        const time_now = Date.now()
        const time_now_str = time_now.toISOString
        const updateAccountInput = {
            id: props.account.id,
            refresh: time_now_str
        }
        if(showRefresh){
            setShowRefresh(false)
            setTimeout(() => setShowRefresh(true), 10000)
        }
        await API.graphql({ query: updateAccount, variables: { input: updateAccountInput } });
    }

    const onClickS3PathItem = async (e) => {
        let dataLevel = e.target.attributes.getNamedItem('data-level').value
        if (dataLevel === "path"){
            let dataIndex = e.target.attributes.getNamedItem('data-index').value
            let dataName = e.target.attributes.getNamedItem('data-name').value
            // console.log(dataIndex, dataName)
            if (e.target) {
                if (dataIndex) {
                    setS3ActivePath(dataName)
                    setS3Path(prevS3Path => [...prevS3Path.slice(0, parseInt(dataIndex))])
                }
            }
        }
        if (dataLevel === "bucket"){
            setS3ActivePath('')
            setS3Path([])
        }
    }

    const onFolderClick = async (e) => {
        // console.log(e.target.attributes.getNamedItem('data-folder').value);
        setS3Path(prevS3Path => {
            // console.log(prevS3Path)
            if (s3ActivePath === '') {
                return [...prevS3Path]
            } else {
                return [...prevS3Path, s3ActivePath]
            }
        })
        setS3ActivePath(e.target.attributes.getNamedItem('data-folder').value)
    }

    const BreadcrumbTrail = s3Path.map((s3PathItem, index) =>
        <BreadcrumbItem
            key={s3PathItem}
            data-level="path"
            data-index={index}
            data-name={s3PathItem}
            className="btn-link">
            {s3PathItem}
        </BreadcrumbItem>
    )

    return (
        <>
            <Row>
                <Col md={10}>
                    <Breadcrumb
                        onClick={onClickS3PathItem}>
                        <BreadcrumbItem
                            className="btn-link"
                            data-level="root">Cross Account S3</BreadcrumbItem>
                        <BreadcrumbItem
                            className="btn-link"
                            data-level={"bucket"}>{props.account.s3_bucket}</BreadcrumbItem>
                        {BreadcrumbTrail}
                        <BreadcrumbItem
                            className="btn-link"
                            data-level={"active"}
                            active>{s3ActivePath}</BreadcrumbItem>
                    </Breadcrumb>
                </Col>
                <Col md={2} >
                    {showRefresh ?
                        <Button
                            style={{float: 'right'}}
                            className="btn-neutral"
                            color="info"
                            size="sm"
                            type="button"
                            onClick={onRefresh}
                        >
                            <i className="nc-icon nc-refresh-69" />
                        </Button> : <p style={{float: 'right'}}>Refreshing...</p>}
                </Col>
            </Row>
            <Row>
                <Col className="ml-auto mr-auto" md="12">
                    <S3Table
                        onImportHandle={props.onImportHandle}
                        s3Folders={s3Folders}
                        s3Objects={s3Objects}
                        onFolderClick={onFolderClick}
                    />
                </Col>
            </Row>
        </>
    );
}

export default S3Browser;
