import React from "react";

// reactstrap components
import {
  Table,
} from "reactstrap";

// core components
import ReferenceTableEntry from "../../components/vqaas/ReferenceTableEntry";

function ReferenceTable(props) {
  const tableEntries = props.references.map(referenceData =>
      <ReferenceTableEntry
          key={referenceData.id}
          reference={referenceData} />)

  return (
      <>
        <Table responsive>
          <thead>
          <tr>
            <th className="text-center">#</th>
            <th>Filename</th>
            <th>Resolution</th>
            <th>Framerate</th>
            <th className="text-right">Actions</th>
          </tr>
          </thead>
          <tbody>
          {tableEntries}
          </tbody>
        </Table>
      </>
  );
}

export default ReferenceTable;
