import React from 'react';
import './App.css';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Index from "./views/Index.js";
import Error404 from "./views/examples/Error404";
import Error422 from "./views/examples/Error422";
import Error500 from "./views/examples/Error500";
import LandingPage from "./views/examples/LandingPage";
import LoginPage from "./views/examples/LoginPage";
import MediaPage from "./views/examples/MediaPage";
import ReferencePage from "./views/examples/ReferencePage";
import TeamPage from "./views/examples/TeamPage";
import SubscriptionPage from "./views/examples/SubscriptionPage";
import DistortedPage from "./views/examples/DistortedPage";
import ComparePage from "./views/examples/ComparePage";
import ProfilePage from "./components/vqaas/ProfilePage";
import SettingsPage from "./components/vqaas/Settings";
import ManageSubscription from "./views/examples/ManageSubscription";
import AddSubscription from "./views/examples/AddSubscription";
import AddTeam from "./views/examples/AddTeam";
import AddAccount from "./views/examples/AddAccount";
import ListAccounts from "./views/examples/ListAccounts";
import DashboardAccounts from "./views/examples/DashboardAccounts";
import { withAuthenticator,  } from '@aws-amplify/ui-react'
import S3Page from "./views/examples/S3Page";


const App = () => (
    <BrowserRouter>
      <Switch>
        <Route path="/index" render={(props) => <Index {...props} />} />
        <Route path="/reference/:reference_id"  render={(props) => <ReferencePage {...props} />} />
        <Route path="/subscription/:subscription_id"  render={(props) => <SubscriptionPage {...props} />} />
        <Route path="/team/:team_id"  render={(props) => <TeamPage {...props} />} />
        <Route path="/distorted/:reference_id/:distorted_id"  render={(props) => <DistortedPage {...props} />} />
        <Route
            path="/compare/:reference_id/:distorted_id1/:distorted_id2/:distorted_id3?/:distorted_id4?"
            render={(props) => <ComparePage {...props} />}
        />
        <Route path='/synamedia' component={() => {
          window.location.href = 'https://www.synamedia.com/video-solutions/video-network/';
          return null;
        }}/>
        <Route path="/error-404" render={(props) => <Error404 {...props} />} />
        <Route path="/error-422" render={(props) => <Error422 {...props} />} />
        <Route path="/error-500" render={(props) => <Error500 {...props} />} />
        <Route
            path="/landing-page"
            render={(props) => <LandingPage {...props} />}
        />
        <Route path="/login-page" render={(props) => <LoginPage {...props} />} />
        <Route
            path="/media-page"
            render={(props) => <MediaPage {...props} />}
        />
        <Route
            path="/s3-page"
            render={(props) => <S3Page {...props} />}
        />
        <Route
            path="/manage-subscription"
            render={(props) => <ManageSubscription {...props} />}
        />
        <Route
            path="/profile-page"
            render={(props) => <ProfilePage {...props} />}
        />
        <Route
            path="/settings-page"
            render={(props) => <SettingsPage {...props} />}
        />
        <Route
            path="/add-subscription"
            render={(props) => <AddSubscription {...props} />}
        />
        <Route
            path="/add-team"
            render={(props) => <AddTeam {...props} />}
        />
        <Route
            path="/add-account"
            render={(props) => <AddAccount {...props} />}
        />
        <Route
            path="/list-accounts"
            render={(props) => <ListAccounts {...props} />}
        />
        <Route
            path="/dashboard-accounts"
            render={(props) => <DashboardAccounts {...props} />}
        />
        <Redirect to="/media-page" />
      </Switch>
    </BrowserRouter>
)

//export default App;
export default withAuthenticator(App);
