import React from "react";

function PixelFormatCell(props) {
  return (
      <>
        <td>
          {
            props.distorted ?
                props.distorted.spec_pix_fmt
                :
                ''
          }
        </td>
      </>
  );
}

export default PixelFormatCell;
