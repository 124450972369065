import React, {useState} from "react"
import {Button, UncontrolledTooltip} from "reactstrap";
import {Link} from "react-router-dom";

function AccountTableEntry(props) {
    const [email, setEmail] = useState("")
    // console.log(props.userData)
    React.useEffect(() => {
        for (let i=0; i< props.userData.Attributes.length ; i++){
            if (props.userData.Attributes[i].Name === "email"){
                setEmail(props.userData.Attributes[i].Value)
            }
        }
    }, [props])

    return (
        <tr>
            <td className="text-center">
                <Link to={"/reference/" + props.userData.Username} >
                    {props.userData.Username}
                </Link>
            </td>
            <td>{email}</td>
            <td>
                {
                    props.userData.UserStatus
                }
            </td>
            <td>
                {
                    props.userData.UserCreateDate
                }
            </td>
            <td className="td-actions text-right">
                <Link to={"/reference/" + props.userData.id} >
                    <Button
                        className="btn-link mr-1"
                        color="info"
                        data-toggle="tooltip"
                        id="tooltip542628903"
                        to={"/reference/" + props.userData.id}
                        size="sm"
                        type="button"
                    >
                        <i className="fas fa-external-link-alt" />
                    </Button>
                    <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target="tooltip542628903"
                    >
                        Open Reference
                    </UncontrolledTooltip>
                </Link>
                <Button
                    className="btn-link"
                    color="danger"
                    data-toggle="tooltip"
                    id="tooltip16493734"
                    size="sm"
                    type="button"
                >
                    <i className="fa fa-times" />
                </Button>
                <UncontrolledTooltip
                    delay={0}
                    placement="top"
                    target="tooltip16493734"
                >
                    Remove Reference
                </UncontrolledTooltip>
            </td>
        </tr>
    )
}

export default AccountTableEntry