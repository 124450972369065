import React, {useState} from "react";
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import MediaPageHeader from "components/Headers/MediaPageHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import {API, Auth, graphqlOperation} from "aws-amplify";
import ReferenceTable from "../../components/vqaas/ReferenceTable";
import {onCreateReference, onDeleteReference, onUpdateReference} from "../../graphql/subscriptions";
import {listReferences} from "../../graphql/queries"
import ReferenceUpload from "../../components/vqaas/ReferenceUpload";
const getAccountWithSubscriptionsTeamsS3 = /* GraphQL */ `
    query GetAccount($id: ID!) {
      getAccount(id: $id) {
        id
        email
        owner
        s3_bucket
        s3_bucket_region
        subscriptions {
          items {
            subscription {
              name
              id
            }
          }
        }
        teams {
          items {
            team {
              name
              id
            }
          }
        }
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        cross_account_s3 {
          items {
            id
            Key
            ETag
            Size
            LastModified
            LastSeen
            accountID
            referenceID
            createdAt
            updatedAt
          }
          nextToken
        }
        credits
        createdAt
        updatedAt
      }
    }
  `;

function dynamicSort(property) {
  var sortOrder = 1;
  if(property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a,b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}

function MediaPage() {
  const [account, setAccount] = useState();
  const [references, setReferences] = useState([])

  React.useEffect(() => {
    async function getMyAccount() {
      const user = await Auth.currentAuthenticatedUser()
      const result = await API.graphql(graphqlOperation(getAccountWithSubscriptionsTeamsS3, {
        id: user.username
      }))
      // console.log(result.data.getAccount)
      setAccount(result.data.getAccount)
    }
    getMyAccount().then()
  }, [])

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("media-page");
    return function cleanup() {
      document.body.classList.remove("media-page");
    };
  });



  React.useEffect(() => {
    async function listMyReferences() {
      if (account){
        const result = await API.graphql(graphqlOperation(listReferences, {
          filter:{'accountID':{'eq': account.id }},
          limit: 999,
        }))
        setReferences(result.data.listReferences.items.sort(dynamicSort("filename")))
      }
    }
    listMyReferences().then()
  }, [account])

  React.useEffect(() => {
    let subscription
    let mounted = true
    async function setupSubscription() {
      const user = await Auth.currentAuthenticatedUser()
      subscription = API.graphql(graphqlOperation(onCreateReference, {owner: user.username})).subscribe({
        next: (data) => {
          if (mounted) {
            const reference = data.value.data.onCreateReference
            setReferences(prevReferences => prevReferences.concat([reference]))
          }
        }
      })
    }
    setupSubscription()
    return () => {
      mounted = false
      subscription.unsubscribe()
    }
  }, [])

  React.useEffect(() => {
    let subscription
    let mounted = true
    async function listMyReferences() {
      if (account){
        const result = await API.graphql(graphqlOperation(listReferences, {
          filter:{'accountID':{'eq': account.id }},
          limit: 999,
        }))
        setReferences(result.data.listReferences.items.sort(dynamicSort("filename")))
      }
    }

    async function setupSubscription() {
      const user = await Auth.currentAuthenticatedUser()
      subscription = API.graphql(graphqlOperation(onDeleteReference, {owner: user.username})).subscribe({
        next: () => {
          if (mounted) {
            listMyReferences().then()
          }
        }
      })
    }
    setupSubscription()
    return () => {
      mounted = false
      subscription.unsubscribe()
    }
  }, [account])

  React.useEffect(() => {
    let subscription
    let mounted = true
    async function listMyReferences() {
      if (account){
        const result = await API.graphql(graphqlOperation(listReferences, {
          filter:{'accountID':{'eq': account.id }},
          limit: 999,
        }))
        setReferences(result.data.listReferences.items.sort(dynamicSort("filename")))
      }
    }

    async function setupSubscription() {
      const user = await Auth.currentAuthenticatedUser()
      subscription = API.graphql(graphqlOperation(onUpdateReference, {owner: user.username})).subscribe({
        next: () => {
          if (mounted) {
            listMyReferences().then()
          }
        }
      })
    }
    setupSubscription()
    return () => {
      mounted = false
      subscription.unsubscribe()
    }
  }, [account])


  return (
      <>
        <ColorNavbar account={account} />
        <MediaPageHeader />
        { references ?
            <div className="section">
              <Container className="tim-container">
                <div className="title">
                  <h3 id="ReferenceLibrary">My References</h3>
                </div>
                <Row>
                  <Col className="ml-auto mr-auto" md="12">
                    <ReferenceTable references={references}/>
                  </Col>
                </Row>
                <ReferenceUpload/>
              </Container>
            </div>
            : <></>}
        <FooterBlack />
      </>
  );
}

export default MediaPage;
