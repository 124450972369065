import React, {useState} from "react";

// reactstrap components
import {
    Button, Col, Progress, Row,
} from "reactstrap";

import {API, Auth, Storage} from "aws-amplify";
import {v4 as uuid} from "uuid";
import {createDistorted} from "../../graphql/mutations";

function DistortedUpload(props) {
    const [disFilename, setDisFilename] = useState("")
    const [uploading, setUploading] = useState(false)
    const [disUploadProgress, setDisUploadProgress] = useState(0)
    const disFileInput = React.createRef();

    const uploadFile = async (file) => {
        const user = await Auth.currentAuthenticatedUser();
        const addDistorted = {
            id: uuid(),
            accountID: props.account.id,
            reference_id: props.reference_id,
            persons: [],
            owner: user.username,
            filename: file.name
        }
        await Storage.put(
            addDistorted.id + "/" + addDistorted.filename,
            file,
            {
                level: 'protected',
                metadata: {
                    owner: user.username,
                },
                progressCallback(progress) {
                    setDisUploadProgress(Math.floor(100 * progress.loaded / progress.total))
                }
            }
        );
        await API.graphql({ query: createDistorted, variables: { input: addDistorted } });
        setDisFilename("")
        setDisUploadProgress(0)
    }

    const onChange = async (e) => {
        setUploading(true)
        setDisFilename(e.target.files.item(0).name)
        let files = [];
        for (var i=0; i<e.target.files.length; i++) {
            files.push(e.target.files.item(i));
        }
        await Promise.all(files.map(f => uploadFile(f)));
        setUploading(false)
    }

    return (
        <Row>
            <Col className="text-left">
                <div className="fileinput text-center">
                    <input
                        type="file"
                        onChange={onChange}
                        ref={disFileInput} />
                    <div>
                        {uploading ? <br/> :
                            <Button
                                className="btn-round"
                                color="default"
                                outline
                                onClick={() => {
                                    disFileInput.current.click();
                                }}
                            >
                                Browse
                            </Button>}
                    </div>
                </div>
            </Col>
            { uploading ?
            <Col className="text-left">
                {disFilename}
            </Col>
                : <></> }
            { uploading ?
            <Col className="text-left">Upload Progress</Col>
                : <></> }
            { uploading ?
            <Col colSpan="1">
                <Progress
                    animated
                    max="100"
                    value={disUploadProgress}
                    barClassName="progress-bar-success"/>
            </Col>
                : <></> }
            { uploading ?
            <Col className="text-left">{disUploadProgress}%</Col>
                : <></> }
        </Row>
    );
}

export default DistortedUpload;
