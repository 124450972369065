/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";
import {Auth} from "aws-amplify";

// core components

function FooterBlack() {
  const [signinUser, setSigninUser] = React.useState()

  React.useEffect(() => {
    async function updateUsername() {
      const user = await Auth.currentAuthenticatedUser()
      setSigninUser(user.attributes.email)
    }
    updateUsername()
  }, [])

  return (
    <>
      <footer className="footer footer-black footer-white">
        <Container>
          <Row>
            <nav className="footer-nav">
              <ul>
                <li>
                  {"Username: "+ signinUser}
                </li>
                <li>
                  {"Credits: "+ 200}
                </li>
              </ul>
            </nav>
            <div className="credits ml-auto">
              <span className="copyright">
                © {new Date().getFullYear()}
                , made with <i className="fa fa-heart heart" /> by Synamedia
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlack;
