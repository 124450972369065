import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import MediaPageHeader from "../../components/Headers/MediaPageHeader";
import FooterBlack from "../../components/Footers/FooterBlack";

function ManageSubscription() {
    // states for collapses
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("ecommerce-page");
        return function cleanup() {
            document.body.classList.remove("ecommerce-page");
        };
    });
    return (
        <>
            <ColorNavbar />
            <MediaPageHeader />
            <div className="wrapper">
                {/* section */}
                <div className="section section-blog">
                    <Container>
                        <div className="title">
                            <h3 id="ManageSubscriptions">Subscriptions</h3>
                        </div>
                        <Row>
                            <Col md="4">
                                <Card className="card-blog">
                                    <div className="card-image">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            <img
                                                alt="..."
                                                className="img img-raised"
                                                src={require("assets/img/sections/miguel-perales.jpg")}
                                            />
                                        </a>
                                    </div>
                                    <CardBody>
                                        <h4 className="card-category text-info">Sales</h4>
                                        <p className="card-description">
                                            LinkedIn is today launching its official desktop
                                            application for Windows 10, allowing the professional
                                            social networking service to... <br />
                                        </p>
                                        <hr />
                                        <CardFooter>

                                            <div className="author">
                                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                    <img
                                                        alt="..."
                                                        className="avatar img-raised mr-2"
                                                        src={require("assets/img/faces/ayo-ogunseinde-2.jpg")}
                                                    />
                                                    <span>Mike John</span>
                                                </a>
                                            </div>
                                            <div className="stats">

                                                <Button
                                                    className="btn-link"
                                                    color="danger"
                                                    data-toggle="tooltip"
                                                    id="tooltip16493123"
                                                    size="sm"
                                                    type="button"
                                                    /* onClick={onRemoveReference} */
                                                >
                                                    <i className="nc-icon nc-vector"/>
                                                </Button>
                                                <UncontrolledTooltip
                                                    delay={0}
                                                    placement="top"
                                                    target="tooltip16493123"
                                                >
                                                    Share subscription
                                                </UncontrolledTooltip>

                                                <Button
                                                    className="btn-link"
                                                    color="danger"
                                                    data-toggle="tooltip"
                                                    id="tooltip16493124"
                                                    size="sm"
                                                    type="button"
                                                    /* onClick={onRemoveReference} */
                                                >
                                                    <i className="fa fa-times" />
                                                </Button>
                                                <UncontrolledTooltip
                                                    delay={0}
                                                    placement="top"
                                                    target="tooltip16493124"
                                                >
                                                    Unsubscribe
                                                </UncontrolledTooltip>
                                            </div>
                                        </CardFooter>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-blog">
                                    <div className="card-image">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            <img
                                                alt="..."
                                                className="img img-raised"
                                                src={require("assets/img/sections/roger-keller.jpg")}
                                            />
                                        </a>
                                    </div>
                                    <CardBody>
                                        <h4 className="card-category text-success">R&D</h4>
                                        <p className="card-description">
                                            The latest version of MIT’s Cheetah robot made its stage
                                            debut today at TC Sessions: Robotics in Cambridge, Mass.
                                            It’s a familiar project... <br />
                                        </p>
                                        <hr />
                                        <CardFooter>
                                            <div className="author">
                                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                    <img
                                                        alt="..."
                                                        className="avatar img-raised mr-2"
                                                        src={require("assets/img/faces/kaci-baum-2.jpg")}
                                                    />
                                                    <span>Nickie Kelly</span>
                                                </a>
                                            </div>
                                            <div className="stats">
                                                <i className="fa fa-clock-o mr-1" />5 min read
                                            </div>
                                        </CardFooter>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-blog">
                                    <div className="card-image">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            <img
                                                alt="..."
                                                className="img img-raised"
                                                src={require("assets/img/sections/joshua-earlesz.jpg")}
                                            />
                                        </a>
                                    </div>
                                    <CardBody>
                                        <h4 className="card-category text-danger">
                                            vDCM Releases
                                        </h4>
                                        <p className="card-description">
                                            Because developing a doctor-on-demand service that would
                                            allow personalized medical visits, booked through an app
                                            on a user’s phone is... <br />
                                        </p>
                                        <hr />
                                        <CardFooter>
                                            <div className="author">
                                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                    <img
                                                        alt="..."
                                                        className="avatar img-raised mr-2"
                                                        src={require("assets/img/faces/erik-lucatero-2.jpg")}
                                                    />
                                                    <span>Mike John</span>
                                                </a>
                                            </div>
                                            <div className="stats">
                                                <i className="fa fa-clock-o mr-1" />5 min read
                                            </div>
                                        </CardFooter>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* section */}
                <FooterBlack />
            </div>
        </>
    );
}

export default ManageSubscription;
