/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAccountSubscription = /* GraphQL */ `
  mutation CreateAccountSubscription(
    $input: CreateAccountSubscriptionInput!
    $condition: ModelAccountSubscriptionConditionInput
  ) {
    createAccountSubscription(input: $input, condition: $condition) {
      id
      accountID
      subscriptionID
      createdAt
      updatedAt
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
      subscription {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateAccountSubscription = /* GraphQL */ `
  mutation UpdateAccountSubscription(
    $input: UpdateAccountSubscriptionInput!
    $condition: ModelAccountSubscriptionConditionInput
  ) {
    updateAccountSubscription(input: $input, condition: $condition) {
      id
      accountID
      subscriptionID
      createdAt
      updatedAt
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
      subscription {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteAccountSubscription = /* GraphQL */ `
  mutation DeleteAccountSubscription(
    $input: DeleteAccountSubscriptionInput!
    $condition: ModelAccountSubscriptionConditionInput
  ) {
    deleteAccountSubscription(input: $input, condition: $condition) {
      id
      accountID
      subscriptionID
      createdAt
      updatedAt
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
      subscription {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createAccountTeam = /* GraphQL */ `
  mutation CreateAccountTeam(
    $input: CreateAccountTeamInput!
    $condition: ModelAccountTeamConditionInput
  ) {
    createAccountTeam(input: $input, condition: $condition) {
      id
      accountID
      teamID
      createdAt
      updatedAt
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateAccountTeam = /* GraphQL */ `
  mutation UpdateAccountTeam(
    $input: UpdateAccountTeamInput!
    $condition: ModelAccountTeamConditionInput
  ) {
    updateAccountTeam(input: $input, condition: $condition) {
      id
      accountID
      teamID
      createdAt
      updatedAt
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteAccountTeam = /* GraphQL */ `
  mutation DeleteAccountTeam(
    $input: DeleteAccountTeamInput!
    $condition: ModelAccountTeamConditionInput
  ) {
    deleteAccountTeam(input: $input, condition: $condition) {
      id
      accountID
      teamID
      createdAt
      updatedAt
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createReferenceSubscription = /* GraphQL */ `
  mutation CreateReferenceSubscription(
    $input: CreateReferenceSubscriptionInput!
    $condition: ModelReferenceSubscriptionConditionInput
  ) {
    createReferenceSubscription(input: $input, condition: $condition) {
      id
      referenceID
      subscriptionID
      createdAt
      updatedAt
      subscription {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        createdAt
        updatedAt
      }
      reference {
        accountID
        createdAt
        cross_account_s3_id
        description
        filename
        viewers
        subscriptions {
          nextToken
        }
        id
        owner
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_extract_feat
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        updatedAt
        user_identity_id
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateReferenceSubscription = /* GraphQL */ `
  mutation UpdateReferenceSubscription(
    $input: UpdateReferenceSubscriptionInput!
    $condition: ModelReferenceSubscriptionConditionInput
  ) {
    updateReferenceSubscription(input: $input, condition: $condition) {
      id
      referenceID
      subscriptionID
      createdAt
      updatedAt
      subscription {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        createdAt
        updatedAt
      }
      reference {
        accountID
        createdAt
        cross_account_s3_id
        description
        filename
        viewers
        subscriptions {
          nextToken
        }
        id
        owner
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_extract_feat
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        updatedAt
        user_identity_id
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteReferenceSubscription = /* GraphQL */ `
  mutation DeleteReferenceSubscription(
    $input: DeleteReferenceSubscriptionInput!
    $condition: ModelReferenceSubscriptionConditionInput
  ) {
    deleteReferenceSubscription(input: $input, condition: $condition) {
      id
      referenceID
      subscriptionID
      createdAt
      updatedAt
      subscription {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        createdAt
        updatedAt
      }
      reference {
        accountID
        createdAt
        cross_account_s3_id
        description
        filename
        viewers
        subscriptions {
          nextToken
        }
        id
        owner
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_extract_feat
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        updatedAt
        user_identity_id
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      company_name
      credits
      description
      email
      first_name
      following {
        name
        ownerID
        accepted
      }
      followed_by {
        name
        ownerID
        accepted
      }
      last_login
      last_upload
      last_name
      num_references
      num_distorteds
      owner
      refresh
      s3_bucket
      s3_bucket_region
      subscriptions {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      teams {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      user_identity_id
      createdAt
      updatedAt
      cross_account_s3 {
        items {
          id
          Key
          ETag
          Size
          LastModified
          LastSeen
          accountID
          referenceID
          createdAt
          updatedAt
        }
        nextToken
      }
      references {
        items {
          accountID
          createdAt
          cross_account_s3_id
          description
          filename
          viewers
          id
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_extract_feat
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          updatedAt
          user_identity_id
        }
        nextToken
      }
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      company_name
      credits
      description
      email
      first_name
      following {
        name
        ownerID
        accepted
      }
      followed_by {
        name
        ownerID
        accepted
      }
      last_login
      last_upload
      last_name
      num_references
      num_distorteds
      owner
      refresh
      s3_bucket
      s3_bucket_region
      subscriptions {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      teams {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      user_identity_id
      createdAt
      updatedAt
      cross_account_s3 {
        items {
          id
          Key
          ETag
          Size
          LastModified
          LastSeen
          accountID
          referenceID
          createdAt
          updatedAt
        }
        nextToken
      }
      references {
        items {
          accountID
          createdAt
          cross_account_s3_id
          description
          filename
          viewers
          id
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_extract_feat
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          updatedAt
          user_identity_id
        }
        nextToken
      }
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      company_name
      credits
      description
      email
      first_name
      following {
        name
        ownerID
        accepted
      }
      followed_by {
        name
        ownerID
        accepted
      }
      last_login
      last_upload
      last_name
      num_references
      num_distorteds
      owner
      refresh
      s3_bucket
      s3_bucket_region
      subscriptions {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      teams {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      user_identity_id
      createdAt
      updatedAt
      cross_account_s3 {
        items {
          id
          Key
          ETag
          Size
          LastModified
          LastSeen
          accountID
          referenceID
          createdAt
          updatedAt
        }
        nextToken
      }
      references {
        items {
          accountID
          createdAt
          cross_account_s3_id
          description
          filename
          viewers
          id
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_extract_feat
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          updatedAt
          user_identity_id
        }
        nextToken
      }
    }
  }
`;
export const createVQaaSSubscription = /* GraphQL */ `
  mutation CreateVQaaSSubscription(
    $input: CreateVQaaSSubscriptionInput!
    $condition: ModelVQaaSSubscriptionConditionInput
  ) {
    createVQaaSSubscription(input: $input, condition: $condition) {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      references {
        items {
          id
          referenceID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVQaaSSubscription = /* GraphQL */ `
  mutation UpdateVQaaSSubscription(
    $input: UpdateVQaaSSubscriptionInput!
    $condition: ModelVQaaSSubscriptionConditionInput
  ) {
    updateVQaaSSubscription(input: $input, condition: $condition) {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      references {
        items {
          id
          referenceID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVQaaSSubscription = /* GraphQL */ `
  mutation DeleteVQaaSSubscription(
    $input: DeleteVQaaSSubscriptionInput!
    $condition: ModelVQaaSSubscriptionConditionInput
  ) {
    deleteVQaaSSubscription(input: $input, condition: $condition) {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      references {
        items {
          id
          referenceID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCrossAccountS3 = /* GraphQL */ `
  mutation CreateCrossAccountS3(
    $input: CreateCrossAccountS3Input!
    $condition: ModelCrossAccountS3ConditionInput
  ) {
    createCrossAccountS3(input: $input, condition: $condition) {
      id
      Key
      ETag
      Size
      LastModified
      LastSeen
      accountID
      referenceID
      createdAt
      updatedAt
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;
export const updateCrossAccountS3 = /* GraphQL */ `
  mutation UpdateCrossAccountS3(
    $input: UpdateCrossAccountS3Input!
    $condition: ModelCrossAccountS3ConditionInput
  ) {
    updateCrossAccountS3(input: $input, condition: $condition) {
      id
      Key
      ETag
      Size
      LastModified
      LastSeen
      accountID
      referenceID
      createdAt
      updatedAt
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;
export const deleteCrossAccountS3 = /* GraphQL */ `
  mutation DeleteCrossAccountS3(
    $input: DeleteCrossAccountS3Input!
    $condition: ModelCrossAccountS3ConditionInput
  ) {
    deleteCrossAccountS3(input: $input, condition: $condition) {
      id
      Key
      ETag
      Size
      LastModified
      LastSeen
      accountID
      referenceID
      createdAt
      updatedAt
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;
export const createCrossCorrelation = /* GraphQL */ `
  mutation CreateCrossCorrelation(
    $input: CreateCrossCorrelationInput!
    $condition: ModelCrossCorrelationConditionInput
  ) {
    createCrossCorrelation(input: $input, condition: $condition) {
      id
      groups
      owner
      offset
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const updateCrossCorrelation = /* GraphQL */ `
  mutation UpdateCrossCorrelation(
    $input: UpdateCrossCorrelationInput!
    $condition: ModelCrossCorrelationConditionInput
  ) {
    updateCrossCorrelation(input: $input, condition: $condition) {
      id
      groups
      owner
      offset
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const deleteCrossCorrelation = /* GraphQL */ `
  mutation DeleteCrossCorrelation(
    $input: DeleteCrossCorrelationInput!
    $condition: ModelCrossCorrelationConditionInput
  ) {
    deleteCrossCorrelation(input: $input, condition: $condition) {
      id
      groups
      owner
      offset
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const createDisMse = /* GraphQL */ `
  mutation CreateDisMse(
    $input: CreateDisMSEInput!
    $condition: ModelDisMSEConditionInput
  ) {
    createDisMSE(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const updateDisMse = /* GraphQL */ `
  mutation UpdateDisMse(
    $input: UpdateDisMSEInput!
    $condition: ModelDisMSEConditionInput
  ) {
    updateDisMSE(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const deleteDisMse = /* GraphQL */ `
  mutation DeleteDisMse(
    $input: DeleteDisMSEInput!
    $condition: ModelDisMSEConditionInput
  ) {
    deleteDisMSE(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const createDistorted = /* GraphQL */ `
  mutation CreateDistorted(
    $input: CreateDistortedInput!
    $condition: ModelDistortedConditionInput
  ) {
    createDistorted(input: $input, condition: $condition) {
      accountID
      align_offset
      createdAt
      cross_account_s3_id
      description
      filename
      persons
      subscriptions
      teams
      id
      metric_psnr
      metric_psnr_bin
      metric_psnr_cdf
      metric_vmaf
      metric_vmaf_bin
      metric_vmaf_cdf
      owner
      progress_calc_metric
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      reference_id
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      updatedAt
      user_identity_id
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;
export const updateDistorted = /* GraphQL */ `
  mutation UpdateDistorted(
    $input: UpdateDistortedInput!
    $condition: ModelDistortedConditionInput
  ) {
    updateDistorted(input: $input, condition: $condition) {
      accountID
      align_offset
      createdAt
      cross_account_s3_id
      description
      filename
      persons
      subscriptions
      teams
      id
      metric_psnr
      metric_psnr_bin
      metric_psnr_cdf
      metric_vmaf
      metric_vmaf_bin
      metric_vmaf_cdf
      owner
      progress_calc_metric
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      reference_id
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      updatedAt
      user_identity_id
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;
export const deleteDistorted = /* GraphQL */ `
  mutation DeleteDistorted(
    $input: DeleteDistortedInput!
    $condition: ModelDistortedConditionInput
  ) {
    deleteDistorted(input: $input, condition: $condition) {
      accountID
      align_offset
      createdAt
      cross_account_s3_id
      description
      filename
      persons
      subscriptions
      teams
      id
      metric_psnr
      metric_psnr_bin
      metric_psnr_cdf
      metric_vmaf
      metric_vmaf_bin
      metric_vmaf_cdf
      owner
      progress_calc_metric
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      reference_id
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      updatedAt
      user_identity_id
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;
export const createPsnRavg = /* GraphQL */ `
  mutation CreatePsnRavg(
    $input: CreatePSNRavgInput!
    $condition: ModelPSNRavgConditionInput
  ) {
    createPSNRavg(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const updatePsnRavg = /* GraphQL */ `
  mutation UpdatePsnRavg(
    $input: UpdatePSNRavgInput!
    $condition: ModelPSNRavgConditionInput
  ) {
    updatePSNRavg(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const deletePsnRavg = /* GraphQL */ `
  mutation DeletePsnRavg(
    $input: DeletePSNRavgInput!
    $condition: ModelPSNRavgConditionInput
  ) {
    deletePSNRavg(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const createRefMse = /* GraphQL */ `
  mutation CreateRefMse(
    $input: CreateRefMSEInput!
    $condition: ModelRefMSEConditionInput
  ) {
    createRefMSE(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const updateRefMse = /* GraphQL */ `
  mutation UpdateRefMse(
    $input: UpdateRefMSEInput!
    $condition: ModelRefMSEConditionInput
  ) {
    updateRefMSE(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const deleteRefMse = /* GraphQL */ `
  mutation DeleteRefMse(
    $input: DeleteRefMSEInput!
    $condition: ModelRefMSEConditionInput
  ) {
    deleteRefMSE(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const createReference = /* GraphQL */ `
  mutation CreateReference(
    $input: CreateReferenceInput!
    $condition: ModelReferenceConditionInput
  ) {
    createReference(input: $input, condition: $condition) {
      accountID
      createdAt
      cross_account_s3_id
      description
      filename
      viewers
      subscriptions {
        items {
          id
          referenceID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      id
      owner
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      updatedAt
      user_identity_id
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;
export const updateReference = /* GraphQL */ `
  mutation UpdateReference(
    $input: UpdateReferenceInput!
    $condition: ModelReferenceConditionInput
  ) {
    updateReference(input: $input, condition: $condition) {
      accountID
      createdAt
      cross_account_s3_id
      description
      filename
      viewers
      subscriptions {
        items {
          id
          referenceID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      id
      owner
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      updatedAt
      user_identity_id
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;
export const deleteReference = /* GraphQL */ `
  mutation DeleteReference(
    $input: DeleteReferenceInput!
    $condition: ModelReferenceConditionInput
  ) {
    deleteReference(input: $input, condition: $condition) {
      accountID
      createdAt
      cross_account_s3_id
      description
      filename
      viewers
      subscriptions {
        items {
          id
          referenceID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      id
      owner
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      updatedAt
      user_identity_id
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;
export const createSsim = /* GraphQL */ `
  mutation CreateSsim(
    $input: CreateSSIMInput!
    $condition: ModelSSIMConditionInput
  ) {
    createSSIM(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const updateSsim = /* GraphQL */ `
  mutation UpdateSsim(
    $input: UpdateSSIMInput!
    $condition: ModelSSIMConditionInput
  ) {
    updateSSIM(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const deleteSsim = /* GraphQL */ `
  mutation DeleteSsim(
    $input: DeleteSSIMInput!
    $condition: ModelSSIMConditionInput
  ) {
    deleteSSIM(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const createVmaf = /* GraphQL */ `
  mutation CreateVmaf(
    $input: CreateVMAFInput!
    $condition: ModelVMAFConditionInput
  ) {
    createVMAF(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const updateVmaf = /* GraphQL */ `
  mutation UpdateVmaf(
    $input: UpdateVMAFInput!
    $condition: ModelVMAFConditionInput
  ) {
    updateVMAF(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const deleteVmaf = /* GraphQL */ `
  mutation DeleteVmaf(
    $input: DeleteVMAFInput!
    $condition: ModelVMAFConditionInput
  ) {
    deleteVMAF(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
