import React from "react";

function VmafCell(props) {
  return (
      <>
        <td>
          {
            props.distorted ?
                props.distorted.metric_vmaf
                :
                ''
          }
        </td>
      </>
  );
}

export default VmafCell;
