import React, { useState } from 'react';
import {API, graphqlOperation} from "aws-amplify"
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {getPsnRavg, getVmaf, getSsim} from "../../graphql/queries"
import {
    Button, Col,
    Row, ButtonGroup
} from "reactstrap";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";

require("highcharts/modules/annotations")(Highcharts);

const argPeak = (myArray) => {
    let min = myArray[0]
    let argMin = 0

    for (let i=1; i < myArray.length; i++){
        let value = myArray[i]
        if (value < min ){
            argMin = i
            min = value
        }
    }
    return argMin
}


function VqaasHCMetricChart(props) {
    const [analysisId, setAnalysisId] = useState(props.analysis_id)
    const [progressCalcMetric, setProgressCalcMetric] = useState(props.progress_calc_metric)
    const [lastSelectedFrame, setLastSelectedFrame] = useState(null)
    const [vmafData, setVmafData] = useState([])
    const [ssimData, setSsimData] = useState([])
    const [psnrData, setPsnrData] = useState([])
    const [xExtremes, setXExtremes] = useState({min: null, max: null})
    const [xDelta, setXDelta] = useState(null)
    const [xCenter, setXCenter] = useState(null)

    const vmafChartRef = React.useRef()

    const [chartOptions, setChartOptions] = useState({
        chart: {
            animation: false,
            marginTop: 100
        },
        series: [
            { data: [], color: '#3380FF' }
        ],
        xAxis: {
            crosshair: true,
            max: null,
            min: null,
            gridLineWidth: 1,
            title: {
                text: 'Frame number after aligning reference and distorted video'
            },
        },
        yAxis: [
            { // Primary axis VMAF
                max: 100,
                labels: {
                    style: {
                        color: '#3380FF'
                    }
                },
                title: {
                    text: 'VMAF',
                    style: {
                        color: '#3380FF'
                    }
                }
            },
            { // Secondary axis PSNR
                gridLineWidth: 0,
                labels: {
                    style: {
                        color: '#FFB400'
                    }
                },
                title: {
                    text: 'PSNR',
                    style: {
                        color: '#FFB400'
                    }
                },
                opposite: true
            },
            { // Tertiary axis SSIM
                gridLineWidth: 0,
                max: 1,
                labels: {
                    style: {
                        color: '#FF3333'
                    }
                },
                title: {
                    text: 'SSIM',
                    style: {
                        color: '#FF3333'
                    }
                },
                opposite: true
            },

        ],
        legend: {
            layout: 'vertical',
            align: 'right',
            x: -50,
            verticalAlign: 'top',
            y: 0,
            floating: true,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || // theme
                'rgba(255,255,255,0.25)'
        },
        title: {
            text: 'Video Quality Metric'
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                animation: false,
                point: {
                    events: {
                        select(e){
                            setLastSelectedFrame(e.target.x)
                        }
                    }
                }
            }
        },
        tooltip: {
            shared: true
        }
    });

    React.useEffect(() => {
        setAnalysisId(props.analysis_id)
    }, [props.analysis_id])

    React.useEffect(() => {
        setProgressCalcMetric(props.progress_calc_metric)
    }, [props.progress_calc_metric])


    React.useEffect(() => {
        setChartOptions(prevChartOptions => {
            let updatedChartOptions = {...prevChartOptions}
            updatedChartOptions.xAxis.min = xExtremes.min
            updatedChartOptions.xAxis.max = xExtremes.max
            return updatedChartOptions
        })
    }, [xExtremes])

    React.useEffect(() => {
        // setChartOptions({...chartOptions, 'series': [{data:vmafData}]})
        setLastSelectedFrame(Math.round(vmafData.length/2))
        setChartOptions(prevChartOptions => {
            let updatedChartOptions = {...prevChartOptions}
            updatedChartOptions.series = [
                {name:"VMAF", data:vmafData, color: '#3380FF', yAxis: 0},
                {name:"PSNR", data:psnrData, color: '#FFB400', yAxis: 1},
                {name:"SSIM", data:ssimData, color: '#FF3333', yAxis: 2}]
            return updatedChartOptions
        })
    }, [vmafData, psnrData, ssimData])

    React.useEffect(() => {
        async function onChangeAnalysisId() {
            async function onAvgPSNRChange() {
                let metrics = await API.graphql(
                    graphqlOperation(
                        getPsnRavg,
                        {
                            'id': analysisId,
                            'sequence': 0
                        })
                )
                if (metrics.data.getPSNRavg){
                    const psnr_list = metrics.data.getPSNRavg.values
                    setPsnrData(psnr_list)
                }
            }

            async function onSSIMChange() {
                let metrics = await API.graphql(
                    graphqlOperation(
                        getSsim,
                        {
                            'id': analysisId,
                            'sequence': 0
                        })
                )
                if (metrics.data.getSSIM){
                    const ssim_list = metrics.data.getSSIM.values
                    setSsimData(ssim_list)
                }
            }

            async function onVMAFChange() {
                let metrics = await API.graphql(
                    graphqlOperation(
                        getVmaf,
                        {
                            'id': analysisId,
                            'sequence': 0
                        })
                )
                if (metrics.data.getVMAF){
                    const vmaf_list = metrics.data.getVMAF.values
                    setVmafData(vmaf_list)
                }
            }

            await onAvgPSNRChange()
            await onSSIMChange()
            await onVMAFChange()
        }
        onChangeAnalysisId()
    }, [analysisId, progressCalcMetric])

    React.useEffect(() => {
        if ((xCenter !== null) && (xDelta !== null)) {
            setXExtremes({
                min: xCenter - Math.round(xDelta / 2),
                max: xCenter + Math.round(xDelta / 2)
            })
        }
    }, [xCenter, xDelta])

    React.useEffect(() => {
        if (lastSelectedFrame !== null){
            setChartOptions(prevChartOptions => {
                let updatedChartOptions = {...prevChartOptions}
                updatedChartOptions.annotations = [ {
                    labelOptions: {
                        backgroundColor: 'rgba(0,0,0,0.95)',
                        overflow: "none"
                    },
                    labels: [{
                        point: {
                            xAxis: 0,
                            x: lastSelectedFrame,
                            y: 0
                        },
                        text: String(lastSelectedFrame)
                    }]
                }]
                return updatedChartOptions
            })
            if (props.framerate){
                props.onSeekTimeChange(lastSelectedFrame / props.framerate)
            }
        }
    }, [lastSelectedFrame, props])

    async function onButtonGroupClick(e) {
        let metric = e.target.offsetParent.attributes.getNamedItem('name').value
        let action = e.target.attributes.getNamedItem('data-key').value
        let selectedMetricData

        if (metric === "Vmaf"){
            selectedMetricData = vmafData
        }
        if (metric === "Psnr"){
            selectedMetricData = psnrData
        }
        if (metric === "Ssim"){
            selectedMetricData = ssimData
        }

        if (action === "peakSearch"){
            let windowMin = (xExtremes.min > 0) ? xExtremes.min : 0
            let windowMax = xExtremes.max

            if (!windowMin) {
                windowMin = 0
                windowMax = selectedMetricData.length
            }
            let localMin = argPeak(selectedMetricData.slice(windowMin,windowMax)) + windowMin
            setLastSelectedFrame(localMin)
        }
        if (action === "peakLeft"){
            if (lastSelectedFrame !== null){
                let windowMin = (xExtremes.min > 0) ? xExtremes.min : 0
                let windowMax = lastSelectedFrame

                if (!windowMin) {
                    windowMin = 0
                }
                let localMin = argPeak(selectedMetricData.slice(windowMin,windowMax)) + windowMin
                setLastSelectedFrame(localMin)
            }
        }
        if (action === "peakRight"){
            if (lastSelectedFrame !== null){
                let windowMin
                // exclude current selected point, but limit to length of data
                windowMin = (lastSelectedFrame + 1 < selectedMetricData.length) ? lastSelectedFrame + 1 : selectedMetricData.length - 1
                // Don't go beyond current span
                if (xExtremes.max !== null){
                    windowMin = (windowMin < xExtremes.max) ? windowMin : xExtremes.max
                }
                let windowMax
                // Don't go beyond current span, if no span defined limit to length of data
                if (xExtremes.max !== null){
                    windowMax = (xExtremes.max < selectedMetricData.length) ? xExtremes.max : selectedMetricData.length - 1
                } else {
                    windowMax = selectedMetricData.length - 1
                }
                let localMin = argPeak(selectedMetricData.slice(windowMin,windowMax)) + windowMin
                setLastSelectedFrame(localMin)
            }
        }
        if (action === "toLeft"){
            setLastSelectedFrame(prevLastSelectedFrame => prevLastSelectedFrame - 1)
        }
        if (action === "toRight"){
            setLastSelectedFrame(prevLastSelectedFrame => prevLastSelectedFrame + 1)
        }
        if (action === "toCenter"){
            setXCenter(() => {
                if (lastSelectedFrame !== null) {
                    return lastSelectedFrame
                } else {
                    return null
                }
            })
            setXDelta(prevXDelta => {
                if (prevXDelta !== null) {
                    return prevXDelta
                } else {
                    return vmafData.length
                }
            })
        }
        if (action === "zoomx2"){
            setXDelta(prevXDelta => {
                if (prevXDelta !== null) {
                    return Math.round(prevXDelta / 2)
                } else {
                    return Math.round(vmafData.length  / 2)
                }
            })
            setXCenter(prevXCenter => {
                if (prevXCenter !== null) {
                    return prevXCenter
                } else {
                    return Math.round(vmafData.length  / 2)
                }
            })
        }
        if (action === "zoomDiv2"){
            setXDelta(prevXDelta => {
                if (prevXDelta !== null) {
                    return prevXDelta * 2
                } else {
                    return vmafData.length
                }
            })
            setXCenter(prevXCenter => {
                if (prevXCenter !== null) {
                    return prevXCenter
                } else {
                    return Math.round(vmafData.length /2)
                }
            })
        }
        if (action === "fullSpan"){
            setXExtremes({min: null, max: null})
            setXDelta(null)
            setXCenter(null)
        }
    }

    return(
        <Divider>
            <Row>
                <Col sm={2} md={1}>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <ButtonGroup
                        className="float-right"
                        vertical
                        name={"Vmaf"}
                        onClick={onButtonGroupClick}
                    >
                        <Button
                            className="btn-round"
                            color="primary"
                            outline
                            data-key={"peakSearch"}
                        >
                            VMAF<br/>Pk Search
                        </Button>
                        <Button
                            className="btn-round"
                            color="primary"
                            outline
                            data-key={"peakLeft"}
                        >
                            VMAF<br/>Pk Left
                        </Button>
                        <Button
                            className="btn-round"
                            color="primary"
                            outline
                            data-key={"peakRight"}
                        >
                            VMAF<br/>Pk Right
                        </Button>
                    </ButtonGroup>
                </Col>
                <Col sm={6} md={9} >
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={chartOptions}
                            ref={vmafChartRef}
                        />
                </Col>
                <Col sm={2} md={1}>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <ButtonGroup
                        className="justified"
                        vertical
                        name={"Psnr"}
                        onClick={onButtonGroupClick}
                    >
                        <Button
                            className="btn-round"
                            color="success"
                            outline
                            data-key={"peakSearch"}
                        >
                            PSNR<br/>Pk Search
                        </Button>
                        <Button
                            className="btn-round"
                            color="success"
                            outline
                            data-key={"peakLeft"}
                        >
                            PSNR<br/>Pk Left
                        </Button>
                        <Button
                            className="btn-round"
                            color="success"
                            outline
                            data-key={"peakRight"}
                        >
                            PSNR<br/>Pk Right
                        </Button>
                    </ButtonGroup>
                </Col>
                <Col sm={2} md={1}>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <ButtonGroup
                        className="justified"
                        vertical
                        name={"Ssim"}
                        onClick={onButtonGroupClick}
                    >
                        <Button
                            className="btn-round"
                            color="danger"
                            outline
                            data-key={"peakSearch"}
                        >
                            SSIM<br/>Pk Search
                        </Button>
                        <Button
                            className="btn-round"
                            color="danger"
                            outline
                            data-key={"peakLeft"}
                        >
                            SSIM<br/>Pk Left
                        </Button>
                        <Button
                            className="btn-round"
                            color="danger"
                            outline
                            data-key={"peakRight"}
                        >
                            SSIM<br/>Pk Right
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={{size:5, offset:3}}>
                    <ButtonGroup
                        className="d-flex align-content-center"
                        name={"Span"}
                        onClick={onButtonGroupClick}
                    >
                        <Button
                            className="btn-round btn-default"
                            color="default"
                            outline
                            data-key={""}
                        >
                        </Button>
                        <Button
                            className="btn-round btn-default"
                            color="default"
                            outline
                            name={"Span"}
                            data-key={"toLeft"}
                        >
                            <i className="nc-icon nc-minimal-left" data-key={"toLeft"} />
                        </Button>
                        <Button
                            className="btn-round btn-default"
                            color="default"
                            outline
                            name={"Span"}
                            data-key={"toRight"}
                        >
                            <i className="nc-icon nc-minimal-right" data-key={"toRight"} />
                        </Button>
                        <Button
                            className="btn-round btn-default"
                            color="default"
                            outline
                            data-key={""}
                        >
                        </Button>
                        <Button
                            className="btn-round btn-default"
                            color="default"
                            outline
                            data-key={"toCenter"}
                        >
                            To Center
                        </Button>
                        <Button
                            className="btn-round btn-default"
                            color="default"
                            outline
                            data-key={"zoomx2"}
                        >
                            Zoom x2
                        </Button>
                        <Button
                            className="btn-round btn-default"
                            color="default"
                            outline
                            data-key={"zoomDiv2"}
                        >
                            Zoom /2
                        </Button>
                        <Button
                            className="btn-round btn-default"
                            color="default"
                            outline
                            data-key={"fullSpan"}
                        >
                            Full Span
                        </Button>
                        <Button
                            className="btn-round btn-default"
                            color="default"
                            outline
                            data-key={""}
                        >
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Divider>
    )
}

export default VqaasHCMetricChart;