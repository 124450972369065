import React from "react";

// reactstrap components
import {
    Table,
} from "reactstrap";

// core components
import S3TableEntry from "../../components/vqaas/S3TableEntry";
import S3FolderEntry from "../../components/vqaas/S3FolderEntry";

function S3Table(props) {

    const folderEntries = props.s3Folders.map(s3Folder =>
        <S3FolderEntry
            key={s3Folder.id}
            s3_object={s3Folder}
            onFolderClick={props.onFolderClick}/>)

    const tableEntries = props.s3Objects.map(s3Object =>
        <S3TableEntry
            key={s3Object.id}
            s3_object={s3Object}
            onImportHandle={props.onImportHandle}/>)

    return (
        <>
            <Table responsive>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Last Modified</th>
                    <th>Size</th>
                    <th className="text-right">Actions</th>
                </tr>
                </thead>
                <tbody>
                {folderEntries}
                {tableEntries}
                </tbody>
            </Table>
        </>
    );
}

export default S3Table;
