import React from "react";
import {
    Card, CardBody, Col
} from "reactstrap";

function TeamCard(props) {
    console.log(props)
    return (
        <Col md="4">
            <Card className="card-blog">
                <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                            alt="..."
                            className="img img-raised"
                            src={require("assets/img/sections/miguel-perales.jpg")}
                        />
                    </a>
                </div>
                <CardBody>
                    <h4 className="card-category text-info">{props.team.name}</h4>
                    <p className="card-description">{props.team.description}<br />
                    </p>
                </CardBody>
            </Card>
        </Col>
    );
}

export default TeamCard;
