import React, {useState} from "react";

// reactstrap components
import {
    Button,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import MediaPageHeader from "components/Headers/MediaPageHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import TeamCard from "components/vqaas/TeamCard";
import {Link} from "react-router-dom";
import {API, Auth, graphqlOperation} from "aws-amplify";
import {getAccount} from "../../graphql/queries";

const getAccountWithSubscriptionsTeams = /* GraphQL */ `
    query GetAccount($id: ID!) {
      getAccount(id: $id) {
        id
        email
        owner
        s3_bucket
        cross_account_s3 {
          items {
            id
            Key
            accountID
            createdAt
            updatedAt
          }
          nextToken
        }
        subscriptions {
          items {
            subscription {
              name
              id
            }
          }
        }
        teams {
          items {
            team {
              description
              name
              id
            }
          }
        }
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        credits
        createdAt
        updatedAt
      }
    }
  `;

function ProfilePage() {
    const [activeTab, setActiveTab] = React.useState("1");

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [description, setDescription] = useState("")
    const [account, setAccount] = useState();
    const [teams, setTeams] = useState([])

    React.useEffect(() => {
        async function getMyAccount() {
            const user = await Auth.currentAuthenticatedUser()
            const result = await API.graphql(graphqlOperation(getAccountWithSubscriptionsTeams, {
                id: user.username
            }))
            setAccount(result.data.getAccount)
            setTeams(result.data.getAccount.teams.items)
            console.log(result.data.getAccount.teams.items)
            console.log(result.data.getAccount)
        }
        getMyAccount().then()
    }, [])

    React.useEffect(() => {
        async function getMyAccount() {
            const user = await Auth.currentAuthenticatedUser()
            const result = await API.graphql(graphqlOperation(getAccount, {
                id: user.username
            }))
            setFirstName(result.data.getAccount.first_name)
            setLastName(result.data.getAccount.last_name)
            setDescription(result.data.getAccount.description)
        }
        getMyAccount().then()
    }, [])


    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("profile-page");
        return function cleanup() {
            document.body.classList.remove("profile-page");
        };
    });

    const tableEntries = teams.map(item =>
        <TeamCard
            key={item.team.id}
            team={item.team} />)

    return (
        <>
            <ColorNavbar account={account} />
            <MediaPageHeader />
            {account ?
                <div className="wrapper">
                    <div className="profile-content section">
                        <Container>
                            <Row>
                                <Col className="ml-auto mr-auto text-center" md="6">
                                    <h3>{firstName+' '+lastName}</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="ml-auto mr-auto text-center" md="6">
                                    <p>
                                        {description}
                                    </p>
                                    <br />
                                    <Button
                                        className="btn-round"
                                        color="default"
                                        outline
                                        to="/settings-page"
                                        tag={Link}>
                                        <i className="fa fa-cog mr-1" />
                                        Settings
                                    </Button>
                                </Col>
                            </Row>
                            <br />
                            <div className="nav-tabs-navigation">
                                <div className="nav-tabs-wrapper">
                                    <Nav role="tablist" tabs>
{/*
                                        <NavItem>
                                            <NavLink
                                                className={activeTab === "1" ? "active" : ""}
                                                onClick={() => {
                                                    toggle("1");
                                                }}
                                            >
                                                Followed by
                                            </NavLink>
                                        </NavItem>
*/}
{/*
                                        <NavItem>
                                            <NavLink
                                                className={activeTab === "2" ? "active" : ""}
                                                onClick={() => {
                                                    toggle("2");
                                                }}
                                            >
                                                Following
                                            </NavLink>
                                        </NavItem>
*/}
                                        <NavItem>
                                            <NavLink
                                                className={activeTab === "1" ? "active" : ""}
                                                onClick={() => {
                                                    toggle("1");
                                                }}
                                            >
                                                Teams
                                            </NavLink>
                                        </NavItem>
{/*
                                        <NavItem>
                                            <NavLink
                                                className={activeTab === "4" ? "active" : ""}
                                                onClick={() => {
                                                    toggle("4");
                                                }}
                                            >
                                                Subscriptions
                                            </NavLink>
                                        </NavItem>
*/}
                                    </Nav>
                                </div>
                            </div>
                            {/* Tab panes */}
                            <TabContent className="following" activeTab={activeTab}>
{/*
                                <TabPane tabId="1" id="follows">
                                    <Row>
                                        <Col className="ml-auto mr-auto" md="6">
                                            <ul className="list-unstyled follows">
                                                <li>
                                                    <Row>
                                                        <Col className="ml-auto mr-auto" lg="2" md="4" xs="4">
                                                            <img
                                                                alt="..."
                                                                className="img-circle img-no-padding img-responsive"
                                                                src={require("assets/img/faces/clem-onojeghuo-3.jpg")}
                                                            />
                                                        </Col>
                                                        <Col className="ml-auto mr-auto" lg="7" md="4" xs="4">
                                                            <h6>
                                                                Lincoln <br />
                                                                <small>Car Producer</small>
                                                            </h6>
                                                        </Col>
                                                        <Col className="ml-auto mr-auto" lg="3" md="4" xs="4">
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input
                                                                        defaultChecked
                                                                        defaultValue=""
                                                                        type="checkbox"
                                                                    />
                                                                    <span className="form-check-sign" />
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </li>
                                                <hr />
                                                <li>
                                                    <Row>
                                                        <Col className="mx-auto" lg="2" md="4" xs="4">
                                                            <img
                                                                alt="..."
                                                                className="img-circle img-no-padding img-responsive"
                                                                src={require("assets/img/faces/erik-lucatero-2.jpg")}
                                                            />
                                                        </Col>
                                                        <Col lg="7" md="4" xs="4">
                                                            <h6>
                                                                Banks <br />
                                                                <small>Singer</small>
                                                            </h6>
                                                        </Col>
                                                        <Col lg="3" md="4" xs="4">
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input defaultValue="" type="checkbox" />
                                                                    <span className="form-check-sign" />
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </TabPane>
*/}
{/*
                                <TabPane className="text-center" tabId="2" id="following">
                                    <h3 className="text-muted">Not following anyone yet :(</h3>
                                    <Button className="btn-round" color="warning">
                                        Find colleagues on VQaaS
                                    </Button>
                                </TabPane>
*/}
                                <TabPane className="text-center" tabId="1" id="teams">
                                    <Row>
                                        {tableEntries}
                                    </Row>
                                </TabPane>
{/*
                                <TabPane className="text-center" tabId="4" id="subscriptions">
                                    <Row>
                                        <Col md="4">
                                            <Card className="card-blog">
                                                <div className="card-image">
                                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                        <img
                                                            alt="..."
                                                            className="img img-raised"
                                                            src={require("assets/img/sections/joshua-earlesz.jpg")}
                                                        />
                                                    </a>
                                                </div>
                                                <CardBody>
                                                    <h4 className="card-category text-danger">
                                                        vDCM Releases
                                                    </h4>
                                                    <p className="card-description">
                                                        Because developing a doctor-on-demand service that would
                                                        allow personalized medical visits, booked through an app
                                                        on a user’s phone is... <br />
                                                    </p>
                                                    <hr />
                                                    <CardFooter>
                                                        <div className="author">
                                                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                                <img
                                                                    alt="..."
                                                                    className="avatar img-raised mr-2"
                                                                    src={require("assets/img/faces/erik-lucatero-2.jpg")}
                                                                />
                                                                <span>Mike John</span>
                                                            </a>
                                                        </div>
                                                        <div className="stats">
                                                            <i className="fa fa-clock-o mr-1" />5 min read
                                                        </div>
                                                    </CardFooter>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </TabPane>
*/}
                            </TabContent>
                        </Container>
                    </div>
                </div> : <></>}
            <FooterBlack />
        </>
    );
}

export default ProfilePage;
