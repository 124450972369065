import React, {useState} from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js"
import ReferencePageHeader from "components/Headers/ReferencePageHeader.js"
import FooterBlack from "components/Footers/FooterBlack.js"
import ReferenceProcessing from "../../components/vqaas/ReferenceProcessing"
import DistortedTable from "../../components/vqaas/DistortedTable"
import {API, Auth, graphqlOperation} from "aws-amplify"
import {useParams} from "react-router-dom"
import ReferenceSpecPage from "../../components/vqaas/ReferenceSpecPage"
import DistortedUpload from "../../components/vqaas/DistortedUpload";
import StreamPlayer from "../../components/vqaas/StreamPlayer";
import {onUpdateReference} from "../../graphql/subscriptions";
import {
  getReferenceWithShares
} from "../../graphql/MyQueries"
import { useHistory } from "react-router-dom";
import {createDistorted} from "../../graphql/mutations";
import S3Browser from "../../components/vqaas/S3Browser";
import {v4 as uuid} from "uuid";

const getAccountWithSubscriptionsTeamsS3 = /* GraphQL */ `
    query GetAccount(
      $id: ID!
      $crossNextToken: String) {
          getAccount(id: $id) {
            id
            email
            owner
            s3_bucket
            s3_bucket_region
            subscriptions {
              items {
                subscription {
                  name
                  id
                }
              }
            }
            teams {
              items {
                team {
                  name
                  id
                }
              }
            }
            following {
              name
              ownerID
              accepted
            }
            followed_by {
              name
              ownerID
              accepted
            }
            cross_account_s3(nextToken: $crossNextToken) {
              items {
                id
                Key
                ETag
                Size
                LastModified
                LastSeen
                accountID
                referenceID
                createdAt
                updatedAt
              }
              nextToken
            }
            credits
            createdAt
            updatedAt
          }
    }
  `;

function dynamicSort(property) {
  var sortOrder = 1;
  if(property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a,b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}

function ReferencePage() {
  const { reference_id } = useParams()
  const [analysis, setAnalysis] = useState();
  const [account, setAccount] = useState();
  const [s3Distorteds, setS3Distorteds] = useState([])

  // const [refTeamList, setRefTeamList] = useState([])
  // const [refSubscriptionList, setRefSubscriptionList] = useState([])

  React.useEffect(() => {
    async function getMyAccount() {
      let cross_items = []
      let nextToken = null
      let myAccount
      const user = await Auth.currentAuthenticatedUser()
      do {
        const result = await API.graphql(graphqlOperation(getAccountWithSubscriptionsTeamsS3, {
          id: user.username,
          crossNextToken: nextToken
        }))
        nextToken = result.data.getAccount.cross_account_s3.nextToken
        myAccount = result.data.getAccount
        cross_items = cross_items.concat(result.data.getAccount.cross_account_s3.items)
      } while (nextToken != null)
      setAccount(myAccount)
      setS3Distorteds(cross_items.sort(dynamicSort("Key")))
    }
    getMyAccount().then()
  }, [])

  React.useEffect(() => {
    async function getDistorted() {
      const user = await Auth.currentAuthenticatedUser()
      const result = await API.graphql(graphqlOperation(getReferenceWithShares, {
        id: reference_id,
        owner: user.username
      }))
      setAnalysis(result.data.getReference)
      // setRefTeamList(result.data.getReference.teams.items)
      // setRefSubscriptionList(result.data.getReference.subscriptions.items)
    }
    getDistorted().then()
  }, [reference_id])

  React.useEffect(() => {
    let subscription
    let mounted = true

    async function setupSubscription() {
      // console.log("Subscribing to onUpdate")
      const user = await Auth.currentAuthenticatedUser()
      subscription = API.graphql(
          graphqlOperation(
              onUpdateReference,
              {id: reference_id, owner: user.username}))
          .subscribe({
            next: (data) => {
              if (mounted) {
                console.log(data.value.data)
                if (!data.value.data.onUpdateReference) {
                  console.log("Subscription to onUpdateReference returned null")
                  return
                }
                if (data.value.data.onUpdateReference.id === reference_id) {
                  setAnalysis(data.value.data.onUpdateReference)
                } else {
                  console.log("onUpdateReference did not match reference_id")
                }
              }
            }
          })
    }
    setupSubscription().then()
    return () => {
      mounted = false
      subscription.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("media-page");
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("media-page");
    };
  });

  let history = useHistory();

  const onImportHandle = async (e) => {
    console.log(e.target.attributes)
    const s3_object_id = e.target.attributes.getNamedItem('data-s3_object_id').value
    const s3_object_key = e.target.attributes.getNamedItem('data-s3_object_key').value
    const addDistorted = {
      cross_account_s3_id: s3_object_id,
      id: uuid(),
      reference_id: reference_id,
      accountID: account.id,
      owner: account.id,
      filename: "arn:aws:s3:::" + account.s3_bucket + "/" + s3_object_key
    }

    console.log("Import S3", addDistorted)
    //setShowButton(false)
    await API.graphql({ query: createDistorted, variables: { input: addDistorted } });
    setS3Distorteds(prevState => {
      return prevState.map(prevDistorted => {
        if (prevDistorted.id === s3_object_id) {
          prevDistorted.Uploaded = true
        }
        return prevDistorted
      })
    })
  }

  return (
      <>
        <ColorNavbar account={account} />
        <ReferencePageHeader />
        <div className="section">
          { analysis && account ?
              <Container className="tim-container">
                <div className="title">
                  <h3>Reference</h3>
                </div>
                <ReferenceSpecPage refSpecData={analysis}/>
                {/*
                {analysis && account ?
                    <ReferenceSharing
                      refSubscriptionList={refSubscriptionList}
                      refTeamList={refTeamList}
                      account={account}
                      addTeam={addTeam}
                      addSubscription={addSubscription}
                    /> : <br/>}
*/}
                <ReferenceProcessing RefProc={analysis}/>
                {analysis.progress_create_hls === 100 ?
                    <>
                      <StreamPlayer
                          streamPlayerData={analysis}
                          refOrDis={"reference"}/>
                      <DistortedTable reference_id={reference_id}/>
                      <DistortedUpload
                          reference_id={reference_id}
                          account={account}/>
                      <h4 className="title">
                        <small>Import distorted from Cross account S3 bucket</small>
                      </h4>
                      {account ?
                          <S3Browser
                              onImportHandle={onImportHandle}
                              account={account}
                              s3_objects={s3Distorteds}
                          />
                          : <></>}
                    </>
                    : <></>}
                <button
                    onClick={() => history.goBack()}
                    className="btn-link pull-left"
                    color="default"
                >Back</button>
              </Container>
              : <></>
          }
        </div>
        <FooterBlack />
      </>
  );
}

export default ReferencePage;
