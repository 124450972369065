import React from "react"
import {Button, FormGroup, Input, Label, UncontrolledTooltip} from "reactstrap";
import {Link} from "react-router-dom";
import {API} from "aws-amplify";
import {deleteDistorted} from "../../graphql/mutations";

function DistortedTableEntry(props) {
    const onRemoveDistorted = async () => {
        // const user = await Auth.currentAuthenticatedUser();
        const deleteDistortedInput = {
            id: props.distorted.id,
            // owner: user.username,
        }
        await API.graphql({ query: deleteDistorted, variables: { input: deleteDistortedInput } });
    }

    return (
        <tr>
            <td className="text-center">
                { props.distorted.spec_width ?
                <Link to={"/distorted/" + props.reference_id + "/" + props.distorted.id} >
                    {props.distorted.id.slice(0,6)}
                </Link> : <></>}
            </td>
            <td>
                { (props.distorted.progress_calc_metric === 100) &&
                <FormGroup check>
                    <Label check>
                        <Input
                            type="checkbox"
                            value={props.distorted.compare}
                            onChange={() => props.handleChange(props.distorted.id)}
                        />
                        <span className="form-check-sign" />
                    </Label>
                </FormGroup>
                }
            </td>
            <td>{props.distorted.filename}</td>
            <td>
                {
                    props.distorted.spec_width ?
                        props.distorted.spec_width + "x" + props.distorted.spec_height :
                        <div className="uil-reload-css reload-small mr-1 "><div /></div>
                }
            </td>
            <td>
                {
                    props.distorted.spec_frame_rate ?
                        props.distorted.spec_frame_rate :
                        <div className="uil-reload-css reload-small mr-1 "><div /></div>
                }
            </td>
            <td className="td-actions text-right">
                { props.distorted.spec_width ? <>
                    <Link to={"/distorted/" + props.reference_id + "/" + props.distorted.id} >
                        <Button
                            className="btn-link mr-1"
                            color="info"
                            data-toggle="tooltip"
                            id="tooltip542628903"
                            to={"/distorted/" + props.reference_id + "/" + props.distorted.id}
                            size="sm"
                            type="button"
                        >
                            <i className="fas fa-external-link-alt" />
                        </Button>
                        <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target="tooltip542628903"
                        >
                            View Profile
                        </UncontrolledTooltip>
                    </Link>
                    <Button
                        className="btn-link"
                        color="danger"
                        data-toggle="tooltip"
                        id="tooltip16493734"
                        size="sm"
                        type="button"
                        onClick={onRemoveDistorted}
                    >
                        <i className="fa fa-times" />
                    </Button>
                    <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target="tooltip16493734"
                    >
                        Remove Distorted
                    </UncontrolledTooltip>
                </> : <>Deploying</>}
            </td>
        </tr>
    )
}

export default DistortedTableEntry