import * as React from 'react';
import {useState} from 'react';
import {API, graphqlOperation} from "aws-amplify"
import {getRefMse, getDisMse} from "../../graphql/queries"

import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

function FeatureExtraction(props) {
    const [refProgress, setRefProgress] = useState(props.ref_extract_feature_progress)
    const [disProgress, setDisProgress] = useState(props.dis_extract_feature_progress)
    const [refData, setRefData] = useState([])
    const [disData, setDisData] = useState([])
    const [chartOptions, setChartOptions] = useState({
        chart: {
            animation: false,
            zoomType: 'x',
            panning: true,
            panKey: 'shift',
        },
        series: [{name:"Reference", data:[], color: '#000000'}, {name:"Distorted", data:[], color: '##3380FF'}],
        xAxis: {
            title: {
                text: 'Frame number before aligning reference and distorted video'
            }
        },
        yAxis: {
            title: {
                text: 'Mean Square Error Frame(x, x+1)'
            }
        },
        legend: {
            enabled: true
        },
        title: {
            text: 'Alignment'
        },
        subtitle: {
            text: 'Feature extraction - scene change detection'
        },
        plotOptions: {
            series: {
                animation: false,
            }
        }
    });

    React.useEffect(() => {
        setRefProgress(props.ref_extract_feature_progress)
    }, [props.ref_extract_feature_progress])

    React.useEffect(() => {
        setDisProgress(props.dis_extract_feature_progress)
    }, [props.dis_extract_feature_progress])

    React.useEffect(() => {
        // setChartOptions({...chartOptions, 'series': [{data:vmafData}]})
        setChartOptions(prevChartOptions => {
            let updatedChartOptions = {...prevChartOptions}
            updatedChartOptions.series = [
                {name:"Reference", data:refData, color: '#000000'},
                {name:"Distorted", data:disData, color: '#3380FF'}]
            return updatedChartOptions
        })
    }, [refData, disData])


    React.useEffect(() => {
        async function updateData(reference_id, distorted_id) {
            let data_list = []
            if (reference_id){
                data_list = await API.graphql(
                    graphqlOperation(
                        getRefMse,
                        {
                            id: reference_id,
                            sequence: 0
                        }));
                if(data_list.data.getRefMSE){
                    setRefData(data_list.data.getRefMSE.values)
                }
            }
            if (distorted_id){
                data_list = await API.graphql(
                    graphqlOperation(
                        getDisMse,
                        {
                            id: distorted_id,
                            sequence: 0
                        }));
                if (data_list.data.getDisMSE){
                    setDisData(data_list.data.getDisMSE.values)
                }
            }
        }
        updateData(props.reference_id, props.distorted_id)
    }, [props.reference_id, props.distorted_id, refProgress, disProgress])


    return(
        <>
            <h4 className="title">
                <small>Feature extraction</small>
            </h4>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />
        </>
    )

}

export default FeatureExtraction;