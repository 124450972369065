import React, {useState} from "react";

// reactstrap components
import {
    Container,
    Row,
    Col, FormGroup,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import ReferencePageHeader from "components/Headers/ReferencePageHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import {API, Auth, graphqlOperation, Storage} from "aws-amplify";
import {getDistorted, getPsnRavg, getVmaf} from "../../graphql/queries";
import {useHistory, useParams} from "react-router-dom";
import CompareSpecs from "../../components/vqaas/CompareSpecs";
import CumulativeDistributionChart from "../../components/vqaas/CumulativeDistributionChart";
import MetricChart from "../../components/vqaas/MetricChart";
import Select from "react-select";
import {ReactCompareSlider} from "react-compare-slider";
import ReactPlayer from "react-player";

const getAccountWithSubscriptionsTeamsS3 = /* GraphQL */ `
    query GetAccount($id: ID!) {
      getAccount(id: $id) {
        id
        email
        owner
        s3_bucket
        s3_bucket_region
        subscriptions {
          items {
            subscription {
              name
              id
            }
          }
        }
        teams {
          items {
            team {
              name
              id
            }
          }
        }
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        cross_account_s3 {
          items {
            id
            Key
            ETag
            Size
            LastModified
            LastSeen
            accountID
            referenceID
            createdAt
            updatedAt
          }
          nextToken
        }
        credits
        createdAt
        updatedAt
      }
    }
  `;

function ComparePage() {
    const [account, setAccount] = useState();

    const {reference_id, distorted_id1, distorted_id2, distorted_id3, distorted_id4} = useParams()
    const [distorted1, setDistorted1] = useState();
    const [distorted2, setDistorted2] = useState();
    const [distorted3, setDistorted3] = useState();
    const [distorted4, setDistorted4] = useState();
    const [selectionList, setSelectionList] = useState();
    let history = useHistory();

    React.useEffect(() => {
        async function getMyAccount() {
            const user = await Auth.currentAuthenticatedUser()
            const result = await API.graphql(graphqlOperation(getAccountWithSubscriptionsTeamsS3, {
                id: user.username
            }))
            setAccount(result.data.getAccount)
        }
        getMyAccount().then()
    }, [])

    async function getMyDistorted(distorted_id) {
        const user = await Auth.currentAuthenticatedUser()
        const result = await API.graphql(graphqlOperation(getDistorted, {
            id: distorted_id,
            owner: user.username
        }))
        const graphql_getVmaf_response = await API.graphql(
            graphqlOperation(
                getVmaf,{
                    id: distorted_id,
                    sequence: 0
                })
        )
        if (graphql_getVmaf_response.data.getVMAF){
            result.data.getDistorted.vmaf = graphql_getVmaf_response.data.getVMAF.values
        }
        let graphql_getPSNRavg_response = await API.graphql(
            graphqlOperation(
                getPsnRavg,
                {
                    'id': distorted_id,
                    'sequence': 0
                })
        )
        if (graphql_getPSNRavg_response.data.getPSNRavg) {
            result.data.getDistorted.psnr = graphql_getPSNRavg_response.data.getPSNRavg.values
        }
        return result.data.getDistorted
    }

    React.useEffect(() => {
        if (distorted_id1) {
            getMyDistorted(distorted_id1).then(data => {setDistorted1(data)})
        }
    }, [reference_id, distorted_id1])

    React.useEffect(() => {
        if (distorted_id2) {
            getMyDistorted(distorted_id2).then(data => {setDistorted2(data)})
        }
    }, [distorted_id2])

    React.useEffect(() => {
        if (distorted_id3) {
            getMyDistorted(distorted_id3).then(data => {setDistorted3(data)})
        }
    }, [distorted_id3])

    React.useEffect(() => {
        if (distorted_id4) {
            getMyDistorted(distorted_id4).then(data => {setDistorted4(data)})
        }
    }, [distorted_id4])

    React.useEffect(() => {
        let newSelectionList = []
        if (distorted1){
            newSelectionList = newSelectionList.concat({
                value:distorted1.id,
                label:distorted1.filename,
                identityId:distorted1.user_identity_id,
                align_offset:distorted1.align_offset})
            setChannelA({
                value:distorted1.id,
                label:distorted1.filename,
                identityId:distorted1.user_identity_id,
                align_offset:distorted1.align_offset})
        }
        if (distorted2){
            newSelectionList = newSelectionList.concat({
                value:distorted2.id,
                label:distorted2.filename,
                identityId:distorted2.user_identity_id,
                align_offset:distorted2.align_offset})
            setChannelB({
                value:distorted2.id,
                label:distorted2.filename,
                identityId:distorted2.user_identity_id,
                align_offset:distorted2.align_offset})
        }
        if (distorted3){
            newSelectionList = newSelectionList.concat({
                value:distorted3.id,
                label:distorted3.filename,
                identityId:distorted3.user_identity_id,
                align_offset:distorted3.align_offset})
        }
        if (distorted4){
            newSelectionList = newSelectionList.concat({
                value:distorted4.id,
                label:distorted4.filename,
                identityId:distorted4.user_identity_id,
                align_offset:distorted4.align_offset})
        }
        setSelectionList(newSelectionList)
    }, [distorted1, distorted2, distorted3, distorted4])

    const [channelA, setChannelA] = React.useState()
    const [channelB, setChannelB] = React.useState()
    const [metric, setMetric] = React.useState({value:'VMAF', label:'VMAF'})
    const [dataAminB, setDataAminB] = React.useState()
    const playerChannelA = React.useRef()
    const playerChannelB = React.useRef()


    React.useEffect(() => {
        if (channelA && channelB){
            let A = {...distorted1}
            let B = {...distorted2}
            if (channelA.value === distorted1.id) { A = {...distorted1} }
            if (channelA.value === distorted2.id) { A = {...distorted2} }
            if (distorted3){
                if (channelA.value === distorted3.id) { A = {...distorted3} }
                if (channelB.value === distorted3.id) { B = {...distorted3} }
            }
            if (distorted4){
                if (channelA.value === distorted4.id) { A = {...distorted4} }
                if (channelB.value === distorted4.id) { B = {...distorted4} }
            }
            if (channelB.value === distorted1.id) { B = {...distorted1} }
            if (channelB.value === distorted2.id) { B = {...distorted2} }
            setDataAminB(
                {
                    vmaf: A.vmaf.map(function(item, index) { return item - B.vmaf[index]}),
                    psnr: A.psnr.map(function(item, index) { return item - B.psnr[index]})
                })
        }
    }, [channelA, channelB, distorted1, distorted2, distorted3, distorted4])

    function handleSeekTimeChange(e) {
        playerChannelA.current.seekTo(parseFloat(e) - (channelA.align_offset / parseFloat(distorted1.spec_frame_rate)))
        playerChannelB.current.seekTo(parseFloat(e) - (channelB.align_offset / parseFloat(distorted1.spec_frame_rate)))
    }
    const [signedUrlChannelA, setSignedUrlChannelA] = React.useState(null)
    const [signedUrlChannelB, setSignedUrlChannelB] = React.useState(null)

    React.useEffect(()=> {
        async function getAWSUrl() {
            if (channelA ){
                if (channelA.value){
                    const fileAccessURLChannelA = await Storage.get(
                        channelA.value + "/360_distorted_out_signed.m3u8",
                        {
                            level: 'protected',
                            identityId: channelA.identityId,
                            expires: 600 });
                    setSignedUrlChannelA(fileAccessURLChannelA);
                }
            }
        }
        getAWSUrl()
    }, [channelA])

    React.useEffect(()=> {
        async function getAWSUrl() {
            if (channelB){
                if (channelB.value){
                    const fileAccessURLChannelB = await Storage.get(
                        channelB.value + "/360_distorted_out_signed.m3u8",
                        {
                            level: 'protected',
                            identityId: channelB.identityId,
                            expires: 600 });
                    setSignedUrlChannelB(fileAccessURLChannelB);
                }
            }
        }
        getAWSUrl()
    }, [channelB])

    return (
        <>
            <ColorNavbar account={account} />
            <ReferencePageHeader />
            { account ?
            <div className="section">
                <Container className="tim-container">
                    <div className="title">
                        <h3>Compare</h3>
                    </div>
                    <CompareSpecs
                        distorted1 = {distorted1}
                        distorted2 = {distorted2}
                        distorted3 = {distorted3}
                        distorted4 = {distorted4}
                    />
                    <div className="title">
                        <CumulativeDistributionChart
                            metric = "VMAF"
                            distorted1 = {distorted1}
                            distorted2 = {distorted2}
                            distorted3 = {distorted3}
                            distorted4 = {distorted4}
                        />
                        <br/>
                        <CumulativeDistributionChart
                            metric = "PSNR"
                            distorted1 = {distorted1}
                            distorted2 = {distorted2}
                            distorted3 = {distorted3}
                            distorted4 = {distorted4}
                        />
                    </div>
                    <Row>
                        <Col md="1" sm="1" xs="1"><h5>A-B:</h5></Col>
                        <Col md="5" sm="5" xs="5">
                            <FormGroup>
                                <Select
                                    menuPlacement="top"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="curencySelect"
                                    value={channelA}
                                    placeholder=""
                                    onChange={(value) => setChannelA(value)}
                                    options={selectionList}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="1" sm="1" xs="1"><h5>min</h5></Col>
                        <Col md="5" sm="5" xs="5">
                            <FormGroup>
                                <Select
                                    menuPlacement="top"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="curencySelect"
                                    value={channelB}
                                    placeholder=""
                                    onChange={(value) => setChannelB(value)}
                                    options={selectionList}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="1" sm="1" xs="1"><h5>Metric:</h5></Col>
                        <Col md="2" sm="2" xs="4">
                            <FormGroup>
                                <Select
                                    menuPlacement="top"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="curencySelect"
                                    value={metric}
                                    placeholder=""
                                    onChange={(value) => setMetric(value)}
                                    options={[{value:'VMAF', label:'VMAF'}, {value:'PSNR', label:'PSNR'}]}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {distorted1 && distorted2 &&
                    <MetricChart
                        metric={metric.value}
                        distorted1={distorted1}
                        distorted2={distorted2}
                        distorted3={distorted3}
                        distorted4={distorted4}
                        dataAminB={dataAminB}
                        onSeekTimeChange={handleSeekTimeChange}
                        framerate={distorted1.spec_frame_rate}
                    />
                    }
                    <ReactCompareSlider
                        itemOne={
                            <ReactPlayer
                                width='100%'
                                height='100%'
                                url={signedUrlChannelA}
                                className="react-player"
                                ref={playerChannelA}
                                pip={false}
                                playing={false}
                                controls={false}
                                light={false}
                                loop={false}
                                playbackRate={1.0}
                                onStart={() => {}}
                                onPlay={() => {}}
                                onEnablePIP={() => {}}
                                onDisablePIP={() => {}}
                                onPause={() => {}}
                                onBuffer={() => {}}
                                onSeek={() => {}}
                                onEnded={() => {}}
                                onError={e => console.log('onError', e)}
                                onDuration={() => {}}
                            />
                        }
                        itemTwo={
                            <ReactPlayer
                                width='100%'
                                height='100%'
                                url={signedUrlChannelB}
                                className="react-player"
                                ref={playerChannelB}
                                pip={false}
                                playing={false}
                                controls={false}
                                light={false}
                                loop={false}
                                playbackRate={1.0}
                                onStart={() => {}}
                                onPlay={() => {}}
                                onEnablePIP={() => {}}
                                onDisablePIP={() => {}}
                                onPause={() => {}}
                                onBuffer={() => {}}
                                onSeek={() => {}}
                                onEnded={() => {}}
                                onError={e => console.log('onError', e)}
                                onDuration={() => {}}
                            />
                        }
                        style={{ width: '100%', height: '65vh' }}
                    />
                    <button
                        onClick={() => history.goBack()}
                        className="btn-link pull-left"
                        color="default"
                    >Back</button>

                </Container>
            </div> : <></> }
            <FooterBlack />
        </>
    );
}

export default ComparePage;
