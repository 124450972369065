import React, {useState} from "react";
// reactstrap components
import {
    Container,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import MediaPageHeader from "components/Headers/MediaPageHeader.js";
import AccountTable from "../../components/vqaas/AccountTable";
import FooterBlack from "components/Footers/FooterBlack.js";
import {API, Auth, graphqlOperation} from "aws-amplify";
import {createAccount} from "../../graphql/mutations";
const getAccountWithSubscriptionsTeamsS3 = /* GraphQL */ `
    query GetAccount($id: ID!) {
      getAccount(id: $id) {
        id
        email
        owner
        s3_bucket
        s3_bucket_region
        subscriptions {
          items {
            subscription {
              name
              id
            }
          }
        }
        teams {
          items {
            team {
              name
              id
            }
          }
        }
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        cross_account_s3 {
          items {
            id
            Key
            ETag
            Size
            LastModified
            LastSeen
            accountID
            referenceID
            createdAt
            updatedAt
          }
          nextToken
        }
        credits
        createdAt
        updatedAt
      }
    }
  `;
const gqlListdBAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
      }
      nextToken
    }
  }
`;

function ListAccounts() {
    const [accounts, setAccounts] = useState();
    const [account, setAccount] = useState();
    const [dbAccounts, setDbAccounts] = useState();

    /*
    function dynamicSort(property) {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
    */

    React.useEffect(() => {
        let nextToken;
        async function listAccounts(limit){
            let apiName = 'AdminQueries';
            let path = '/listUsers';
            let myInit = {
                queryStringParameters: {
                    "limit": limit,
                    "token": nextToken
                },
                headers: {
                    'Content-Type' : 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                }
            }
            const { NextToken, ...rest } =  await API.get(apiName, path, myInit);
            nextToken = NextToken;
            // console.log(rest)
            return rest;
        }
        async function listDbAccounts() {
            const result = await API.graphql(graphqlOperation(gqlListdBAccounts))
            // console.log(result.data.listAccounts.items)
            setDbAccounts(result.data.listAccounts.items)
        }
        async function getMyAccount() {
            const user = await Auth.currentAuthenticatedUser()
            const result = await API.graphql(graphqlOperation(getAccountWithSubscriptionsTeamsS3, {
                id: user.username
            }))
            setAccount(result.data.getAccount)
        }
        getMyAccount().then()
        listDbAccounts().then()
        listAccounts(60).then(data => setAccounts(data))
    }, [])

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("media-page");
        return function cleanup() {
            document.body.classList.remove("media-page");
        };
    });

    React.useEffect(() => {
        async function syncAccounts() {
            if (accounts && dbAccounts) {
                if (accounts.Users) {
                    for (let i = 0; i < accounts.Users.length; i++) {
                        console.log("Evaluate", accounts.Users[i])
                        let match = false
                        for (let j = 0; j < dbAccounts.length; j++) {
                            console.log("? Match with", dbAccounts[j].id)
                            if (accounts.Users[i].Username === dbAccounts[j].id) {
                                match = true
                                break
                            }
                        }
                        if (match === false) {
                            console.log("Add", accounts, "to database")
                            let new_email = ""
                            for (let k=0; k< accounts.Users[i].Attributes.length ; k++){
                                if (accounts.Users[i].Attributes[k].Name === "email"){
                                    new_email = accounts.Users[i].Attributes[k].Value
                                }
                            }
                            const addAccount = {
                                id: accounts.Users[i].Username,
                                email: new_email,
                                owner: accounts.Users[i].Username,
                                first_name: "",
                                last_name: "",
                                company_name: "",
                                s3_bucket: "",
                                s3_bucket_region: "",
                                description: "",
                                following: [],
                                followed_by: [],
                                num_references: 0,
                                num_distorteds: 0,
                                credits: 200
                            }
                            await API.graphql({query: createAccount, variables: {input: addAccount}});
                        }
                    }
                }
            }
        }
        syncAccounts().then()
    },[accounts, dbAccounts])

    return (
        <>
            <ColorNavbar account={account} />
            <MediaPageHeader />
            <div className="section">
                <Container className="tim-container">
                    <div className="title">
                        <h3 id="ReferenceLibrary">Accounts</h3>
                        {accounts ? <AccountTable accounts={accounts}/> : <></>}
                    </div>
                </Container>
            </div>
            <FooterBlack />
        </>
    );
}

export default ListAccounts;
