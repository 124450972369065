/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      company_name
      credits
      description
      email
      first_name
      following {
        name
        ownerID
        accepted
      }
      followed_by {
        name
        ownerID
        accepted
      }
      last_login
      last_upload
      last_name
      num_references
      num_distorteds
      owner
      refresh
      s3_bucket
      s3_bucket_region
      subscriptions {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      teams {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      user_identity_id
      createdAt
      updatedAt
      cross_account_s3 {
        items {
          id
          Key
          ETag
          Size
          LastModified
          LastSeen
          accountID
          referenceID
          createdAt
          updatedAt
        }
        nextToken
      }
      references {
        items {
          accountID
          createdAt
          cross_account_s3_id
          description
          filename
          viewers
          id
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_extract_feat
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          updatedAt
          user_identity_id
        }
        nextToken
      }
    }
  }
`;
export const listVQaaSSubscriptions = /* GraphQL */ `
  query ListVQaaSSubscriptions(
    $filter: ModelVQaaSSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVQaaSSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVQaaSSubscription = /* GraphQL */ `
  query GetVQaaSSubscription($id: ID!) {
    getVQaaSSubscription(id: $id) {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      references {
        items {
          id
          referenceID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        accounts {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const getCrossAccountS3 = /* GraphQL */ `
  query GetCrossAccountS3($id: ID!) {
    getCrossAccountS3(id: $id) {
      id
      Key
      ETag
      Size
      LastModified
      LastSeen
      accountID
      referenceID
      createdAt
      updatedAt
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;
export const listCrossAccountS3s = /* GraphQL */ `
  query ListCrossAccountS3s(
    $filter: ModelCrossAccountS3FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrossAccountS3s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Key
        ETag
        Size
        LastModified
        LastSeen
        accountID
        referenceID
        createdAt
        updatedAt
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCrossCorrelation = /* GraphQL */ `
  query GetCrossCorrelation($id: ID!) {
    getCrossCorrelation(id: $id) {
      id
      groups
      owner
      offset
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const listCrossCorrelations = /* GraphQL */ `
  query ListCrossCorrelations(
    $filter: ModelCrossCorrelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrossCorrelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groups
        owner
        offset
        sequence
        values
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDisMse = /* GraphQL */ `
  query GetDisMse($id: ID!) {
    getDisMSE(id: $id) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const listDisMsEs = /* GraphQL */ `
  query ListDisMsEs(
    $filter: ModelDisMSEFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDisMSEs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groups
        owner
        sequence
        values
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDistorted = /* GraphQL */ `
  query GetDistorted($id: ID!) {
    getDistorted(id: $id) {
      accountID
      align_offset
      createdAt
      cross_account_s3_id
      description
      filename
      persons
      subscriptions
      teams
      id
      metric_psnr
      metric_psnr_bin
      metric_psnr_cdf
      metric_vmaf
      metric_vmaf_bin
      metric_vmaf_cdf
      owner
      progress_calc_metric
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      reference_id
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      updatedAt
      user_identity_id
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;
export const listDistorteds = /* GraphQL */ `
  query ListDistorteds(
    $filter: ModelDistortedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDistorteds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        accountID
        align_offset
        createdAt
        cross_account_s3_id
        description
        filename
        persons
        subscriptions
        teams
        id
        metric_psnr
        metric_psnr_bin
        metric_psnr_cdf
        metric_vmaf
        metric_vmaf_bin
        metric_vmaf_cdf
        owner
        progress_calc_metric
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_extract_feat
        progress_upload
        reference_id
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        updatedAt
        user_identity_id
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPsnRavg = /* GraphQL */ `
  query GetPsnRavg($id: ID!) {
    getPSNRavg(id: $id) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const listPsnRavgs = /* GraphQL */ `
  query ListPsnRavgs(
    $filter: ModelPSNRavgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPSNRavgs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groups
        owner
        sequence
        values
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRefMse = /* GraphQL */ `
  query GetRefMse($id: ID!) {
    getRefMSE(id: $id) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const listRefMsEs = /* GraphQL */ `
  query ListRefMsEs(
    $filter: ModelRefMSEFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRefMSEs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groups
        owner
        sequence
        values
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listReferences = /* GraphQL */ `
  query ListReferences(
    $filter: ModelReferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReferences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        accountID
        createdAt
        cross_account_s3_id
        description
        filename
        viewers
        subscriptions {
          nextToken
        }
        id
        owner
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_extract_feat
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        updatedAt
        user_identity_id
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getReference = /* GraphQL */ `
  query GetReference($id: ID!) {
    getReference(id: $id) {
      accountID
      createdAt
      cross_account_s3_id
      description
      filename
      viewers
      subscriptions {
        items {
          id
          referenceID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      id
      owner
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      updatedAt
      user_identity_id
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;
export const getSsim = /* GraphQL */ `
  query GetSsim($id: ID!) {
    getSSIM(id: $id) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const listSsiMs = /* GraphQL */ `
  query ListSsiMs(
    $filter: ModelSSIMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSSIMs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groups
        owner
        sequence
        values
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVmaf = /* GraphQL */ `
  query GetVmaf($id: ID!) {
    getVMAF(id: $id) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const listVmaFs = /* GraphQL */ `
  query ListVmaFs(
    $filter: ModelVMAFFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVMAFs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groups
        owner
        sequence
        values
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
