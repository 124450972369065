/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCrossCorrelation = /* GraphQL */ `
  subscription OnCreateCrossCorrelation($owner: String!) {
    onCreateCrossCorrelation(owner: $owner) {
      id
      groups
      owner
      offset
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCrossCorrelation = /* GraphQL */ `
  subscription OnUpdateCrossCorrelation($owner: String!) {
    onUpdateCrossCorrelation(owner: $owner) {
      id
      groups
      owner
      offset
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCrossCorrelation = /* GraphQL */ `
  subscription OnDeleteCrossCorrelation($owner: String!) {
    onDeleteCrossCorrelation(owner: $owner) {
      id
      groups
      owner
      offset
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDisMse = /* GraphQL */ `
  subscription OnCreateDisMse($owner: String!) {
    onCreateDisMSE(owner: $owner) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDisMse = /* GraphQL */ `
  subscription OnUpdateDisMse($owner: String!) {
    onUpdateDisMSE(owner: $owner) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDisMse = /* GraphQL */ `
  subscription OnDeleteDisMse($owner: String!) {
    onDeleteDisMSE(owner: $owner) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDistorted = /* GraphQL */ `
  subscription OnCreateDistorted($owner: String!) {
    onCreateDistorted(owner: $owner) {
      accountID
      align_offset
      createdAt
      cross_account_s3_id
      description
      filename
      persons
      subscriptions
      teams
      id
      metric_psnr
      metric_psnr_bin
      metric_psnr_cdf
      metric_vmaf
      metric_vmaf_bin
      metric_vmaf_cdf
      owner
      progress_calc_metric
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      reference_id
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      updatedAt
      user_identity_id
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateDistorted = /* GraphQL */ `
  subscription OnUpdateDistorted($id: ID!, $owner: String!) {
    onUpdateDistorted(id: $id, owner: $owner) {
      accountID
      align_offset
      createdAt
      cross_account_s3_id
      description
      filename
      persons
      subscriptions
      teams
      id
      metric_psnr
      metric_psnr_bin
      metric_psnr_cdf
      metric_vmaf
      metric_vmaf_bin
      metric_vmaf_cdf
      owner
      progress_calc_metric
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      reference_id
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      updatedAt
      user_identity_id
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateDistortedRefId = /* GraphQL */ `
  subscription OnUpdateDistortedRefId($reference_id: String, $owner: String) {
    onUpdateDistortedRefId(reference_id: $reference_id, owner: $owner) {
      accountID
      align_offset
      createdAt
      cross_account_s3_id
      description
      filename
      persons
      subscriptions
      teams
      id
      metric_psnr
      metric_psnr_bin
      metric_psnr_cdf
      metric_vmaf
      metric_vmaf_bin
      metric_vmaf_cdf
      owner
      progress_calc_metric
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      reference_id
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      updatedAt
      user_identity_id
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteDistorted = /* GraphQL */ `
  subscription OnDeleteDistorted($owner: String!) {
    onDeleteDistorted(owner: $owner) {
      accountID
      align_offset
      createdAt
      cross_account_s3_id
      description
      filename
      persons
      subscriptions
      teams
      id
      metric_psnr
      metric_psnr_bin
      metric_psnr_cdf
      metric_vmaf
      metric_vmaf_bin
      metric_vmaf_cdf
      owner
      progress_calc_metric
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      reference_id
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      updatedAt
      user_identity_id
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;
export const onCreatePsnRavg = /* GraphQL */ `
  subscription OnCreatePsnRavg($owner: String!) {
    onCreatePSNRavg(owner: $owner) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePsnRavg = /* GraphQL */ `
  subscription OnUpdatePsnRavg($owner: String!) {
    onUpdatePSNRavg(owner: $owner) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePsnRavg = /* GraphQL */ `
  subscription OnDeletePsnRavg($owner: String!) {
    onDeletePSNRavg(owner: $owner) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRefMse = /* GraphQL */ `
  subscription OnCreateRefMse($owner: String!) {
    onCreateRefMSE(owner: $owner) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRefMse = /* GraphQL */ `
  subscription OnUpdateRefMse($owner: String!) {
    onUpdateRefMSE(owner: $owner) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRefMse = /* GraphQL */ `
  subscription OnDeleteRefMse($owner: String!) {
    onDeleteRefMSE(owner: $owner) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const onCreateReference = /* GraphQL */ `
  subscription OnCreateReference($owner: String!) {
    onCreateReference(owner: $owner) {
      accountID
      createdAt
      cross_account_s3_id
      description
      filename
      viewers
      subscriptions {
        items {
          id
          referenceID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      id
      owner
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      updatedAt
      user_identity_id
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateReference = /* GraphQL */ `
  subscription OnUpdateReference($owner: String!) {
    onUpdateReference(owner: $owner) {
      accountID
      createdAt
      cross_account_s3_id
      description
      filename
      viewers
      subscriptions {
        items {
          id
          referenceID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      id
      owner
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      updatedAt
      user_identity_id
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteReference = /* GraphQL */ `
  subscription OnDeleteReference($owner: String!) {
    onDeleteReference(owner: $owner) {
      accountID
      createdAt
      cross_account_s3_id
      description
      filename
      viewers
      subscriptions {
        items {
          id
          referenceID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      id
      owner
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      updatedAt
      user_identity_id
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;
export const onCreateSsim = /* GraphQL */ `
  subscription OnCreateSsim($owner: String!) {
    onCreateSSIM(owner: $owner) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSsim = /* GraphQL */ `
  subscription OnUpdateSsim($owner: String!) {
    onUpdateSSIM(owner: $owner) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSsim = /* GraphQL */ `
  subscription OnDeleteSsim($owner: String!) {
    onDeleteSSIM(owner: $owner) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const onCreateVmaf = /* GraphQL */ `
  subscription OnCreateVmaf($owner: String!) {
    onCreateVMAF(owner: $owner) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateVmaf = /* GraphQL */ `
  subscription OnUpdateVmaf($owner: String!) {
    onUpdateVMAF(owner: $owner) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteVmaf = /* GraphQL */ `
  subscription OnDeleteVmaf($owner: String!) {
    onDeleteVMAF(owner: $owner) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAccountSubscription = /* GraphQL */ `
  subscription OnCreateAccountSubscription {
    onCreateAccountSubscription {
      id
      accountID
      subscriptionID
      createdAt
      updatedAt
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
      subscription {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateAccountSubscription = /* GraphQL */ `
  subscription OnUpdateAccountSubscription {
    onUpdateAccountSubscription {
      id
      accountID
      subscriptionID
      createdAt
      updatedAt
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
      subscription {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteAccountSubscription = /* GraphQL */ `
  subscription OnDeleteAccountSubscription {
    onDeleteAccountSubscription {
      id
      accountID
      subscriptionID
      createdAt
      updatedAt
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
      subscription {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateAccountTeam = /* GraphQL */ `
  subscription OnCreateAccountTeam {
    onCreateAccountTeam {
      id
      accountID
      teamID
      createdAt
      updatedAt
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateAccountTeam = /* GraphQL */ `
  subscription OnUpdateAccountTeam {
    onUpdateAccountTeam {
      id
      accountID
      teamID
      createdAt
      updatedAt
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteAccountTeam = /* GraphQL */ `
  subscription OnDeleteAccountTeam {
    onDeleteAccountTeam {
      id
      accountID
      teamID
      createdAt
      updatedAt
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateReferenceSubscription = /* GraphQL */ `
  subscription OnCreateReferenceSubscription {
    onCreateReferenceSubscription {
      id
      referenceID
      subscriptionID
      createdAt
      updatedAt
      subscription {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        createdAt
        updatedAt
      }
      reference {
        accountID
        createdAt
        cross_account_s3_id
        description
        filename
        viewers
        subscriptions {
          nextToken
        }
        id
        owner
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_extract_feat
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        updatedAt
        user_identity_id
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateReferenceSubscription = /* GraphQL */ `
  subscription OnUpdateReferenceSubscription {
    onUpdateReferenceSubscription {
      id
      referenceID
      subscriptionID
      createdAt
      updatedAt
      subscription {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        createdAt
        updatedAt
      }
      reference {
        accountID
        createdAt
        cross_account_s3_id
        description
        filename
        viewers
        subscriptions {
          nextToken
        }
        id
        owner
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_extract_feat
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        updatedAt
        user_identity_id
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteReferenceSubscription = /* GraphQL */ `
  subscription OnDeleteReferenceSubscription {
    onDeleteReferenceSubscription {
      id
      referenceID
      subscriptionID
      createdAt
      updatedAt
      subscription {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        createdAt
        updatedAt
      }
      reference {
        accountID
        createdAt
        cross_account_s3_id
        description
        filename
        viewers
        subscriptions {
          nextToken
        }
        id
        owner
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_extract_feat
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        updatedAt
        user_identity_id
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateAccount = /* GraphQL */ `
  subscription OnCreateAccount {
    onCreateAccount {
      id
      company_name
      credits
      description
      email
      first_name
      following {
        name
        ownerID
        accepted
      }
      followed_by {
        name
        ownerID
        accepted
      }
      last_login
      last_upload
      last_name
      num_references
      num_distorteds
      owner
      refresh
      s3_bucket
      s3_bucket_region
      subscriptions {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      teams {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      user_identity_id
      createdAt
      updatedAt
      cross_account_s3 {
        items {
          id
          Key
          ETag
          Size
          LastModified
          LastSeen
          accountID
          referenceID
          createdAt
          updatedAt
        }
        nextToken
      }
      references {
        items {
          accountID
          createdAt
          cross_account_s3_id
          description
          filename
          viewers
          id
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_extract_feat
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          updatedAt
          user_identity_id
        }
        nextToken
      }
    }
  }
`;
export const onUpdateAccount = /* GraphQL */ `
  subscription OnUpdateAccount {
    onUpdateAccount {
      id
      company_name
      credits
      description
      email
      first_name
      following {
        name
        ownerID
        accepted
      }
      followed_by {
        name
        ownerID
        accepted
      }
      last_login
      last_upload
      last_name
      num_references
      num_distorteds
      owner
      refresh
      s3_bucket
      s3_bucket_region
      subscriptions {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      teams {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      user_identity_id
      createdAt
      updatedAt
      cross_account_s3 {
        items {
          id
          Key
          ETag
          Size
          LastModified
          LastSeen
          accountID
          referenceID
          createdAt
          updatedAt
        }
        nextToken
      }
      references {
        items {
          accountID
          createdAt
          cross_account_s3_id
          description
          filename
          viewers
          id
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_extract_feat
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          updatedAt
          user_identity_id
        }
        nextToken
      }
    }
  }
`;
export const onDeleteAccount = /* GraphQL */ `
  subscription OnDeleteAccount {
    onDeleteAccount {
      id
      company_name
      credits
      description
      email
      first_name
      following {
        name
        ownerID
        accepted
      }
      followed_by {
        name
        ownerID
        accepted
      }
      last_login
      last_upload
      last_name
      num_references
      num_distorteds
      owner
      refresh
      s3_bucket
      s3_bucket_region
      subscriptions {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      teams {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      user_identity_id
      createdAt
      updatedAt
      cross_account_s3 {
        items {
          id
          Key
          ETag
          Size
          LastModified
          LastSeen
          accountID
          referenceID
          createdAt
          updatedAt
        }
        nextToken
      }
      references {
        items {
          accountID
          createdAt
          cross_account_s3_id
          description
          filename
          viewers
          id
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_extract_feat
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          updatedAt
          user_identity_id
        }
        nextToken
      }
    }
  }
`;
export const onCreateVQaaSSubscription = /* GraphQL */ `
  subscription OnCreateVQaaSSubscription {
    onCreateVQaaSSubscription {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      references {
        items {
          id
          referenceID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateVQaaSSubscription = /* GraphQL */ `
  subscription OnUpdateVQaaSSubscription {
    onUpdateVQaaSSubscription {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      references {
        items {
          id
          referenceID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteVQaaSSubscription = /* GraphQL */ `
  subscription OnDeleteVQaaSSubscription {
    onDeleteVQaaSSubscription {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      references {
        items {
          id
          referenceID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTeam = /* GraphQL */ `
  subscription OnCreateTeam {
    onCreateTeam {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTeam = /* GraphQL */ `
  subscription OnUpdateTeam {
    onUpdateTeam {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTeam = /* GraphQL */ `
  subscription OnDeleteTeam {
    onDeleteTeam {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCrossAccountS3 = /* GraphQL */ `
  subscription OnCreateCrossAccountS3 {
    onCreateCrossAccountS3 {
      id
      Key
      ETag
      Size
      LastModified
      LastSeen
      accountID
      referenceID
      createdAt
      updatedAt
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateCrossAccountS3 = /* GraphQL */ `
  subscription OnUpdateCrossAccountS3 {
    onUpdateCrossAccountS3 {
      id
      Key
      ETag
      Size
      LastModified
      LastSeen
      accountID
      referenceID
      createdAt
      updatedAt
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteCrossAccountS3 = /* GraphQL */ `
  subscription OnDeleteCrossAccountS3 {
    onDeleteCrossAccountS3 {
      id
      Key
      ETag
      Size
      LastModified
      LastSeen
      accountID
      referenceID
      createdAt
      updatedAt
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;
