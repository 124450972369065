import React from "react";

// reactstrap components
import {
    Button,
    Progress,
    Table,
    UncontrolledTooltip,
} from "reactstrap";
import {Storage} from "aws-amplify";


function DistortedProcessing(props) {
    const [signedUrlExtractES, setSignedUrlExtractES] = React.useState(null)
    const [signedUrlDecode, setSignedUrlDecode] = React.useState(null)
    const [signedUrlExtractFeat, setSignedUrlExtractFeat] = React.useState(null)
    const [signedUrlCreateHLS, setSignedUrlCreateHLS] = React.useState(null)
    const [signedUrlCalcMetric, setSignedUrlCalcMetric] = React.useState(null)
    const [signedUrlJSON, setSignedUrlJSON] = React.useState(null)
    const [signedUrlAlignment, setSignedUrlAlignment] = React.useState(null)

    React.useEffect(()=> {
        async function getAWSUrl() {
            if (props.disProc.progress_extract_es === 100) {
                const fileAccessURLReference = await Storage.get(
                    props.disProc.id + "/distorted.es.txt",
                    {
                        level: 'protected',
                        identityId: props.disProc.user_identity_id,
                        expires: 21600 });
                setSignedUrlExtractES(fileAccessURLReference);
            }
        }
        getAWSUrl()
    }, [props.disProc.id, props.disProc.progress_extract_es, props.disProc.user_identity_id])

    React.useEffect(()=> {
        async function getAWSUrl() {
            if (props.disProc.progress_decode === 100) {
                const fileAccessURLReference = await Storage.get(
                    props.disProc.id + "/distorted.decode.txt",
                    {
                        level: 'protected',
                        identityId: props.disProc.user_identity_id,
                        expires: 21600 });
                setSignedUrlDecode(fileAccessURLReference);
            }
        }
        getAWSUrl()
    }, [props.disProc.id, props.disProc.progress_decode, props.disProc.user_identity_id])

    React.useEffect(()=> {
        async function getAWSUrl() {
            if (props.disProc.progress_extract_feat === 100) {
                const fileAccessURLReference = await Storage.get(
                    props.disProc.id + "/distorted.extract_feature.txt",
                    {
                        level: 'protected',
                        identityId: props.disProc.user_identity_id,
                        expires: 21600 });
                setSignedUrlExtractFeat(fileAccessURLReference);
            }
        }
        getAWSUrl()
    }, [props.disProc.id, props.disProc.progress_extract_feat, props.disProc.user_identity_id])

    React.useEffect(()=> {
        async function getAWSUrl() {
            if (props.disProc.progress_create_hls === 100) {
                const fileAccessURLReference = await Storage.get(
                    props.disProc.id + "/distorted.hls.txt",
                    {
                        level: 'protected',
                        identityId: props.disProc.user_identity_id,
                        expires: 21600 });
                setSignedUrlCreateHLS(fileAccessURLReference);
            }
        }
        getAWSUrl()
    }, [props.disProc.id, props.disProc.progress_create_hls, props.disProc.user_identity_id])

    React.useEffect(()=> {
        async function getAWSUrl() {
            if (props.disProc.progress_calc_metric === 100) {
                const fileAccessURLReference = await Storage.get(
                    props.disProc.id + "/vmaf.txt",
                    {
                        level: 'protected',
                        identityId: props.disProc.user_identity_id,
                        expires: 21600 });
                setSignedUrlCalcMetric(fileAccessURLReference);
                const fileAccessJSON = await Storage.get(
                    props.disProc.id + "/vmaf.json",
                    {
                        level: 'protected',
                        identityId: props.disProc.user_identity_id,
                        expires: 21600 });
                setSignedUrlJSON(fileAccessJSON);
            }
        }
        getAWSUrl()
    }, [props.disProc.id, props.disProc.progress_calc_metric, props.disProc.user_identity_id])

    React.useEffect(()=> {
        async function getAWSUrl() {
            let fileAccessURLReference
            if (props.disProc.progress_calc_metric === 100 && props.disProc.align_offset) {
                if (props.disProc.align_offset < 0){
                    fileAccessURLReference = await Storage.get(
                        props.disProc.id + "/distorted.cut.txt",
                        {
                            level: 'protected',
                            identityId: props.disProc.user_identity_id,
                            expires: 21600 });
                }
                if (props.disProc.align_offset > 0){
                    fileAccessURLReference = await Storage.get(
                        props.disProc.id + "/reference.cut.txt",
                        {
                            level: 'protected',
                            identityId: props.disProc.user_identity_id,
                            expires: 21600 });
                }
                setSignedUrlAlignment(fileAccessURLReference);
            }
        }
        getAWSUrl()
    }, [props.disProc.id, props.disProc.progress_calc_metric, props.disProc.align_offset, props.disProc.user_identity_id])

    return (
        <>
            <h4 className="title">
                <small>Processing</small>
            </h4>
            <Table responsive>
                <thead>
                <tr>
                    <th>Process</th>
                    <th>Progress</th>
                    <th/>
                    <th className="text-right">Log</th>
                </tr>
                </thead>
                <tbody>
                {props.disProc.progress_cross_copy ?
                    <tr>
                        <td>Cross Account S3 copy</td>
                        <td> {props.disProc.progress_cross_copy === 100 ? "Completed" :
                            <Progress
                                animated
                                max="100"
                                value={props.disProc.progress_cross_copy ? props.disProc.progress_cross_copy : 0}
                                barClassName="progress-bar-success"/>
                        }
                        </td>
                        <td>{props.disProc.progress_cross_copy ? props.disProc.progress_cross_copy + "%": ""}</td>
                        <td/>
                    </tr> : <></> }

                { props.disProc.progress_extract_es ?
                    <tr>
                    <td>Extract Elementary stream</td>
                    <td>{props.disProc.progress_extract_es === 100 ? "Completed" :
                        <Progress
                            animated
                            max="100"
                            value={props.disProc.progress_extract_es ? props.disProc.progress_extract_es : 0}
                            barClassName="progress-bar-success"/>
                    }
                    </td>
                    <td>{props.disProc.progress_extract_es ? props.disProc.progress_extract_es + "%": ""}</td>
                    <td className="td-actions text-right">
                        {
                            (props.disProc.progress_extract_es === 100) ?
                                <a href={signedUrlExtractES} target="_blank" rel="noopener noreferrer">
                                    <Button
                                        className="btn-link mr-1"
                                        color="success"
                                        data-toggle="tooltip"
                                        id="tooltip_es_378266693"
                                        size="sm"
                                        type="button"
                                    >
                                        <i className="fa fa-file"/>
                                    </Button>
                                    <UncontrolledTooltip
                                        delay={0}
                                        placement="top"
                                        target="tooltip_es_378266693"
                                    >
                                        View Log
                                    </UncontrolledTooltip>
                                </a>
                                :
                                ""
                        }
                    </td>
                </tr> : <></> }
                {props.disProc.progress_decode ?
                    <tr>
                    <td>Decode</td>
                    <td> {props.disProc.progress_decode === 100 ? "Completed" :
                        <Progress
                        animated
                        max="100"
                        value={props.disProc.progress_decode ? props.disProc.progress_decode : 0}
                        barClassName="progress-bar-success"/>
                    }
                    </td>
                    <td>{props.disProc.progress_decode ? props.disProc.progress_decode + "%": ""}</td>
                    <td className="td-actions text-right">
                        {
                            (props.disProc.progress_decode === 100) ?

                                <a href={signedUrlDecode} target="_blank" rel="noopener noreferrer">
                                    <Button
                                        className="btn-link mr-1"
                                        color="success"
                                        data-toggle="tooltip"
                                        id="tooltip_decode_378266693"
                                        size="sm"
                                        type="button"
                                    >
                                        <i className="fa fa-file"/>
                                    </Button>
                                    <UncontrolledTooltip
                                        delay={0}
                                        placement="top"
                                        target="tooltip_decode_378266693"
                                    >
                                        View Log
                                    </UncontrolledTooltip>
                                </a>
                                :
                                ""
                        }
                    </td>
                </tr> : <></> }
                {props.disProc.progress_extract_feat ?
                    <tr>
                    <td>Extract features</td>
                    <td> {props.disProc.progress_extract_feat === 100 ? "Completed" :
                        <Progress
                            animated
                            max="100"
                            value={props.disProc.progress_extract_feat ? props.disProc.progress_extract_feat : 0}
                            barClassName="progress-bar-success"/>
                    }
                    </td>
                    <td>{props.disProc.progress_extract_feat ? props.disProc.progress_extract_feat + "%": ""}</td>
                    <td className="td-actions text-right">
                        {
                            (props.disProc.progress_extract_feat === 100) ?
                                <a href={signedUrlExtractFeat} target="_blank" rel="noopener noreferrer">
                                    <Button
                                        className="btn-link mr-1"
                                        color="success"
                                        data-toggle="tooltip"
                                        id="tooltip_extract_378266693"
                                        size="sm"
                                        type="button"
                                    >
                                        <i className="fa fa-file" />
                                    </Button>
                                    <UncontrolledTooltip
                                        delay={0}
                                        placement="top"
                                        target="tooltip_extract_378266693"
                                    >
                                        View Log
                                    </UncontrolledTooltip>
                                </a>
                                :
                                ""
                        }
                    </td>
                </tr> : <></> }
                {props.disProc.progress_create_hls ?
                    <tr>
                        <td>Create HLS stream</td>
                        <td> {props.disProc.progress_create_hls === 100 ? "Completed" :
                            <Progress
                                animated
                                max="100"
                                value={props.disProc.progress_create_hls ? props.disProc.progress_create_hls : 0}
                                barClassName="progress-bar-success"/>
                        }
                        </td>
                        <td>{props.disProc.progress_create_hls ? props.disProc.progress_create_hls + "%": ""}</td>
                        <td className="td-actions text-right">
                            {
                                (props.disProc.progress_create_hls === 100) ?
                                    <a href={signedUrlCreateHLS} target="_blank" rel="noopener noreferrer">
                                        <Button
                                            className="btn-link mr-1"
                                            color="success"
                                            data-toggle="tooltip"
                                            id="tooltip_hls_378266693"
                                            size="sm"
                                            type="button"
                                        >
                                            <i className="fa fa-file" />
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            placement="top"
                                            target="tooltip_hls_378266693"
                                        >
                                            View Log
                                        </UncontrolledTooltip>
                                    </a>
                                    :
                                    ""
                            }
                        </td>
                    </tr> : <></> }
                {props.disProc.progress_calc_metric ?
                    <tr>
                    <td>Calculate Metrics</td>
                    <td> {props.disProc.progress_calc_metric === 100 ? "Completed" :
                        <Progress
                            animated
                            max="100"
                            value={props.disProc.progress_calc_metric ? props.disProc.progress_calc_metric : 0}
                            barClassName="progress-bar-success"/>
                    }
                    </td>
                    <td>{props.disProc.progress_calc_metric ? props.disProc.progress_calc_metric + "%": ""}</td>
                    <td className="td-actions text-right">
                        {
                            (props.disProc.progress_calc_metric === 100) ?
                                <>
                                    <a href={signedUrlJSON} target="_blank" rel="noopener noreferrer">
                                        <Button
                                            className="btn-link mr-1"
                                            color="success"
                                            data-toggle="tooltip"
                                            id="tooltip_calc_378266693"
                                            size="sm"
                                            type="button"
                                        >
                                            <i className="fas fa-poll"/>
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            placement="top"
                                            target="tooltip_calc_378266693"
                                        >
                                            View Results
                                        </UncontrolledTooltip>
                                    </a>
                                    <a href={signedUrlCalcMetric} target="_blank" rel="noopener noreferrer">
                                        <Button
                                            className="btn-link mr-1"
                                            color="success"
                                            data-toggle="tooltip"
                                            id="tooltip_calc_log_378266693"
                                            size="sm"
                                            type="button"
                                        >
                                            <i className="fa fa-file" />
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            placement="top"
                                            target="tooltip_calc_log_378266693"
                                        >
                                            View Log
                                        </UncontrolledTooltip>
                                    </a>
                                </>
                                :
                                ""
                        }
                    </td>
                </tr> : <></> }
                </tbody>
            </Table>
        </>
    );
}

export default DistortedProcessing;
