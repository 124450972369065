import React from "react";

function DurationFramesCell(props) {
    return (
        <>
            { props.distorted ?
                <td>
                    {
                        props.distorted.spec_duration_frames ?
                            props.distorted.spec_duration_frames + ' frames'
                            :
                            ''
                    }
                </td>: <td/>
            }
        </>
    );
}

export default DurationFramesCell;
