import React, { useState } from "react";

// reactstrap components
import {
    Button,
    FormGroup,
    Input,
    Container,
    Row,
    Col, Form,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import MediaPageHeader from "../../components/Headers/MediaPageHeader";
import {API, Auth, graphqlOperation} from "aws-amplify";
import {v4 as uuid} from "uuid";
import { useHistory } from "react-router-dom";
import {createAccount} from "../../graphql/mutations";
const getAccountWithSubscriptionsTeams = /* GraphQL */ `
    query GetAccount($id: ID!) {
      getAccount(id: $id) {
        id
        email
        owner
        s3_bucket
        subscriptions {
          items {
            subscription {
              name
              id
            }
          }
        }
        teams {
          items {
            team {
              name
              id
            }
          }
        }
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        credits
        createdAt
        updatedAt
      }
    }
  `;

function AddAccount() {
    const [account, setAccount] = useState();

    React.useEffect(() => {
        async function getMyAccount() {
            const user = await Auth.currentAuthenticatedUser()
            const result = await API.graphql(graphqlOperation(getAccountWithSubscriptionsTeams, {
                id: user.username
            }))
            setAccount(result.data.getAccount)
        }
        getMyAccount().then()
    }, [])

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [email, setEmail] = useState("")
    const [showSave, setShowSave] = useState(false)
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("add-product");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("add-product");
        };
    });
    let history = useHistory();

    React.useEffect(() => {
        setShowSave(firstName !== "" && lastName !== "" && companyName !== "" && email !== "")
    }, [firstName, lastName, companyName, email])

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        let new_uuid = uuid()
        const addAccount = {
            id: new_uuid,
            email: email,
            owner: new_uuid,
            first_name: firstName,
            last_name: lastName,
            company_name: companyName,
            s3_bucket: "",
            description: "",
            following: [],
            followed_by: [],
            num_references: 0,
            num_distorteds: 0,
            credits: 200
        }
        await API.graphql({ query: createAccount, variables: { input: addAccount } });
        history.goBack()
    }


    return (
        <>
            <ColorNavbar account={account}/>
            <MediaPageHeader />
            <div className="main">
                <div className="section">
                    <Container>
                        <h3>Create Account</h3>
                        <div>
                            <Row>
                                <Col className="ml-auto mr-auto" md="9">
                                    <h6>Account information</h6>
                                    <Form
                                        className="settings-form"
                                        onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md="6" sm="6">
                                                <FormGroup>
                                                    <label>First Name</label><span className="icon-danger">*</span>
                                                    <Input
                                                        className="border-input"
                                                        placeholder="First Name"
                                                        onChange={e => setFirstName(e.target.value)}
                                                        value={firstName}
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6" sm="6">
                                                <FormGroup>
                                                    <label>Last Name</label><span className="icon-danger">*</span>
                                                    <Input
                                                        className="border-input"
                                                        placeholder="Last Name"
                                                        onChange={e => setLastName(e.target.value)}
                                                        value={lastName}
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup>
                                            <label>Company name</label><span className="icon-danger">*</span>
                                            <Input
                                                className="border-input"
                                                placeholder="Company name"
                                                onChange={e => setCompanyName(e.target.value)}
                                                value={companyName}
                                                type="text"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <label>Email</label><span className="icon-danger">*</span>
                                            <Input
                                                className="border-input"
                                                placeholder="john.doe@company.com"
                                                onChange={e => setEmail(e.target.value)}
                                                value={email}
                                                type="text"
                                            />
                                        </FormGroup>
                                        <Row className="buttons-row">
                                            <Col md="4" sm="4">
                                                <Button
                                                    block
                                                    className="btn-round"
                                                    color="danger"
                                                    outline
                                                    onClick={() => history.goBack()}
                                                    type="reset"
                                                >
                                                    Cancel
                                                </Button>
                                            </Col>
                                            {showSave ?
                                            <Col md="4" sm="4">
                                                <Button
                                                    block
                                                    className="btn-round"
                                                    color="primary"
                                                    outline
                                                    type="submit"
                                                >
                                                    Save
                                                </Button>
                                            </Col> : <></>}
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </div>
            <FooterBlack />
        </>
    );
}

export default AddAccount;
