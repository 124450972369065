import React, {useState} from "react";
// reactstrap components
import {
    Container,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import MediaPageHeader from "components/Headers/MediaPageHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import {API, Auth, graphqlOperation} from "aws-amplify";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {listDistorteds, listReferences} from "../../graphql/queries";
const getAccountWithSubscriptionsTeamsS3 = /* GraphQL */ `
    query GetAccount($id: ID!) {
      getAccount(id: $id) {
        id
        email
        owner
        s3_bucket
        s3_bucket_region
        subscriptions {
          items {
            subscription {
              name
              id
            }
          }
        }
        teams {
          items {
            team {
              name
              id
            }
          }
        }
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        cross_account_s3 {
          items {
            id
            Key
            ETag
            Size
            LastModified
            LastSeen
            accountID
            referenceID
            createdAt
            updatedAt
          }
          nextToken
        }
        credits
        createdAt
        updatedAt
      }
    }
  `;
const gqlListdBAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
      }
      nextToken
    }
  }
`;


function DashboardAccounts() {
    const [accounts, setAccounts] = useState();
    const [distorteds, setDistorteds] = useState([])
    const [references, setReferences] = useState([])
    const [account, setAccount] = useState();
    const [dbAccounts, setDbAccounts] = useState();
    const chartRef = React.useRef()
    const barChartRef = React.useRef()
    const [pieChartData, setPieChartData] = useState([])
    const [pieChartOptions, setPieChartOptions] = useState( {
        chart: {
            type: 'pie'
        },
        title: {
            text: 'Activity by user'
        },
        accessibility: {
            announceNewData: {
                enabled: true
            },
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.y:.1f}%'
                }
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
        },
        series: [
            {
                name: "Users",
                colorByPoint: true,
                data: pieChartData
            }
        ],
        })
    const [barChartData, setBarChartData] = useState([])
    const [barChartOptions, setBarChartOptions] = useState({
        chart: {
            type: 'column'
        },
        title: {
            text: 'Monthly Activity by user'
        },
        xAxis: {
            categories: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec'
            ],
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: '# clips uploaded'
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: barChartData
    })
    const chartSynaRef = React.useRef()
    const barChartSynaRef = React.useRef()
    const [pieChartSynaData, setPieChartSynaData] = useState([])
    const [pieChartSynaOptions, setPieChartSynaOptions] = useState( {
        chart: {
            type: 'pie'
        },
        title: {
            text: 'Activity by Synamedia user'
        },
        accessibility: {
            announceNewData: {
                enabled: true
            },
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.y:.1f}%'
                }
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
        },
        series: [
            {
                name: "Users",
                colorByPoint: true,
                data: pieChartSynaData
            }
        ],
    })
    const [barChartSynaData, setBarChartSynaData] = useState([])
    const [barChartSynaOptions, setBarChartSynaOptions] = useState({
        chart: {
            type: 'column'
        },
        title: {
            text: 'Monthly Activity by Synamedia user'
        },
        xAxis: {
            categories: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec'
            ],
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: '# clips uploaded'
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: barChartSynaData
    })
    const chartCompanyRef = React.useRef()
    const barChartCompanyRef = React.useRef()
    const [pieChartCompanyData, setPieChartCompanyData] = useState([])
    const [pieChartCompanyOptions, setPieChartCompanyOptions] = useState( {
        chart: {
            type: 'pie'
        },
        title: {
            text: 'Activity by Company Name'
        },
        accessibility: {
            announceNewData: {
                enabled: true
            },
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.y:.1f}%'
                }
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
        },
        series: [
            {
                name: "Users",
                colorByPoint: true,
                data: pieChartSynaData
            }
        ],
    })
    const [barChartCompanyData, setBarChartCompanyData] = useState([])
    const [barChartCompanyOptions, setBarChartCompanyOptions] = useState({
        chart: {
            type: 'column'
        },
        title: {
            text: 'Monthly Activity by Company Name'
        },
        xAxis: {
            categories: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec'
            ],
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: '# clips uploaded'
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: barChartSynaData
    })

    React.useEffect(() => {
        async function listMyDistorteds() {
            const result = await API.graphql(graphqlOperation(listDistorteds, {
                limit: 999,
            }))
            setDistorteds(result.data.listDistorteds.items)
        }
        listMyDistorteds()
    }, [])

    React.useEffect(() => {
        async function listMyReferences() {
            const result = await API.graphql(graphqlOperation(listReferences, {
                limit: 999,
            }))
            setReferences(result.data.listReferences.items)
        }
        listMyReferences()
    }, [])

    React.useEffect(() => {
        let data = []
        let data_by_month = []
        let syna_data = []
        let syna_data_by_month = []
        let company_data = []
        let company_data_by_month = []
        for (let i=0; i < references.length; i++){
            let month = parseInt(references[i].createdAt.split("-")[1])
            console.log(month)
            if (references[i].account) {
                let user_name = references[i].account.first_name + ' ' + references[i].account.last_name
                let company_name = references[i].account.company_name
                console.log(user_name, references[i].account.email)
                if (company_name === 'Synamedia'){
                    let found_match = false
                    for (let j=0; j< syna_data.length; j++){
                        if (syna_data[j].name === user_name){
                            syna_data[j].y += 1
                            syna_data_by_month[j].data[month-1] += 1
                            found_match = true
                        }
                    }
                    if (found_match === false){
                        syna_data.push({name:user_name, y:1})
                        let new_data_by_month = {name:user_name, data:[0,0,0,0,0,0,0,0,0,0,0,0]}
                        new_data_by_month.data[month-1] = 1
                        syna_data_by_month.push(new_data_by_month)
                    }
                } else {
                    let found_match = false
                    for (let j=0; j< data.length; j++){
                        if (data[j].name === user_name){
                            data[j].y += 1
                            data_by_month[j].data[month-1] += 1
                            found_match = true
                        }
                    }
                    if (found_match === false){
                        data.push({name:user_name, y:1})
                        let new_data_by_month = {name:user_name, data:[0,0,0,0,0,0,0,0,0,0,0,0]}
                        new_data_by_month.data[month-1] = 1
                        data_by_month.push(new_data_by_month)
                    }
                    found_match = false
                    for (let j=0; j< company_data.length; j++){
                        if (company_data[j].name === company_name){
                            company_data[j].y += 1
                            company_data_by_month[j].data[month-1] += 1
                            found_match = true
                        }
                    }
                    if (found_match === false){
                        company_data.push({name:company_name, y:1})
                        let new_company_data_by_month = {name:company_name, data:[0,0,0,0,0,0,0,0,0,0,0,0]}
                        new_company_data_by_month.data[month-1] = 1
                        company_data_by_month.push(new_company_data_by_month)
                    }
                }
            }
        }
        for (let i=0; i < distorteds.length; i++){
            let month = parseInt(distorteds[i].createdAt.split("-")[1])
            console.log(month)
            if (distorteds[i].account) {
                let user_name = distorteds[i].account.first_name + ' ' + distorteds[i].account.last_name
                let company_name = distorteds[i].account.company_name
                if (distorteds[i].account.company_name === 'Synamedia'){
                    let found_match = false
                    for (let j=0; j< syna_data.length; j++){
                        if (syna_data[j].name === user_name){
                            syna_data[j].y += 1
                            syna_data_by_month[j].data[month-1] += 1
                            found_match = true
                        }
                    }
                    if (found_match === false){
                        syna_data.push({name:user_name, y:1})
                        let new_data_by_month = {name:user_name, data:[0,0,0,0,0,0,0,0,0,0,0,0]}
                        new_data_by_month.data[month-1] = 1
                        syna_data_by_month.push(new_data_by_month)
                    }
                } else {
                    let found_match = false
                    for (let j=0; j< data.length; j++){
                        if (data[j].name === user_name){
                            data[j].y += 1
                            data_by_month[j].data[month-1] += 1
                            found_match = true
                        }
                    }
                    if (found_match === false){
                        data.push({name:user_name, y:1})
                        let new_data_by_month = {name:user_name, data:[0,0,0,0,0,0,0,0,0,0,0,0]}
                        new_data_by_month.data[month-1] = 1
                        data_by_month.push(new_data_by_month)
                    }
                    found_match = false
                    for (let j=0; j< company_data.length; j++){
                        if (company_data[j].name === company_name){
                            company_data[j].y += 1
                            company_data_by_month[j].data[month-1] += 1
                            found_match = true
                        }
                    }
                    if (found_match === false){
                        company_data.push({name:company_name, y:1})
                        let new_company_data_by_month = {name:company_name, data:[0,0,0,0,0,0,0,0,0,0,0,0]}
                        new_company_data_by_month.data[month-1] = 1
                        company_data_by_month.push(new_company_data_by_month)
                    }

                }
            }
        }
        let sum = 0
        for (let j=0; j<data.length; j ++){
            sum += data[j].y
        }
        if (sum){
            for (let j=0; j<data.length; j ++){
                data[j].y = (100 * data[j].y) / sum
            }
        }
        sum = 0
        for (let j=0; j<syna_data.length; j ++){
            sum += syna_data[j].y
        }
        if (sum){
            for (let j=0; j<syna_data.length; j ++){
                syna_data[j].y = (100 * syna_data[j].y) / sum
            }
        }
        sum = 0
        for (let j=0; j<company_data.length; j ++){
            sum += company_data[j].y
        }
        if (sum){
            for (let j=0; j<company_data.length; j ++){
                company_data[j].y = (100 * company_data[j].y) / sum
            }
        }

        console.log(data_by_month)
        setPieChartData(data)
        setBarChartData(data_by_month)
        setPieChartSynaData(syna_data)
        setBarChartSynaData(syna_data_by_month)
        setPieChartCompanyData(company_data)
        setBarChartCompanyData(company_data_by_month)
    }, [distorteds, references])


    React.useEffect(() => {
        setPieChartOptions(prevChartOptions => {
            let updatedChartOptions = {...prevChartOptions}
            updatedChartOptions.series[0].data = pieChartData
            return updatedChartOptions
        })
    }, [pieChartData])

    React.useEffect(() => {
        setBarChartOptions(prevChartOptions => {
            let updatedChartOptions = {...prevChartOptions}
            updatedChartOptions.series = barChartData
            return updatedChartOptions
        })
    }, [barChartData])

    React.useEffect(() => {
        setPieChartSynaOptions(prevChartOptions => {
            let updatedChartOptions = {...prevChartOptions}
            updatedChartOptions.series[0].data = pieChartSynaData
            return updatedChartOptions
        })
    }, [pieChartSynaData])

    React.useEffect(() => {
        setBarChartSynaOptions(prevChartOptions => {
            let updatedChartOptions = {...prevChartOptions}
            updatedChartOptions.series = barChartSynaData
            return updatedChartOptions
        })
    }, [barChartSynaData])

    React.useEffect(() => {
        setPieChartCompanyOptions(prevChartOptions => {
            let updatedChartOptions = {...prevChartOptions}
            updatedChartOptions.series[0].data = pieChartCompanyData
            return updatedChartOptions
        })
    }, [pieChartCompanyData])

    React.useEffect(() => {
        setBarChartCompanyOptions(prevChartOptions => {
            let updatedChartOptions = {...prevChartOptions}
            updatedChartOptions.series = barChartCompanyData
            return updatedChartOptions
        })
    }, [barChartCompanyData])


    React.useEffect(() => {
        let nextToken;
        async function listAccounts(limit){
            let apiName = 'AdminQueries';
            let path = '/listUsers';
            let myInit = {
                queryStringParameters: {
                    "limit": limit,
                    "token": nextToken
                },
                headers: {
                    'Content-Type' : 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                }
            }
            const { NextToken, ...rest } =  await API.get(apiName, path, myInit);
            nextToken = NextToken;
            // console.log(rest)
            return rest;
        }
        async function listDbAccounts() {
            const result = await API.graphql(graphqlOperation(gqlListdBAccounts))
            // console.log(result.data.listAccounts.items)
            setDbAccounts(result.data.listAccounts.items)
        }
        async function getMyAccount() {
            const user = await Auth.currentAuthenticatedUser()
            const result = await API.graphql(graphqlOperation(getAccountWithSubscriptionsTeamsS3, {
                id: user.username
            }))
            setAccount(result.data.getAccount)
        }
        getMyAccount().then()
        listDbAccounts().then()
        listAccounts(60).then(data => setAccounts(data))
    }, [])

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("media-page");
        return function cleanup() {
            document.body.classList.remove("media-page");
        };
    });

    return (
        <>
            <ColorNavbar account={account} />
            <MediaPageHeader />
            <div className="section">
                <Container className="tim-container">
                    <div className="title">
                        <h3 id="ReferenceLibrary">Partners and Customers</h3>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={pieChartOptions}
                            ref={chartRef}
                        />
                        <hr/>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={barChartOptions}
                            ref={barChartRef}
                        />
                        <hr/>
                        <br/>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={pieChartCompanyOptions}
                            ref={chartCompanyRef}
                        />
                        <hr/>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={barChartCompanyOptions}
                            ref={barChartCompanyRef}
                        />
                        <br/>
                        <h3 id="ReferenceLibrary">Synamedia</h3>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={pieChartSynaOptions}
                            ref={chartSynaRef}
                        />
                        <hr/>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={barChartSynaOptions}
                            ref={barChartSynaRef}
                        />
                    </div>
                </Container>
            </div>
            <FooterBlack />
        </>
    );
}

export default DashboardAccounts;
