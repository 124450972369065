/*!

=========================================================
* Paper Kit PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-pro-react
* Copyright 2020 Synamedia (http://www.creative-tim.com)

* Coded by Synamedia

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import ReactDOM from "react-dom";
import './index.css';
import App from './App';

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
import '@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css';
import Amplify from 'aws-amplify';
import config from './aws-exports';

config.Auth = {
  ...config.Auth,
  storage: window.sessionStorage
};
Amplify.configure({ ...config, ssr: true });
ReactDOM.render(
    <App />,
    document.getElementById("root")
);
