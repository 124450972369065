import React, { useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {
    Button, Col,
    Row, ButtonGroup
} from "reactstrap";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";

require("highcharts/modules/annotations")(Highcharts);

const argPeak = (myArray) => {
    let min = myArray[0]
    let argMin = 0

    for (let i=1; i < myArray.length; i++){
        let value = myArray[i]
        if (value < min ){
            argMin = i
            min = value
        }
    }
    return argMin
}

const argMaxPeak = (myArray) => {
    let max = myArray[0]
    let argMax = 0

    for (let i=1; i < myArray.length; i++){
        let value = myArray[i]
        if (value > max ){
            argMax = i
            max = value
        }
    }
    return argMax
}


function MetricChart(props) {
    const [lastSelectedFrame, setLastSelectedFrame] = useState(null)
    const [xExtremes, setXExtremes] = useState({min: null, max: null})
    const [xDelta, setXDelta] = useState(null)
    const [xCenter, setXCenter] = useState(null)

    const chartRef = React.useRef()

    const [chartOptions, setChartOptions] = useState({
        chart: {
            animation: false,
            zoomType: 'x',
            panning: true,
            panKey: 'shift',
            marginTop: 100
        },
        series: [
            { data: [], color: '#3380FF' }
        ],
        xAxis: {
            crosshair: true,
            max: null,
            min: null,
            gridLineWidth: 1,
            title: {
                text: 'Frame number after aligning reference and distorted video'
            },
        },
        yAxis: [
            { // Primary axis A, B
                max: 100,
                labels: {
                    style: {
                        color: '#000'
                    }
                },
                title: {
                    text: props.metric,
                    style: {
                        color: '#000'
                    }
                },
                opposite: true
            },
            { // Secondary axis A min B
                gridLineWidth: 0,
                labels: {
                    style: {
                        color: '#51BCDA'
                    }
                },
                title: {
                    text: 'Delta ' + props.metric,
                    style: {
                        color: '#51BCDA'
                    }
                },
            },

        ],
        legend: {
            layout: 'vertical',
            align: 'right',
            x: -50,
            verticalAlign: 'top',
            y: 0,
            floating: true,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || // theme
                'rgba(255,255,255,0.25)'
        },
        title: {
            text: ''
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                animation: false,
                point: {
                    events: {
                        select(e){
                            setLastSelectedFrame(e.target.x)
                        }
                    }
                }
            }
        },
        tooltip: {
            shared: true
        }
    });


    React.useEffect(() => {
        setChartOptions(prevChartOptions => {
            let updatedChartOptions = {...prevChartOptions}
            updatedChartOptions.xAxis.min = xExtremes.min
            updatedChartOptions.xAxis.max = xExtremes.max
            return updatedChartOptions
        })
    }, [xExtremes])

    React.useEffect(() => {
        setChartOptions(prevChartOptions => {
            let updatedChartOptions = {...prevChartOptions}
            let data1, data2, data3, data4, dataDelta
            if (props.metric === 'VMAF'){
                if (props.distorted1) {
                    data1 = [...props.distorted1.vmaf]
                }
                if (props.distorted2) {
                    data2 = [...props.distorted2.vmaf]
                }
                if (props.distorted3) {
                    data3 = [...props.distorted3.vmaf]
                }
                if (props.distorted4) {
                    data4 = [...props.distorted4.vmaf]
                }
                if (props.dataAminB){
                    dataDelta = [...props.dataAminB.vmaf]
                }
            }
            if (props.metric === 'PSNR'){
                if (props.distorted1){
                    data1 = [...props.distorted1.psnr]
                }
                if (props.distorted2){
                    data2 = [...props.distorted2.psnr]
                }
                if (props.distorted3) {
                    data3 = [...props.distorted3.psnr]
                }
                if (props.distorted4) {
                    data4 = [...props.distorted4.psnr]
                }
                if (props.dataAminB) {
                    dataDelta = [...props.dataAminB.psnr]
                }
            }
            updatedChartOptions.series = []
            if (props.distorted1){
                updatedChartOptions.series = updatedChartOptions.series.concat({
                    name:props.distorted1.filename,
                    data:data1,
                    color: '#000',
                    yAxis: 0})
            }
            if (props.distorted2){
                updatedChartOptions.series = updatedChartOptions.series.concat({
                    name:props.distorted2.filename,
                    data:data2,
                    color: '#FFB400',
                    yAxis: 0})
            }
            if (props.distorted3){
                updatedChartOptions.series = updatedChartOptions.series.concat({
                    name:props.distorted3.filename,
                    data:data3,
                    color: '#3380FF',
                    yAxis: 0})
            }
            if (props.distorted4){
                updatedChartOptions.series = updatedChartOptions.series.concat({
                    name:props.distorted4.filename,
                    data:data4,
                    color: '#FF3333',
                    yAxis: 0})
            }
            updatedChartOptions.series = updatedChartOptions.series.concat({
                name:"A-B",
                data:dataDelta,
                color: '#51BCDA',
                yAxis: 1
            })
            updatedChartOptions.yAxis[0].title.text = props.metric
            updatedChartOptions.yAxis[1].title.text = 'Delta ' + props.metric
            return updatedChartOptions
        })
    }, [props.distorted1, props.distorted2, props.distorted3, props.distorted4, props.dataAminB, props.metric])

    React.useEffect(() => {
        if ((xCenter !== null) && (xDelta !== null)) {
            setXExtremes({
                min: xCenter - Math.round(xDelta / 2),
                max: xCenter + Math.round(xDelta / 2)
            })
        }
    }, [xCenter, xDelta])

    React.useEffect(() => {
        if (lastSelectedFrame !== null){
            setChartOptions(prevChartOptions => {
                let updatedChartOptions = {...prevChartOptions}
                updatedChartOptions.annotations = [ {
                    labelOptions: {
                        backgroundColor: 'rgba(0,0,0,0.95)',
                        overflow: "none"
                    },
                    labels: [{
                        point: {
                            xAxis: 0,
                            x: lastSelectedFrame,
                            y: 0
                        },
                        text: String(lastSelectedFrame)
                    }]
                }]
                return updatedChartOptions
            })
            if (props.framerate){
                props.onSeekTimeChange(lastSelectedFrame / props.framerate)
            }
        }
    }, [lastSelectedFrame, props])

    async function onButtonGroupClick(e) {
        let metric = e.target.offsetParent.attributes.getNamedItem('name').value
        let selectedLabel = e.target.offsetParent.attributes.getNamedItem('name').value
        let action = e.target.attributes.getNamedItem('data-key').value
        let selectedMetricData = props.distorted1.vmaf

        if (selectedLabel === "AminB"){
            if (props.metric === 'VMAF'){
                selectedMetricData = [...props.dataAminB.vmaf]
            }
            if (props.metric === 'PSNR'){
                selectedMetricData = [...props.dataAminB.psnr]
            }
        }
        if (selectedLabel === "dis1"){
            if (props.metric === 'VMAF'){
                selectedMetricData = [...props.distorted1.vmaf]
            }
            if (props.metric === 'PSNR'){
                selectedMetricData = [...props.distorted1.psnr]
            }
        }
        if (metric === "B"){
            selectedMetricData = props.dataB
        }
        if (action === "peakSearch"){
            let windowMin = (xExtremes.min > 0) ? xExtremes.min : 0
            let windowMax = xExtremes.max

            if (!windowMin) {
                windowMin = 0
                windowMax = selectedMetricData.length
            }
            let localMin = argPeak(selectedMetricData.slice(windowMin,windowMax)) + windowMin
            setLastSelectedFrame(localMin)
        }
        if (action === "maxPeakSearch"){
            let windowMin = (xExtremes.min > 0) ? xExtremes.min : 0
            let windowMax = xExtremes.max

            if (!windowMin) {
                windowMin = 0
                windowMax = selectedMetricData.length
            }
            let localMin = argMaxPeak(selectedMetricData.slice(windowMin,windowMax)) + windowMin
            setLastSelectedFrame(localMin)
        }
        if (action === "peakLeft"){
            if (lastSelectedFrame !== null){
                let windowMin = (xExtremes.min > 0) ? xExtremes.min : 0
                let windowMax = lastSelectedFrame

                if (!windowMin) {
                    windowMin = 0
                }
                let localMin = argPeak(selectedMetricData.slice(windowMin,windowMax)) + windowMin
                setLastSelectedFrame(localMin)
            }
        }
        if (action === "maxPeakLeft"){
            if (lastSelectedFrame !== null){
                let windowMin = (xExtremes.min > 0) ? xExtremes.min : 0
                let windowMax = lastSelectedFrame

                if (!windowMin) {
                    windowMin = 0
                }
                let localMax = argMaxPeak(selectedMetricData.slice(windowMin,windowMax)) + windowMin
                setLastSelectedFrame(localMax)
            }
        }
        if (action === "peakRight"){
            if (lastSelectedFrame !== null){
                let windowMin
                // exclude current selected point, but limit to length of data
                windowMin = (lastSelectedFrame + 1 < selectedMetricData.length) ? lastSelectedFrame + 1 : selectedMetricData.length - 1
                // Don't go beyond current span
                if (xExtremes.max !== null){
                    windowMin = (windowMin < xExtremes.max) ? windowMin : xExtremes.max
                }
                let windowMax
                // Don't go beyond current span, if no span defined limit to length of data
                if (xExtremes.max !== null){
                    windowMax = (xExtremes.max < selectedMetricData.length) ? xExtremes.max : selectedMetricData.length - 1
                } else {
                    windowMax = selectedMetricData.length - 1
                }
                let localMin = argPeak(selectedMetricData.slice(windowMin,windowMax)) + windowMin
                setLastSelectedFrame(localMin)
            }
        }
        if (action === "maxPeakRight"){
            if (lastSelectedFrame !== null){
                let windowMin
                // exclude current selected point, but limit to length of data
                windowMin = (lastSelectedFrame + 1 < selectedMetricData.length) ? lastSelectedFrame + 1 : selectedMetricData.length - 1
                // Don't go beyond current span
                if (xExtremes.max !== null){
                    windowMin = (windowMin < xExtremes.max) ? windowMin : xExtremes.max
                }
                let windowMax
                // Don't go beyond current span, if no span defined limit to length of data
                if (xExtremes.max !== null){
                    windowMax = (xExtremes.max < selectedMetricData.length) ? xExtremes.max : selectedMetricData.length - 1
                } else {
                    windowMax = selectedMetricData.length - 1
                }
                let localMax = argMaxPeak(selectedMetricData.slice(windowMin,windowMax)) + windowMin
                setLastSelectedFrame(localMax)
            }
        }
        if (action === "toLeft"){
            setLastSelectedFrame(prevLastSelectedFrame => prevLastSelectedFrame - 1)
        }
        if (action === "toRight"){
            setLastSelectedFrame(prevLastSelectedFrame => prevLastSelectedFrame + 1)
        }
        if (action === "toCenter"){
            setXCenter(() => {
                if (lastSelectedFrame !== null) {
                    return lastSelectedFrame
                } else {
                    return null
                }
            })
            setXDelta(prevXDelta => {
                if (prevXDelta !== null) {
                    return prevXDelta
                } else {
                    return selectedMetricData.length
                }
            })
        }
        if (action === "zoomx2"){
            setXDelta(prevXDelta => {
                if (prevXDelta !== null) {
                    return Math.round(prevXDelta / 2)
                } else {
                    return Math.round(selectedMetricData.length  / 2)
                }
            })
            setXCenter(prevXCenter => {
                if (prevXCenter !== null) {
                    return prevXCenter
                } else {
                    return Math.round(selectedMetricData.length  / 2)
                }
            })
        }
        if (action === "zoomDiv2"){
            setXDelta(prevXDelta => {
                if (prevXDelta !== null) {
                    return prevXDelta * 2
                } else {
                    return selectedMetricData.length
                }
            })
            setXCenter(prevXCenter => {
                if (prevXCenter !== null) {
                    return prevXCenter
                } else {
                    return Math.round(selectedMetricData.length /2)
                }
            })
        }
        if (action === "fullSpan"){
            setXExtremes({min: null, max: null})
            setXDelta(null)
            setXCenter(null)
        }
    }

    return(
        <Divider>
            <Row>
                <Col sm={2} md={1}>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <ButtonGroup
                        className="float-right"
                        vertical
                        name={"AminB"}
                        onClick={onButtonGroupClick}
                    >
                        <Button
                            className="btn-round"
                            color={"neutral"}
                            outline
                        >
                        </Button>
                        <Button
                            className="btn-round"
                            color="info"
                            outline
                            data-key={"maxPeakSearch"}
                        >
                            Max
                        </Button>
                        <Button
                            className="btn-round"
                            color="info"
                            outline
                            data-key={"maxPeakLeft"}
                        >
                            Max-L
                        </Button>
                        <Button
                            className="btn-round"
                            color="info"
                            outline
                            data-key={"maxPeakRight"}
                        >
                            Max-R
                        </Button>
                        <Button
                            className="btn-round"
                            color="info"
                            outline
                            data-key={"peakSearch"}
                        >
                            Min
                        </Button>
                        <Button
                            className="btn-round"
                            color="info"
                            outline
                            data-key={"peakLeft"}
                        >
                            Min-L
                        </Button>
                        <Button
                            className="btn-round"
                            color="info"
                            outline
                            data-key={"peakRight"}
                        >
                            Min-R
                        </Button>
                        <Button
                            className="btn-round"
                            color={"neutral"}
                            outline
                            data-key={"peakSearch"}
                        >
                        </Button>

                    </ButtonGroup>
                </Col>
                <Col sm={6} md={9} >
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                        ref={chartRef}
                    />
                </Col>
                <Col sm={2} md={1}>
                    <br/>
                    <br/>
                    <br/>
                    <ButtonGroup
                        className="float-right"
                        vertical
                        name={"dis1"}
                        onClick={onButtonGroupClick}
                    >
                        <Button
                            className="btn-round"
                            color={"neutral"}
                            outline
                        >
                        </Button>
                        <Button
                            className="btn-round"
                            color={"default"}
                            outline
                            data-key={"peakSearch"}
                        >
                            Min
                        </Button>
                        <Button
                            className="btn-round"
                            color={"default"}
                            outline
                            data-key={"peakLeft"}
                        >
                            Min-L
                        </Button>
                        <Button
                            className="btn-round"
                            color={"default"}
                            outline
                            data-key={"peakRight"}
                        >
                            Min-R
                        </Button>
                        <Button
                            className="btn-round"
                            color={"neutral"}
                            outline
                        >
                        </Button>
                    </ButtonGroup>
                    <ButtonGroup
                        className="float-right"
                        vertical
                        name={"dis2"}
                        onClick={onButtonGroupClick}
                    >
                        <Button
                            className="btn-round"
                            color={"neutral"}
                            outline
                        >
                        </Button>
                        <Button
                            className="btn-round"
                            color="success"
                            outline
                            data-key={"peakSearch"}
                        >
                            Min
                        </Button>
                        <Button
                            className="btn-round"
                            color="success"
                            outline
                            data-key={"peakLeft"}
                        >
                            Min-L
                        </Button>
                        <Button
                            className="btn-round"
                            color="success"
                            outline
                            data-key={"peakRight"}
                        >
                            Min-R
                        </Button>
                        <Button
                            className="btn-round"
                            color={"neutral"}
                            outline
                            data-key={"peakSearch"}
                        >
                        </Button>

                    </ButtonGroup>
                </Col>
                <Col sm={2} md={1}>
                    <br/>
                    <br/>
                    <br/>
                    {props.distorted3 &&
                    <ButtonGroup
                        className="float-right"
                        vertical
                        name={"dis3"}
                        onClick={onButtonGroupClick}
                    >
                        <Button
                            className="btn-round"
                            color={"neutral"}
                            outline
                            data-key={"peakSearch"}
                        >
                        </Button>
                        <Button
                            className="btn-round"
                            color={"primary"}
                            outline
                            data-key={"peakSearch"}
                        >
                            Min
                        </Button>
                        <Button
                            className="btn-round"
                            color={"primary"}
                            outline
                            data-key={"peakLeft"}
                        >
                            Min-L
                        </Button>
                        <Button
                            className="btn-round"
                            color={"primary"}
                            outline
                            data-key={"peakRight"}
                        >
                            Min-R
                        </Button>
                        <Button
                            className="btn-round"
                            color={"neutral"}
                            outline
                        >
                        </Button>
                    </ButtonGroup>}
                    {props.distorted4 &&
                    <ButtonGroup
                        className="float-right"
                        vertical
                        name={"dis4"}
                        onClick={onButtonGroupClick}
                    >
                        <Button
                            className="btn-round"
                            color={"neutral"}
                            outline
                        >
                        </Button>
                        <Button
                            className="btn-round"
                            color="danger"
                            outline
                            data-key={"peakSearch"}
                        >
                            Min
                        </Button>
                        <Button
                            className="btn-round"
                            color="danger"
                            outline
                            data-key={"peakLeft"}
                        >
                            Min-L
                        </Button>
                        <Button
                            className="btn-round"
                            color="danger"
                            outline
                            data-key={"peakRight"}
                        >
                            Min-R
                        </Button>
                        <Button
                            className="btn-round"
                            color={"neutral"}
                            outline
                            data-key={"peakSearch"}
                        >
                        </Button>

                    </ButtonGroup>}
                </Col>
            </Row>
            <Row>
                <Col sm={{size:5, offset:3}}>
                    <ButtonGroup
                        className="d-flex align-content-center"
                        name={"Span"}
                        onClick={onButtonGroupClick}
                    >
                        <Button
                            className="btn-round btn-default"
                            color="c"
                            outline
                            data-key={""}
                        >
                        </Button>
                        <Button
                            className="btn-round btn-default"
                            color="default"
                            outline
                            name={"Span"}
                            data-key={"toLeft"}
                        >
                            <i className="nc-icon nc-minimal-left" data-key={"toLeft"} />
                        </Button>
                        <Button
                            className="btn-round btn-default"
                            color="default"
                            outline
                            name={"Span"}
                            data-key={"toRight"}
                        >
                            <i className="nc-icon nc-minimal-right" data-key={"toRight"} />
                        </Button>
                        <Button
                            className="btn-round btn-default"
                            color="default"
                            outline
                            data-key={""}
                        >
                        </Button>
                        <Button
                            className="btn-round btn-default"
                            color="default"
                            outline
                            data-key={"toCenter"}
                        >
                            To Center
                        </Button>
                        <Button
                            className="btn-round btn-default"
                            color="default"
                            outline
                            data-key={"zoomx2"}
                        >
                            Zoom x2
                        </Button>
                        <Button
                            className="btn-round btn-default"
                            color="default"
                            outline
                            data-key={"zoomDiv2"}
                        >
                            Zoom /2
                        </Button>
                        <Button
                            className="btn-round btn-default"
                            color="default"
                            outline
                            data-key={"fullSpan"}
                        >
                            Full Span
                        </Button>
                        <Button
                            className="btn-round btn-default"
                            color="default"
                            outline
                            data-key={""}
                        >
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Divider>
    )
}

export default MetricChart;