import React from "react";

// reactstrap components
import {
  Table,
} from "reactstrap";
import CodecCell from "./CodecCell";
import DurationTimeCell from "./DurationTimeCell";
import ResolutionCell from "./ResolutionCell";
import FramerateCell from "./FramerateCell";
import PixelFormatCell from "./PixelFormatCell";
import DurationFramesCell from "./DurationFramesCell";
import CreatedAtCell from "./CreatedAtCell";
import VmafCell from "./VmafCell";
import PsnrCell from "./PsnrCell";


function DistortedSpecPage(props) {
  return (
      <>
        <h4 className="title">
          <small>Specifications</small>
        </h4>
        <Table responsive>
          <thead>
          <tr>
            <th />
            <th>Value</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Filename</td>
            <td>{props.disSpecData.filename}</td>
          </tr>
          <tr>
            <td>Resolution</td>
            <ResolutionCell distorted={props.disSpecData}/>
          </tr>
          <tr>
            <td>Framerate</td>
            <FramerateCell distorted={props.disSpecData}/>
          </tr>
          <tr>
            <td>Pixel format</td>
            <PixelFormatCell distorted={props.disSpecData}/>
          </tr>
          <tr>
            <td>Codec</td>
            <CodecCell distorted={props.disSpecData}/>
          </tr>
          <tr>
            <td>Duration</td>
            <DurationTimeCell distorted={props.disSpecData}/>
          </tr>
          <tr>
            <td>Duration</td>
            <DurationFramesCell distorted={props.disSpecData}/>
          </tr>
          <tr>
            <td>Upload Date</td>
            <CreatedAtCell  distorted={props.disSpecData}/>
          </tr>
          <tr>
            <td>VMAF mean</td>
            <VmafCell distorted={props.disSpecData}/>
          </tr>
          <tr>
            <td>PSNR mean</td>
            <PsnrCell distorted={props.disSpecData}/>
          </tr>
          </tbody>
        </Table>
      </>
  );
}

export default DistortedSpecPage;
