import React, {useState} from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import ReferencePageHeader from "components/Headers/ReferencePageHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import {API, Auth, graphqlOperation, Storage} from "aws-amplify";
import {getDistorted, getReference} from "../../graphql/queries";
import {useParams} from "react-router-dom";
import DistortedProcessing from "../../components/vqaas/DistortedProcessing";
import VqaasHCCorrelationChart from "../sections-sections/VqaasHCCorrelationChart";
import VqaasHCMetricChart from "../sections-sections/VqaasHCMetricChart";
import {ReactCompareSlider} from "react-compare-slider";
import ReactPlayer from "react-player";
import FeatureExtraction from "../../components/vqaas/FeatureExtraction";
// import {onUpdateDistorted} from "../../graphql/subscriptions";
import DistortedSpecPage from "../../components/vqaas/DistortedSpecPage";
import StreamPlayer from "../../components/vqaas/StreamPlayer";
import { useHistory } from "react-router-dom";
const getAccountWithSubscriptionsTeamsS3 = /* GraphQL */ `
    query GetAccount($id: ID!) {
      getAccount(id: $id) {
        id
        email
        owner
        s3_bucket
        s3_bucket_region
        subscriptions {
          items {
            subscription {
              name
              id
            }
          }
        }
        teams {
          items {
            team {
              name
              id
            }
          }
        }
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        cross_account_s3 {
          items {
            id
            Key
            ETag
            Size
            LastModified
            LastSeen
            accountID
            referenceID
            createdAt
            updatedAt
          }
          nextToken
        }
        credits
        createdAt
        updatedAt
      }
    }
  `;
const onUpdateDistorted = /* GraphQL */ `
  subscription OnUpdateDistorted($id: ID!, $owner: String!) {
    onUpdateDistorted(id: $id, owner: $owner) {
      accountID
      align_offset
      createdAt
      cross_account_s3_id
      description
      filename
      persons
      subscriptions
      teams
      id
      metric_psnr
      metric_psnr_bin
      metric_psnr_cdf
      metric_vmaf
      metric_vmaf_bin
      metric_vmaf_cdf
      owner
      progress_calc_metric
      progress_create_hls
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      reference_id
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      updatedAt
      user_identity_id
      account {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
        references {
          nextToken
        }
      }
    }
  }
`;

function DistortedPage() {
  const [account, setAccount] = useState();
  const {reference_id, distorted_id} = useParams()
  const [analysis, setAnalysis] = useState();
  const [reference, setReference] = useState();
  const playerReference = React.useRef()
  const playerDistorted = React.useRef()
  const [signedUrlReference, setSignedUrlReference] = React.useState(null)
  const [signedUrlDistorted, setSignedUrlDistorted] = React.useState(null)

  React.useEffect(() => {
    async function getMyAccount() {
      const user = await Auth.currentAuthenticatedUser()
      const result = await API.graphql(graphqlOperation(getAccountWithSubscriptionsTeamsS3, {
        id: user.username
      }))
      setAccount(result.data.getAccount)
    }
    getMyAccount().then()
  }, [])

  React.useEffect(() => {
    async function getMyDistorted() {
      const user = await Auth.currentAuthenticatedUser()
      const result = await API.graphql(graphqlOperation(getDistorted, {
        id: distorted_id,
        owner: user.username
      }))
      console.log("Distorted loaded", result.data.getDistorted.account.user_identity_id)
      setAnalysis(result.data.getDistorted)
    }
    getMyDistorted().then()
  }, [distorted_id])

  React.useEffect(() => {
    async function getMyReference() {
      const user = await Auth.currentAuthenticatedUser()
      const result = await API.graphql(graphqlOperation(getReference, {
        id: reference_id,
        owner: user.username
      }))
      console.log("Reference loaded", result.data.getReference.account.user_identity_id)
      setReference(result.data.getReference)
    }
    getMyReference().then()
  }, [reference_id])

  React.useEffect(() => {
    let subscription
    let mounted = true
    async function setupSubscription() {
      // console.log("Subscribing to onUpdate")
      const user = await Auth.currentAuthenticatedUser()
      subscription = API.graphql(
          graphqlOperation(
              onUpdateDistorted,
              {id: distorted_id, owner: user.username}))
          .subscribe({
            next: (data) => {
              if (mounted) {
                // console.log(data)
                if (!data.value.data.onUpdateDistorted) {
                  console.log("Subscription to onUpdateDistorted returned null")
                  return
                }
                if (data.value.data.onUpdateDistorted.id === distorted_id) {
                  setAnalysis(data.value.data.onUpdateDistorted)
                } else {
                  console.log("onUpdateDistorted did not match distorted_id")
                }
              }
            }
          })
    }
    setupSubscription().then()
    return () => {
      mounted = false
      subscription.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("media-page");
    return function cleanup() {
      document.body.classList.remove("media-page");
    };
  });

  React.useEffect(()=> {
    async function getAWSUrl() {
      if (analysis && reference){
        if (analysis.progress_create_hls === 100) {
          const fileAccessURLReference = await Storage.get(
              reference_id + "/360_reference_out_signed.m3u8",
              {
                identityId: reference.user_identity_id,
                level: 'protected',
                expires: 600 });
          console.log(analysis)
          const fileAccessURLDistorted = await Storage.get(
              distorted_id + "/360_distorted_out_signed.m3u8",
              {
                identityId: analysis.user_identity_id,
                level: 'protected',
                expires: 600 });
          setSignedUrlReference(fileAccessURLReference);
          setSignedUrlDistorted(fileAccessURLDistorted);
        }
      }
    }
    getAWSUrl()
  }, [reference_id, distorted_id, analysis, reference])

  function handleSeekTimeChange(e) {
    console.log(analysis.align_offset)
    if (analysis.align_offset === 0){
      playerReference.current.seekTo(parseFloat(e))
      playerDistorted.current.seekTo(parseFloat(e))
    }
    if (analysis.align_offset > 0){
      let x_ref = parseFloat(e)
      if (x_ref < 0){
        x_ref = 0
      }
      let x_dis = parseFloat(e) - (analysis.align_offset / parseFloat(analysis.spec_frame_rate))
      if (x_dis < 0){
        x_dis=0
      }
      playerReference.current.seekTo(x_ref)
      playerDistorted.current.seekTo(x_dis)
      console.log(x_ref, x_dis)
    }
    if (analysis.align_offset < 0){
      let x_ref = parseFloat(e)
      if (x_ref < 0){
        x_ref = 0
      }
      let x_dis = parseFloat(e) - (analysis.align_offset / parseFloat(analysis.spec_frame_rate))
      if (x_dis < 0){
        x_dis=0
      }
      playerReference.current.seekTo(x_ref)
      playerDistorted.current.seekTo(x_dis)
      console.log(x_ref, x_dis)
    }
  }

  let history = useHistory();

  return (
      <>
        <ColorNavbar account={account} />
        <ReferencePageHeader />
        <div className="section">
          <Container className="tim-container">
            <div className="title">
              <h3>Distorted</h3>
            </div>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                {analysis ? <DistortedSpecPage disSpecData={analysis}/> : <br/>}
                {
                  analysis ?
                      <DistortedProcessing disProc={analysis}/>
                      :
                      <br/>
                }
                {
                  analysis ?
                      <FeatureExtraction
                          reference_id = {reference_id}
                          distorted_id = {distorted_id}
                          ref_extract_feature_progress = {analysis.progress_extract_feat}
                          dis_extract_feature_progress = {analysis.progress_extract_feat}
                      />
                      :
                      <br/>
                }
                {
                  analysis ?
                      <VqaasHCCorrelationChart
                          analysis_id = {distorted_id}
                          ref_extract_feature_progress = {analysis.progress_extract_feat}
                          dis_extract_feature_progress = {analysis.progress_extract_feat}
                      />
                      :
                      <br/>
                }
                <br/>
                {
                  (analysis && analysis.align_offset != null) ?
                      <p>{"Align offset: " + analysis.align_offset + " frames"}</p>
                      :
                      <br/>
                }
                {analysis ?
                    <StreamPlayer
                        streamPlayerData={analysis}
                        refOrDis={"distorted"}/> : <br/>}

              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <h4 className="title">
                  <small>Video Quality Metrics</small>
                </h4>
                {
                  analysis ?
                      <VqaasHCMetricChart
                          analysis_id = {distorted_id}
                          progress_calc_metric = {analysis.progress_calc_metric}
                          framerate = {analysis.spec_frame_rate}
                          onSeekTimeChange = {handleSeekTimeChange}
                      />
                      :
                      <br/>
                }
                <h4 className="title">
                  <small>Subjective Analysis</small>
                </h4>
                  <ReactCompareSlider
                      itemOne={
                        <ReactPlayer
                            width='100%'
                            height='100%'
                            url={signedUrlReference}
                            className="react-player"
                            ref={playerReference}
                            pip={false}
                            playing={false}
                            controls={false}
                            light={false}
                            loop={false}
                            playbackRate={1.0}
                            onStart={() => {}}
                            onPlay={() => {}}
                            onEnablePIP={() => {}}
                            onDisablePIP={() => {}}
                            onPause={() => {}}
                            onBuffer={() => {}}
                            onSeek={() => {}}
                            onEnded={() => {}}
                            onError={e => console.log('onError', e)}
                            onDuration={() => {}}
                        />
                      }
                      itemTwo={
                        <ReactPlayer
                            width='100%'
                            height='100%'
                            url={signedUrlDistorted}
                            className="react-player"
                            ref={playerDistorted}
                            pip={false}
                            playing={false}
                            controls={false}
                            light={false}
                            loop={false}
                            playbackRate={1.0}
                            onStart={() => {}}
                            onPlay={() => {}}
                            onEnablePIP={() => {}}
                            onDisablePIP={() => {}}
                            onPause={() => {}}
                            onBuffer={() => {}}
                            onSeek={() => {}}
                            onEnded={() => {}}
                            onError={e => console.log('onError', e)}
                            onDuration={() => {}}
                        />
                      }
                      style={{ width: '100%', height: '65vh' }}
                  />
              </Col>
            </Row>
            <button
                onClick={() => history.goBack()}
                className="btn-link pull-left"
                color="default"
            >Back</button>
          </Container>
        </div>
        <FooterBlack />
      </>
  );
}

export default DistortedPage;
