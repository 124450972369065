import React from "react"
import {Button, UncontrolledTooltip} from "reactstrap";

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
function humanFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}


function S3TableEntry(props) {
    return (
        <tr>
            <td>
                {
                    props.s3_object.Key ?
                        props.s3_object.Key.split('/')[props.s3_object.Key.split('/').length - 1]  :
                        <div className="uil-reload-css reload-small mr-1 "><div /></div>
                }
            </td>
            <td>
                {
                    props.s3_object.LastModified ?
                        props.s3_object.LastModified :
                        <div className="uil-reload-css reload-small mr-1 "><div /></div>
                }
            </td>
            <td>
                {
                    parseInt(props.s3_object.Size) ?
                        humanFileSize(parseInt(props.s3_object.Size), true, 1)  :
                        <></>
                }
            </td>
            { props.s3_object.Uploaded ?
                <td className="text-right">Uploaded</td> :
                <td className="td-actions text-right">
                    <Button
                        className="btn-link mr-1"
                        color="info"
                        data-toggle="tooltip"
                        data-s3_object_id={props.s3_object.id}
                        data-s3_object_key={props.s3_object.Key}
                        id={"tooltip"+props.s3_object.id}
                        onClick={props.onImportHandle}
                        size="sm"
                        type="button"
                    >
                        <i
                            className="fas fa-file-import"
                            data-s3_object_id={props.s3_object.id}
                            data-s3_object_key={props.s3_object.Key}
                        />
                    </Button>
                    <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target={"tooltip"+props.s3_object.id}
                    >
                        Import as reference
                    </UncontrolledTooltip>
                </td>
            }
        </tr>
    )
}

export default S3TableEntry