import React from "react";

function PsnrCell(props) {
    return (
        <>
            { props.distorted ?
                <td>
                    {
                        props.distorted.metric_psnr ?
                            props.distorted.metric_psnr + ' dB'
                            :
                            ''
                    }
                </td> : <td/> }
        </>
    );
}

export default PsnrCell;
