import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  NavbarBrand,
  Navbar, NavItem, NavLink,
  Container, Collapse, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from "reactstrap";
// core components
import { AmplifySignOut } from '@aws-amplify/ui-react';
import { Auth } from "aws-amplify";


function ColorNavbar(props) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [adminVisible, setAdminVisible] = React.useState(false);
  // const [mySubscriptions, setMySubscriptions] = React.useState([])
  const [myTeams, setMyTeams] = React.useState([])
  const [s3Bucket, setS3Bucket] = React.useState("")

  React.useEffect(() => {
    async function getMyAccessRights() {
      const user = await Auth.currentAuthenticatedUser()
      if (user.signInUserSession.idToken.payload['cognito:groups']){
        if (user.signInUserSession.idToken.payload['cognito:groups'].includes("SystemAdmin"))
          setAdminVisible(true)
      }
    }
    getMyAccessRights().then()
  });
  // console.log(props.account)
  React.useEffect(() => {
    if (props.account){
      // setMySubscriptions(props.account.subscriptions.items)
      setMyTeams(props.account.teams.items)
      setS3Bucket(props.account.s3_bucket)
    }
  },[props.account]);


  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
    const updateNavbarColor = () => {
      if (
          document.documentElement.scrollTop > 499 ||
          document.body.scrollTop > 499
      ) {
        setNavbarColor("");
      } else if (
          document.documentElement.scrollTop < 500 ||
          document.body.scrollTop < 500
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

/*
  let tableSubscriptions = mySubscriptions.map((item) =>
      <DropdownItem
          to={"/subscription/"+item.subscription.id}
          tag={Link}
          key={item.subscription.id}>
        {item.subscription.name}
      </DropdownItem>)
*/

  let tableTeams = myTeams.map((item) =>
      <DropdownItem
          to={"/team/"+item.team.id}
          tag={Link}
          key={item.team.id}>
        {item.team.name}
      </DropdownItem>)

  return (
      <>
        {bodyClick ? (
            <div
                id="bodyClick"
                onClick={() => {
                  document.documentElement.classList.toggle("nav-open");
                  setBodyClick(false);
                  setCollapseOpen(false);
                }}
            />
        ) : null}
        <Navbar
            className={classnames("fixed-top", navbarColor)}
            expand="lg"
            id="navbar-main"
        >
          <Container>
            <div className="navbar-translate">
              <NavbarBrand id="navbar-brand" to="/synamedia" tag={Link}>
                Synamedia
              </NavbarBrand>
            </div>
            <Collapse navbar isOpen={collapseOpen}>
              <Nav className="ml-auto" navbar>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle className="mr-2" color="default" caret nav>
                    References
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-danger" right>
                    <DropdownItem header>
                      VQaaS Storage
                    </DropdownItem>
                    <DropdownItem to="/media-page" tag={Link}>
                      My References
                    </DropdownItem>
                    { s3Bucket ?
                        <>
                          <DropdownItem header>
                            Cross account S3 bucket
                          </DropdownItem>
                          <DropdownItem to="/s3-page" tag={Link}>
                            {s3Bucket}
                          </DropdownItem>
                        </> : <></>
                    }
{/*
                    {mySubscriptions.length ?
                        <DropdownItem header>
                          Subscriptions
                        </DropdownItem> : <></>
                    }
                    {tableSubscriptions}
*/}
                    {myTeams.length ?
                        <DropdownItem header>
                          Teams
                        </DropdownItem> : <></>}
                    {tableTeams}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle className="mr-2" color="default" caret nav>
                    Account
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-danger" right>
                    <DropdownItem to="/profile-page" tag={Link}>
                      <i className="nc-icon nc-single-02" />
                      Profile
                    </DropdownItem>
{/*
                    <DropdownItem to="/manage-subscription" tag={Link}>
                      <i className="nc-icon nc-money-coins" />
                      Credits
                    </DropdownItem>
*/}
                  </DropdownMenu>
                </UncontrolledDropdown>
                {adminVisible ?
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle className="mr-2" color="default" caret nav>
                        Admin
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-danger" right>
                        <DropdownItem header>
                          Account
                        </DropdownItem>
                        <DropdownItem to="/dashboard-accounts" tag={Link}>
                          <i className="nc-icon nc-settings" />
                          Dashboard
                        </DropdownItem>
                        <DropdownItem to="/list-accounts" tag={Link}>
                          <i className="nc-icon nc-settings" />
                          List
                        </DropdownItem>
                        <DropdownItem header>
                          Subscription
                        </DropdownItem>
                        <DropdownItem to="/add-subscription" tag={Link}>
                          <i className="nc-icon nc-settings" />
                          Create
                        </DropdownItem>
                        <DropdownItem header>
                          Team
                        </DropdownItem>
                        <DropdownItem to="/add-team" tag={Link}>
                          <i className="nc-icon nc-settings" />
                          Create
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown> : <></>}
                <NavItem>
                  <NavLink href="/help.pdf">Help</NavLink>
                </NavItem>
              </Nav>
            </Collapse>
            <AmplifySignOut/>
          </Container>
        </Navbar>
      </>
  );
}

export default ColorNavbar;
