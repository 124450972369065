import React, {useState} from "react";

// reactstrap components
import {
    Button,
    FormGroup,
    Input,
    Container,
    Row,
    Col, Label, Form,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import {v4 as uuid} from "uuid";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import MediaPageHeader from "../../components/Headers/MediaPageHeader";
import {API, Auth, graphqlOperation} from "aws-amplify";
import {listAccounts} from "../../graphql/queries";
import {createVQaaSSubscription, createAccountSubscription} from "../../graphql/mutations";
import {useHistory} from "react-router-dom";
const getAccountWithSubscriptionsTeams = /* GraphQL */ `
    query GetAccount($id: ID!) {
      getAccount(id: $id) {
        id
        email
        owner
        s3_bucket
        subscriptions {
          items {
            subscription {
              name
              id
            }
          }
        }
        teams {
          items {
            team {
              name
              id
            }
          }
        }
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        credits
        createdAt
        updatedAt
      }
    }
  `;

function AddSubscription() {
    const [account, setAccount] = useState();

    React.useEffect(() => {
        async function getMyAccount() {
            const user = await Auth.currentAuthenticatedUser()
            const result = await API.graphql(graphqlOperation(getAccountWithSubscriptionsTeams, {
                id: user.username
            }))
            setAccount(result.data.getAccount)
        }
        getMyAccount().then()
    }, [])

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [accounts, setAccounts] = useState([])

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("add-product");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("add-product");
        };
    }, []);

    let history = useHistory();

    React.useEffect(() => {
        async function getAllAccounts() {
            let nextToken = null;
            let account_list = []
            do {
                let chunk = await API.graphql(graphqlOperation(listAccounts, {limit: 999}));
                account_list = account_list.concat(chunk.data.listAccounts.items)
                nextToken = chunk.nextToken;
            } while (nextToken != null);
            setAccounts(account_list)
        }
        getAllAccounts().then()
    }, [])

    function handleChange(item_id){
        setAccounts(prevState => {
            return prevState.map(item => {
                if (item.id === item_id) {
                    item.checked = !item.checked
                }
                return item
            })
        })
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        const addSubscription = {
            id: uuid(),
            name: name,
            description: description,
        }
        let result = await API.graphql({ query: createVQaaSSubscription, variables: { input: addSubscription } });
        for (let i=0; i < accounts.length; i++){
            if (accounts[i].checked){
                const addAccountSubscription = {
                    id: uuid(),
                    accountID: accounts[i].id,
                    subscriptionID: result.data.createVQaaSSubscription.id
                }
                await API.graphql({ query: createAccountSubscription, variables: { input: addAccountSubscription } });
            }
        }
        history.goBack()
    }

    let tableSubscribers = accounts.map((item) =>
        <li key={item.id}>
            <Row>
                <Col className="ml-auto mr-auto" lg="2" md="4" xs="4">
                    <img
                        alt="..."
                        className="img-circle img-no-padding img-responsive"
                        src={require("assets/img/faces/clem-onojeghuo-3.jpg")}
                    />
                </Col>
                <Col className="ml-auto mr-auto" lg="7" md="4" xs="4">
                    <h6>
                        {item.first_name + " " + item.last_name} <br />
                        <small>{item.company_name}</small>
                    </h6>
                </Col>
                <Col className="ml-auto mr-auto" lg="3" md="4" xs="4">
                    <FormGroup  check>
                        <Label check>
                            <Input
                                value={item.checked}
                                type="checkbox"
                                onChange={() => handleChange(item.id)}
                            />
                            <span className="form-check-sign"/>
                        </Label>
                    </FormGroup>
                </Col>
            </Row>
        </li>)

    return (
        <>
            <ColorNavbar account={account}/>
            <MediaPageHeader />
            <div className="main">
                <div className="section">
                    <Container>
                        <h3>Create Subscription</h3>
                        <div>
                            <Form
                                className="settings-form"
                                onSubmit={handleSubmit}>
                                <Row>
                                    <Col md="5" sm="5">
                                        <h5>Photo</h5>
                                        <ImageUpload />
                                    </Col>
                                    <Col md="7" sm="7">
                                        <FormGroup>
                                            <h5>
                                                Name <span className="icon-danger">*</span>
                                            </h5>
                                            <Input
                                                className="border-input"
                                                placeholder="enter the subscription name here..."
                                                onChange={e => setName(e.target.value)}
                                                value={name}
                                                type="text"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <h5>Description</h5>
                                            <Input
                                                className="textarea-limited"
                                                maxLength="150"
                                                placeholder="This is a textarea limited to 150 characters."
                                                onChange={e => setDescription(e.target.value)}
                                                value={description}
                                                rows="6"
                                                type="textarea"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col md="12" sm="12">
                                        <h5>
                                            Subscribers
                                        </h5>
                                        <Row>
                                            <Col className="ml-auto mr-auto" md="4">
                                                <ul className="list-unstyled follows">
                                                    {tableSubscribers}
                                                </ul>
                                            </Col>
                                            <Col className="ml-auto mr-auto" md="4">
                                            </Col>
                                            <Col className="ml-auto mr-auto" md="4">
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="buttons-row">
                                    <Col md="4" sm="4">
                                        <Button
                                            block
                                            className="btn-round"
                                            color="danger"
                                            outline
                                            type="reset"
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col md="4" sm="4">
                                        <Button
                                            block
                                            className="btn-round"
                                            color="primary"
                                            outline
                                            type="submit"
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Container>
                </div>
            </div>
            <FooterBlack />
        </>
    );
}

export default AddSubscription;
