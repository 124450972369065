import React from "react";

function DurationTimeCell(props) {
    return (
        <>
            { props.distorted ?
                <td>
                    {
                        props.distorted.spec_duration_time ?
                            props.distorted.spec_duration_time + ' s'
                            :
                            ''
                    }
                </td> : <td/>
            }
        </>
    );
}

export default DurationTimeCell;
