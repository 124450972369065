import React from "react";

// reactstrap components
import {
    Button,
    Progress,
    Table,
    UncontrolledTooltip,
} from "reactstrap";
import {Storage} from "aws-amplify";


function ReferenceProcessing(props) {
    const [signedUrlExtractES, setSignedUrlExtractES] = React.useState(null)
    const [signedUrlDecode, setSignedUrlDecode] = React.useState(null)
    const [signedUrlExtractFeat, setSignedUrlExtractFeat] = React.useState(null)
    const [signedUrlCreateHLS, setSignedUrlCreateHLS] = React.useState(null)

    React.useEffect(()=> {
        async function getAWSUrl() {
            if (props.RefProc.progress_extract_es === 100) {
                const fileAccessURLReference = await Storage.get(
                    props.RefProc.id + "/reference.es.txt",
                    {
                        level: 'protected',
                        identityId: props.RefProc.user_identity_id,
                        expires: 21600 });
                setSignedUrlExtractES(fileAccessURLReference);
            }
        }
        getAWSUrl()
    }, [props.RefProc.id, props.RefProc.progress_extract_es, props.RefProc.user_identity_id])

    React.useEffect(()=> {
        async function getAWSUrl() {
            if (props.RefProc.progress_decode === 100) {
                const fileAccessURLReference = await Storage.get(
                    props.RefProc.id + "/reference.decode.txt",
                    {
                        level: 'protected',
                        identityId: props.RefProc.user_identity_id,
                        expires: 21600 });
                setSignedUrlDecode(fileAccessURLReference);
            }
        }
        getAWSUrl()
    }, [props.RefProc.id, props.RefProc.progress_decode, props.RefProc.user_identity_id])

    React.useEffect(()=> {
        async function getAWSUrl() {
            if (props.RefProc.progress_extract_feat === 100) {
                const fileAccessURLReference = await Storage.get(
                    props.RefProc.id + "/reference.extract_feature.txt",
                    {
                        level: 'protected',
                        identityId: props.RefProc.user_identity_id,
                        expires: 21600 });
                setSignedUrlExtractFeat(fileAccessURLReference);
            }
        }
        getAWSUrl()
    }, [props.RefProc.id, props.RefProc.progress_extract_feat, props.RefProc.user_identity_id])

    React.useEffect(()=> {
        async function getAWSUrl() {
            if (props.RefProc.progress_create_hls === 100) {
                const fileAccessURLReference = await Storage.get(
                    props.RefProc.id + "/reference.hls.txt",
                    {
                        level: 'protected',
                        identityId: props.RefProc.user_identity_id,
                        expires: 21600 });
                setSignedUrlCreateHLS(fileAccessURLReference);
            }
        }
        getAWSUrl()
    }, [props.RefProc.id, props.RefProc.progress_create_hls, props.RefProc.user_identity_id])

    return (
        <>
            <h4 className="title">
                <small>Processing</small>
            </h4>
            <Table responsive>
                <thead>
                <tr>
                    <th>Process</th>
                    <th>Progress</th>
                    <th/>
                    <th className="text-right">Log</th>
                </tr>
                </thead>
                <tbody>
                {props.RefProc.progress_cross_copy ?
                    <tr>
                        <td>Cross Account S3 copy</td>
                        <td> {props.RefProc.progress_cross_copy === 100 ? "Completed" :
                            <Progress
                                animated
                                max="100"
                                value={props.RefProc.progress_cross_copy ? props.RefProc.progress_cross_copy : 0}
                                barClassName="progress-bar-success"/>
                        }
                        </td>
                        <td>{props.RefProc.progress_cross_copy ? props.RefProc.progress_cross_copy + "%": ""}</td>
                        <td/>
                    </tr> : <></> }
                {props.RefProc.progress_extract_es ?
                <tr>
                    <td>Extract Elementary stream</td>
                    <td> {props.RefProc.progress_extract_es === 100 ? "Completed" :
                        <Progress
                            animated
                            max="100"
                            value={props.RefProc.progress_extract_es ? props.RefProc.progress_extract_es : 0}
                            barClassName="progress-bar-success"/>
                    }
                    </td>
                    <td>{props.RefProc.progress_extract_es ? props.RefProc.progress_extract_es + "%": ""}</td>
                    <td className="td-actions text-right">
                        {
                            (props.RefProc.progress_extract_es === 100) ?
                                <a href={signedUrlExtractES} target="_blank" rel="noopener noreferrer">
                                    <Button
                                        className="btn-link mr-1"
                                        color="success"
                                        data-toggle="tooltip"
                                        id="tooltip_es_378266693"
                                        size="sm"
                                        type="button"
                                    >
                                        <i className="fa fa-file"/>
                                    </Button>
                                    <UncontrolledTooltip
                                        delay={0}
                                        placement="top"
                                        target="tooltip_es_378266693"
                                    >
                                        View Log
                                    </UncontrolledTooltip>
                                </a>
                                :
                                ""
                        }
                    </td>
                </tr> : <></> }
                {props.RefProc.progress_decode ?
                <tr>
                    <td>Decode</td>
                    <td> {props.RefProc.progress_decode === 100 ? "Completed" :
                        <Progress
                            animated
                            max="100"
                            value={props.RefProc.progress_decode ? props.RefProc.progress_decode : 0}
                            barClassName="progress-bar-success"/>
                    }
                    </td>
                    <td>{props.RefProc.progress_decode ? props.RefProc.progress_decode + "%": ""}</td>
                    <td className="td-actions text-right">
                        {
                            (props.RefProc.progress_decode === 100) ?
                                <a href={signedUrlDecode} target="_blank" rel="noopener noreferrer">
                                    <Button
                                        className="btn-link mr-1"
                                        color="success"
                                        data-toggle="tooltip"
                                        id="tooltip_decode_378266693"
                                        size="sm"
                                        type="button"
                                    >
                                        <i className="fa fa-file"/>
                                    </Button>
                                    <UncontrolledTooltip
                                        delay={0}
                                        placement="top"
                                        target="tooltip_decode_378266693"
                                    >
                                        View Log
                                    </UncontrolledTooltip>
                                </a>
                                :
                                ""
                        }
                    </td>
                </tr> : <></> }
                {props.RefProc.progress_extract_feat ?
                <tr>
                    <td>Extract features</td>
                    <td>{props.RefProc.progress_extract_feat === 100 ? "Completed" :
                        <Progress
                            animated
                            max="100"
                            value={props.RefProc.progress_extract_feat ? props.RefProc.progress_extract_feat : 0}
                            barClassName="progress-bar-success"/>
                    }
                    </td>
                    <td>{props.RefProc.progress_extract_feat ? props.RefProc.progress_extract_feat + "%": ""}</td>
                    <td className="td-actions text-right">
                        {
                            (props.RefProc.progress_extract_feat === 100) ?
                                <a href={signedUrlExtractFeat} target="_blank" rel="noopener noreferrer">
                                    <Button
                                        className="btn-link mr-1"
                                        color="success"
                                        data-toggle="tooltip"
                                        id="tooltip_extract_378266693"
                                        size="sm"
                                        type="button"
                                    >
                                        <i className="fa fa-file" />
                                    </Button>
                                    <UncontrolledTooltip
                                        delay={0}
                                        placement="top"
                                        target="tooltip_extract_378266693"
                                    >
                                        View Log
                                    </UncontrolledTooltip>
                                </a>
                                :
                                ""
                        }
                    </td>
                </tr> : <></> }
                {props.RefProc.progress_create_hls ?
                <tr>
                    <td>Create HLS stream</td>
                    <td>{props.RefProc.progress_create_hls === 100 ? "Completed" :
                        <Progress
                            animated
                            max="100"
                            value={props.RefProc.progress_create_hls ? props.RefProc.progress_create_hls : 0}
                            barClassName="progress-bar-success"/>
                    }
                    </td>
                    <td>{props.RefProc.progress_create_hls ? props.RefProc.progress_create_hls + "%": ""}</td>
                    <td className="td-actions text-right">
                        {
                            (props.RefProc.progress_create_hls === 100) ?
                                <a href={signedUrlCreateHLS} target="_blank" rel="noopener noreferrer">
                                    <Button
                                        className="btn-link mr-1"
                                        color="success"
                                        data-toggle="tooltip"
                                        id="tooltip_hls_378266693"
                                        size="sm"
                                        type="button"
                                    >
                                        <i className="fa fa-file" />
                                    </Button>
                                    <UncontrolledTooltip
                                        delay={0}
                                        placement="top"
                                        target="tooltip_hls_378266693"
                                    >
                                        View Log
                                    </UncontrolledTooltip>
                                </a>
                                :
                                ""
                        }
                    </td>
                </tr> : <></> }
                </tbody>
            </Table>
        </>
    );
}

export default ReferenceProcessing;
