import React, { useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';


function CumulativeDistributionChart(props) {
    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'spline',
            zoomType: 'x',
            panning: true,
            panKey: 'shift',
            animation: false
        },
        series: [
            { data: [], color: '#3380FF' }
        ],
        xAxis: {
            title: {
                text: ''
            }
        },
        yAxis: {
            max: 100,
            title: {
                text: 'Probability [%]'
            }
        },
        legend: {
            enabled: true
        },
        title: {
            text: 'VMAF'
        },
        subtitle: {
            text: 'Cumulative Distribution: '
        },
        plotOptions: {
            series: {
                animation: false,
                marker:{enabled: false}
            }
        }
    });


    React.useEffect(() => {
        setChartOptions(prevChartOptions => {
            let updatedChartOptions = {...prevChartOptions}
            updatedChartOptions.title.text = props.metric
            updatedChartOptions.subtitle.text =
                'Cumulative Distribution: y% of the frames have a ' + props.metric + ' lower than x'
            updatedChartOptions.xAxis.title.text = props.metric + ' score'
            updatedChartOptions.series = []
            if (props.distorted1){
                let dis1_series = []
                if (props.metric === 'VMAF'){
                    for (let i=0; i < props.distorted1.metric_vmaf_bin.length; i++){
                        dis1_series = dis1_series.concat(
                            [[props.distorted1.metric_vmaf_bin[i], props.distorted1.metric_vmaf_cdf[i]]]
                        )
                    }
                }
                if (props.metric === 'PSNR'){
                    for (let i=0; i < props.distorted1.metric_psnr_bin.length; i++){
                        dis1_series = dis1_series.concat(
                            [[props.distorted1.metric_psnr_bin[i], props.distorted1.metric_psnr_cdf[i]]]
                        )
                    }
                }
                updatedChartOptions.series = updatedChartOptions.series.concat(
                    {
                        name: props.distorted1.filename,
                        data: dis1_series,
                        color: '#000000'
                    })
            }
            if (props.distorted2){
                let dis2_series = []
                if (props.metric === 'VMAF') {
                    for (let i = 0; i < props.distorted2.metric_vmaf_bin.length; i++) {
                        dis2_series = dis2_series.concat(
                            [[props.distorted2.metric_vmaf_bin[i], props.distorted2.metric_vmaf_cdf[i]]]
                        )
                    }
                }
                if (props.metric === 'PSNR'){
                    for (let i=0; i < props.distorted2.metric_psnr_bin.length; i++){
                        dis2_series = dis2_series.concat(
                            [[props.distorted2.metric_psnr_bin[i], props.distorted2.metric_psnr_cdf[i]]]
                        )
                    }
                }
                updatedChartOptions.series = updatedChartOptions.series.concat(
                    {
                        name: props.distorted2.filename,
                        data: dis2_series,
                        color: '#FFB400'
                    })
            }
            if (props.distorted3){
                let dis3_series = []
                if (props.metric === 'VMAF') {
                    for (let i = 0; i < props.distorted3.metric_vmaf_bin.length; i++) {
                        dis3_series = dis3_series.concat(
                            [[props.distorted3.metric_vmaf_bin[i], props.distorted3.metric_vmaf_cdf[i]]]
                        )
                    }
                }
                if (props.metric === 'PSNR'){
                    for (let i=0; i < props.distorted3.metric_psnr_bin.length; i++){
                        dis3_series = dis3_series.concat(
                            [[props.distorted3.metric_psnr_bin[i], props.distorted3.metric_psnr_cdf[i]]]
                        )
                    }
                }
                updatedChartOptions.series = updatedChartOptions.series.concat(
                    {
                        name: props.distorted3.filename,
                        data: dis3_series,
                        color: '#3380FF'
                    })
            }
            if (props.distorted4){
                let dis4_series = []
                if (props.metric === 'VMAF') {
                    for (let i = 0; i < props.distorted4.metric_vmaf_bin.length; i++) {
                        dis4_series = dis4_series.concat(
                            [[props.distorted4.metric_vmaf_bin[i], props.distorted4.metric_vmaf_cdf[i]]]
                        )
                    }
                }
                if (props.metric === 'PSNR'){
                    for (let i=0; i < props.distorted4.metric_psnr_bin.length; i++){
                        dis4_series = dis4_series.concat(
                            [[props.distorted4.metric_psnr_bin[i], props.distorted4.metric_psnr_cdf[i]]]
                        )
                    }
                }
                updatedChartOptions.series = updatedChartOptions.series.concat(
                    {
                        name: props.distorted4.filename,
                        data: dis4_series,
                        color: '#ff3333'
                    })
            }
            return updatedChartOptions
        })
    }, [props])


    return(
        <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
        />
    )
}

export default CumulativeDistributionChart;