import React, {useState} from "react";
// reactstrap components
import {
    Container,
    Row,
    Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import MediaPageHeader from "components/Headers/MediaPageHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import {API, Auth, graphqlOperation} from "aws-amplify";
import ReferenceTable from "../../components/vqaas/ReferenceTable";
import {useParams} from "react-router-dom";
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
const getAccountWithSubscriptionsTeamsS3 = /* GraphQL */ `
    query GetAccount($id: ID!) {
      getAccount(id: $id) {
        id
        email
        owner
        s3_bucket
        s3_bucket_region
        subscriptions {
          items {
            subscription {
              name
              id
            }
          }
        }
        teams {
          items {
            team {
              name
              id
            }
          }
        }
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        cross_account_s3 {
          items {
            id
            Key
            ETag
            Size
            LastModified
            LastSeen
            accountID
            referenceID
            createdAt
            updatedAt
          }
          nextToken
        }
        credits
        createdAt
        updatedAt
      }
    }
  `;

export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      references {
        items {
          accountID
          createdAt
          cross_account_s3_id
          description
          filename
          viewers
          id
          owner
          progress_create_hls
          progress_decode
          progress_deploy
          progress_extract_es
          progress_extract_feat
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          updatedAt
          user_identity_id
        }
        nextToken
      }
    }
  }
`;

function TeamPage() {
    const { team_id } = useParams()
    // console.log("Team id", team_id)
    const [account, setAccount] = useState();
    const [accounts, setAccounts] = useState([]);
    const [teamReferences, setTeamReferences] = useState([]);
    const [teamName, setTeamName] = useState("");


    function dynamicSort(property) {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            /* next line works with strings and numbers,
             * and you may want to customize it to your needs
             */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    React.useEffect(() => {
        async function getMyAccount() {
            const user = await Auth.currentAuthenticatedUser()
            const result = await API.graphql(graphqlOperation(getAccountWithSubscriptionsTeamsS3, {
                id: user.username
            }))
            setAccount(result.data.getAccount)
        }
        getMyAccount().then()
    }, [])

    React.useEffect(() => {
        async function listTeamAccounts() {
            let nextToken = null;
            let account_list = []
            let team_name = ""
            do {
                const result = await API.graphql(graphqlOperation(getTeam, {
                    id: team_id
                }))
                console.log("getTeam", result)
                nextToken = result.data.getTeam.accounts.nextToken
                team_name = result.data.getTeam.name
                account_list = account_list.concat(result.data.getTeam.accounts.items)
            } while (nextToken != null);
            // console.log("Team ref", account_list)
            setAccounts(account_list)
            setTeamName(team_name)
        }
        listTeamAccounts().then()
    }, [team_id])

    React.useEffect(() => {
        async function listTeamReferences() {
            let reference_list = []
            for (let i = 0; i < accounts.length; i++) {
                let nextToken = null;
                do {
                    const result = await API.graphql(graphqlOperation(getAccount, {
                        id: accounts[i].accountID
                    }))
                    console.log(result)
                    nextToken = result.data.getAccount.references.nextToken
                    reference_list = reference_list.concat(result.data.getAccount.references.items)
                } while (nextToken != null);
                console.log("Team account", reference_list)
                setTeamReferences(reference_list.sort(dynamicSort("filename")))
            }
        }
        if (accounts){
            listTeamReferences().then()
        }
    }, [accounts])

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("media-page");
        return function cleanup() {
            document.body.classList.remove("media-page");
        };
    });

    return (
        <>
            <ColorNavbar account={account} />
            <MediaPageHeader />
            <div className="section">
                { teamName ?
                    <Container className="tim-container">
                        <div className="title">
                            <h3 id="ReferenceLibrary">{teamName} Team</h3>
                        </div>
                        <h4 >References</h4>
                        <Row>
                            <Col className="ml-auto mr-auto" md="12">
                                <ReferenceTable references={teamReferences}/>
                            </Col>
                        </Row>
                    </Container> : <></>
                }
            </div>
            <FooterBlack />
        </>
    );
}

export default TeamPage;
