import React from "react";
import ReactPlayer from 'react-player'
import {Storage} from "aws-amplify"
import {Progress} from "reactstrap";


function StreamPlayer(props) {
    const playerReference = React.useRef()
    const [signedUrlReference, setSignedUrlReference] = React.useState(null)
    const [loaded, setLoaded] = React.useState(0)
    const [played, setPlayed] = React.useState(0)

    React.useEffect(()=> {
        async function getAWSUrl() {
            if (props.streamPlayerData.progress_create_hls === 100) {
                const fileAccessURLReference = await Storage.get(
                    props.streamPlayerData.id + "/360_" + props.refOrDis + "_out_signed.m3u8",
                    {
                        level: 'protected',
                        identityId: props.streamPlayerData.user_identity_id,
                        expires: 600 });
                setSignedUrlReference(fileAccessURLReference);
            }
        }
        getAWSUrl()
    }, [props.streamPlayerData.id, props.streamPlayerData.progress_create_hls, props.refOrDis, props.streamPlayerData.user_identity_id])

    const handleOnProgress = async (e) => {
        setLoaded(e.loaded)
        setPlayed(e.played)
    }

    return (
        <>
            <h4 className="title">
                <small>View HLS stream</small>
            </h4>
            <div className='player-wrapper'>
                <ReactPlayer
                    width='100%'
                    height='100%'
                    url={signedUrlReference}
                    className="react-player"
                    ref={playerReference}
                    pip={false}
                    playing={false}
                    controls={false}
                    light={false}
                    loop={false}
                    playbackRate={1.0}
                    onStart={() => console.log('onStart')}
                    onProgress={handleOnProgress}
                    onPlay={() => {}}
                    onEnablePIP={() => {}}
                    onDisablePIP={() => console.log('handleDisablePIP')}
                    onPause={() => console.log('handlePause')}
                    onBuffer={() => console.log('onBuffer')}
                    onSeek={e => console.log('onSeek', e)}
                    onEnded={() => {}}
                    onError={e => console.log('onError', e)}
                    onDuration={() => {}}
                />
            </div>
            <div>
                Loaded
                <Progress
                    animated
                    max="1"
                    value={loaded}
                    barClassName="progress-bar-success"/>
            </div>
            <div>
                Played
                <Progress
                    animated
                    max="1"
                    value={played}
                    barClassName="progress-bar-success"/>
            </div>
            <br/>
        </>
    )
}

export default StreamPlayer;
