import React, {useState} from "react";

// reactstrap components
import {
    Button,
    Table,
} from "reactstrap";

import DistortedTableEntry from "../../components/vqaas/DistortedTableEntry";
import {API, Auth, graphqlOperation} from "aws-amplify";
import {listDistorteds} from "../../graphql/queries";
import {onCreateDistorted, onDeleteDistorted, onUpdateDistortedRefId} from "../../graphql/subscriptions";
import {Link} from "react-router-dom";

function DistortedTable(props) {
    const [distorteds, setDistorteds] = useState([])
    const [showCompare, setShowCompare] = useState(false)
    const [ compareParams, setCompareParams] = useState("")

    React.useEffect(() => {
        async function listMyDistorteds() {
            const result = await API.graphql(graphqlOperation(listDistorteds, {
                filter: {'reference_id': {'eq': props.reference_id}},
                limit: 999,
            }))
            setDistorteds(result.data.listDistorteds.items)
        }
        listMyDistorteds()
    }, [props.reference_id])


    React.useEffect(() => {
        let subscription
        let mounted = true
        async function setupSubscription() {
            const user = await Auth.currentAuthenticatedUser()
            subscription = API.graphql(graphqlOperation(onUpdateDistortedRefId, {
                reference_id: props.reference_id, owner: user.username})).subscribe(
                {next: (data) =>
                    {
                        if (mounted){
                            let distorted = data.value.data.onUpdateDistortedRefId
                            if (distorted == null) return
                            if (distorted.reference_id !== props.reference_id) return
                            setDistorteds(prevState => {
                                return prevState.map(prevDistorted => {
                                    if (prevDistorted.id === distorted.id) {
                                        distorted.compare = prevDistorted.compare
                                        return distorted
                                    } else {
                                        return prevDistorted
                                    }
                                })
                            })
                        }
                    }
                })
        }
        setupSubscription().then()
        return () => {
            mounted = false
            subscription.unsubscribe()
        }
    }, [props.reference_id])

    React.useEffect(() => {
        let subscription
        let mounted = true
        async function setupSubscription() {
            const user = await Auth.currentAuthenticatedUser()
            subscription = API.graphql(graphqlOperation(onDeleteDistorted, {owner: user.username})).subscribe(
                {next: (data) =>
                    {
                        if (mounted){
                            let distorted = data.value.data.onDeleteDistorted
                            if (!distorted) return
                            if (distorted.reference_id !== props.reference_id) return
                            setDistorteds(prevDistorted => [...prevDistorted.filter(r => {
                                return (
                                    r.id !== distorted.id
                                )
                            })])
                        }
                    }
                })
        }
        setupSubscription().then()
        return () => {
            mounted = false
            subscription.unsubscribe()
        }
    }, [props.reference_id])

    React.useEffect(() => {
        let subscription
        let mounted = true
        async function setupSubscription() {
            const user = await Auth.currentAuthenticatedUser()
            subscription = API.graphql(
                graphqlOperation(
                    onCreateDistorted,
                    {owner: user.username})).subscribe(
                        {
                            next: (data) => {
                                if (mounted){
                                    let distorted = data.value.data.onCreateDistorted
                                    if (!distorted) return
                                    if (distorted.reference_id !== props.reference_id) return
                                    distorted.compare = false
                                    setDistorteds(prevDistorteds => prevDistorteds.concat([distorted]))
                                }
                            }
                        })
        }
        setupSubscription().then()
        return () => {
            mounted = false
            subscription.unsubscribe()
        }
    }, [props.reference_id])


    function handleChange(distorted_id){
        setDistorteds(prevState => {
            return prevState.map(distorted => {
                if (distorted.id === distorted_id) {
                    distorted.compare = !distorted.compare
                }
                return distorted
            })
        })
    }

    React.useEffect(() => {
        let total_compare = 0
        let new_compare_params = ""
        const ordered_distorted = [...distorteds].sort((a,b) => (a.metric_vmaf > b.metric_vmaf) ? 1 : ((b.metric_vmaf > a.metric_vmaf) ? -1 : 0))
        for (var i=0; i < ordered_distorted.length; i++){
            if (ordered_distorted[i].compare){
                total_compare += 1
                new_compare_params += "/" + ordered_distorted[i].id
            }
        }
        setShowCompare(total_compare > 1)
        setCompareParams(new_compare_params)
    }, [distorteds])

    const tableEntries = distorteds.map(distortedData =>
        <DistortedTableEntry
            key={distortedData.id}
            distorted={distortedData}
            reference_id = {props.reference_id}
            handleChange = {handleChange}/>
            )
    return (
        <>
            <h4 className="title">
                <small>Distorted videos with reference as source</small>
            </h4>
            <Table responsive>
                <thead>
                <tr>
                    <th className="text-center">#</th>
                    <th>Compare</th>
                    <th>Filename</th>
                    <th>Resolution</th>
                    <th>Framerate</th>
                    <th className="text-right">Actions</th>
                </tr>
                </thead>
                <tbody>
                {tableEntries}
                {(showCompare) ?
                    <tr className="tr-actions">
                        <td colSpan="4"/>
                        <td className="text-right" colSpan="2">
                            <Link to={"/compare/" + props.reference_id + compareParams} >
                                <Button color="danger" size="lg" type="button">
                                    Compare <i className="fa fa-chevron-right"/>
                                </Button>
                            </Link>
                        </td>
                    </tr> :
                    <tr/>
                }
                </tbody>
            </Table>
        </>
    );
}

export default DistortedTable;
