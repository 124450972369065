export const getReferenceWithShares = /* GraphQL */ `
  query GetReference($id: ID!) {
    getReference(id: $id) {
      createdAt
      description
      filename
      viewers
      subscriptions {
        items {
          subscription {
            id
            name
          }
        }
      }
      id
      owner
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      updatedAt
      user_identity_id
    }
  }
`;
export const getAccountWithSubscriptionsTeams = /* GraphQL */ `
    query GetAccount($id: ID!) {
      getAccount(id: $id) {
        id
        email
        owner
        s3_bucket
        subscriptions {
          items {
            subscription {
              name
              id
            }
          }
        }
        teams {
          items {
            team {
              name
              id
            }
          }
        }
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        credits
        createdAt
        updatedAt
      }
    }
  `;
export const getAccountWithS3Objects = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      email
      owner
      first_name
      last_name
      company_name
      description
      s3_bucket
      subscriptions {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      teams {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        name
        ownerID
        accepted
      }
      followed_by {
        name
        ownerID
        accepted
      }
      num_references
      num_distorteds
      last_login
      last_upload
      credits
      createdAt
      updatedAt
      cross_account_s3_objects {
        items {
          cross_account_s3_object{
            id
            Key
            ETag
            Size
          }
        }
      }
    }
  }
`;

export const getVQaaSSubscriptionWithReferences = /* GraphQL */ `
  query GetVQaaSSubscription($id: ID!) {
    getVQaaSSubscription(id: $id) {
      id
      name
      description
      references {
        items {
          reference {
              createdAt
              description
              filename
              id
              owner
              progress_create_hls
              progress_decode
              progress_deploy
              progress_extract_es
              progress_extract_feat
              progress_upload
              spec_codec
              spec_duration_frames
              spec_duration_time
              spec_frame_rate
              spec_height
              spec_pid
              spec_pix_fmt
              spec_width
              updatedAt
              user_identity_id
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
