import React from "react";

function ResolutionCell(props) {
  return (
      <>
        <td>
          {
            props.distorted ?
                props.distorted.spec_width + 'x' + props.distorted.spec_height
                :
                ''
          }
        </td>
      </>
  );
}

export default ResolutionCell;
