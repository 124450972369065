import React, {useState} from "react";

// reactstrap components
import {
    Button,
    Row, Col,
    Progress,
} from "reactstrap";

// core components
import {API, Auth, Storage} from "aws-amplify";
import {createReference} from "../../graphql/mutations";

import {v4 as uuid} from 'uuid'

function ReferenceUpload() {
    const refFileInput = React.createRef();
    const [refFilename, setRefFilename] = useState("")
    const [uploading, setUploading] = useState(false)
    const [refUploadProgress, setRefUploadProgress] = useState(0)

    const uploadFile = async (file) => {
        const user = await Auth.currentAuthenticatedUser();
        console.log(user)
        const addReference = {
            id: uuid(),
            owner: user.username,
            filename: file.name,
            accountID: user.username
        }
        await Storage.put(
            addReference.id + "/" + addReference.filename,
            file,
            {
                level: 'protected',
                metadata: {
                    owner: user.username,
                },
                progressCallback(progress) {
                    setRefUploadProgress(Math.floor(100 * progress.loaded / progress.total))
                }
            }
        );
        await API.graphql({ query: createReference, variables: { input: addReference } });
        setRefFilename("")
        setRefUploadProgress(0)
    }

    const onChange = async (e) => {
        setUploading(true)
        setRefFilename(e.target.files.item(0).name)
        let files = [];
        for (var i=0; i<e.target.files.length; i++) {
            files.push(e.target.files.item(i));
        }
        await Promise.all(files.map(f => uploadFile(f)));
        setUploading(false)
    }

    return (
        <Row>
            <Col className="text-left">
                <div className="fileinput text-center">
                    <input
                        type="file"
                        onChange={onChange}
                        ref={refFileInput} />
                    <div>
                        {uploading ? <br/> :
                            <Button
                                className="btn-round"
                                color="default"
                                outline
                                onClick={() => {
                                    refFileInput.current.click();
                                }}
                            >
                                Browse
                            </Button>}
                    </div>
                </div>
            </Col>
            { uploading ?
                <Col className="text-left">
                    {refFilename}
                </Col>
                : <></>
            }
            { uploading ?
                <Col className="text-left">Upload Progress</Col>
                : <></>
            }
            { uploading ?
                <Col colSpan="1">
                    <Progress
                        animated
                        max="100"
                        value={refUploadProgress}
                        barClassName="progress-bar-success"/>
                </Col>
                : <></>
            }
            { uploading ?
                <Col className="text-left">{refUploadProgress}%</Col>
                : <></>
            }
        </Row>
    );
}

export default ReferenceUpload;
