import React, {useState} from "react";

// reactstrap components
import {
    Container,
    Row,
    Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import MediaPageHeader from "components/Headers/MediaPageHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import {API, Auth, graphqlOperation} from "aws-amplify";
import ReferenceTable from "../../components/vqaas/ReferenceTable";
import {useParams} from "react-router-dom";
import {
    getVQaaSSubscriptionWithReferences,
    getAccountWithSubscriptionsTeams
} from "../../graphql/MyQueries"



function SubscriptionPage() {
    const { subscription_id } = useParams()
    const [account, setAccount] = useState();
    const [subscriptionName, setSubscriptionName] = useState("");
    const [references, setReferences] = useState([])

    React.useEffect(() => {
        async function getMyAccount() {
            const user = await Auth.currentAuthenticatedUser()
            const result = await API.graphql(graphqlOperation(getAccountWithSubscriptionsTeams, {
                id: user.username
            }))
            setAccount(result.data.getAccount)
        }
        getMyAccount().then()
    }, [])

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("media-page");
        return function cleanup() {
            document.body.classList.remove("media-page");
        };
    });

    React.useEffect(() => {
        async function listMyReferences() {
            const new_result = await API.graphql(graphqlOperation(getVQaaSSubscriptionWithReferences, {
                id: subscription_id
            }))
            let convList = []
            for (let i=0; i< new_result.data.getVQaaSSubscription.references.items.length; i++){
                convList.push(new_result.data.getVQaaSSubscription.references.items[i].reference)
            }
            setSubscriptionName(new_result.data.getVQaaSSubscription.name)
            setReferences(convList)
        }
        listMyReferences().then()
    }, [subscription_id])

    return (
        <>
            <ColorNavbar account={account} />
            <MediaPageHeader />
            <div className="section">
                <Container className="tim-container">
                    <div className="title">
                        <h3 id="ReferenceLibrary">{subscriptionName} Reference Library</h3>
                    </div>
                    <Row>
                        <Col className="ml-auto mr-auto" md="12">
                            <ReferenceTable references={references}/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <FooterBlack />
        </>
    );
}

export default SubscriptionPage;
