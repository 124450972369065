import React from "react"
import {Button, UncontrolledTooltip} from "reactstrap";
import {Link} from "react-router-dom";
import {API} from "aws-amplify";
import {deleteReference} from "../../graphql/mutations";

function ReferenceTableEntry(props) {
    const onRemoveReference = async () => {
        // const user = await Auth.currentAuthenticatedUser();
        const deleteReferenceInput = {
            id: props.reference.id,
            // owner: user.username,
        }
        await API.graphql({ query: deleteReference, variables: { input: deleteReferenceInput } });
    }

    return (
        <tr>
            <td className="text-center">
                {(props.reference.spec_width || props.reference.progress_cross_copy) ?
                    <Link to={"/reference/" + props.reference.id} >
                        {props.reference.id.slice(0,6)}
                    </Link> :
                    <></>
                }
            </td>
            <td>{props.reference.filename}</td>
            <td>
                {
                    props.reference.spec_width ?
                        props.reference.spec_width + "x" + props.reference.spec_height :
                        <div className="uil-reload-css reload-small mr-1 "><div /></div>
                }
            </td>
            <td>
                {
                    props.reference.spec_frame_rate ?
                        props.reference.spec_frame_rate :
                        <div className="uil-reload-css reload-small mr-1 "><div /></div>
                }
            </td>
            <td className="td-actions text-right">
                {(props.reference.spec_width  || props.reference.progress_cross_copy) ?
                    <>
                        <Link to={"/reference/" + props.reference.id} >
                        <Button
                            className="btn-link mr-1"
                            color="info"
                            data-toggle="tooltip"
                            id={"tooltip_link" + props.reference.id}
                            to={"/reference/" + props.reference.id}
                            size="sm"
                            type="button"
                        >
                            <i className="fas fa-external-link-alt" />
                        </Button>
                        <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target={"tooltip_link" + props.reference.id}
                        >
                            Open Reference
                        </UncontrolledTooltip>
                        </Link>
                        <Button
                            className="btn-link"
                            color="danger"
                            data-toggle="tooltip"
                            id={"tooltip_delete" + props.reference.id}
                            size="sm"
                            type="button"
                            onClick={onRemoveReference}
                        >
                            <i className="fa fa-times" />
                        </Button>
                        <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target={"tooltip_delete" + props.reference.id}
                        >
                            Remove Reference
                        </UncontrolledTooltip>
                    </>: <>Deploying</>}
            </td>
        </tr>
    )
}

export default ReferenceTableEntry