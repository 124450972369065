import React, {useState} from "react";
// reactstrap components
import {
    Button,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
} from "reactstrap";
// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import MediaPageHeader from "components/Headers/MediaPageHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import {API, Auth, graphqlOperation} from "aws-amplify";
import {getAccount} from "../../graphql/queries";
import {updateAccount} from "../../graphql/mutations";
import { Redirect} from "react-router-dom";

const getAccountWithSubscriptionsTeams = /* GraphQL */ `
    query GetAccount($id: ID!) {
      getAccount(id: $id) {
        id
        email
        owner
        s3_bucket
        s3_bucket_region
        subscriptions {
          items {
            subscription {
              name
              id
            }
          }
        }
        teams {
          items {
            team {
              name
              id
            }
          }
        }
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        credits
        createdAt
        updatedAt
      }
    }
  `;

function SettingsPage() {
    const [account, setAccount] = useState();
    const [returnToProfilePage, setReturnToProfilePage] = useState(false);
    const [owner, setOwner] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [email, setEmail] = useState("")
    const [description, setDescription] = useState("")
    const [userIdentityId, setUserIdentityId] = useState("")
    const [s3Bucket, setS3Bucket] = useState("")
    const [s3BucketRegion, setS3BucketRegion] = useState("")

    React.useEffect(() => {
        async function getMyAccount() {
            const user = await Auth.currentAuthenticatedUser()
            const result = await API.graphql(graphqlOperation(getAccountWithSubscriptionsTeams, {
                id: user.username
            }))
            setAccount(result.data.getAccount)
        }
        getMyAccount().then()
    }, [])

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("settings-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("settings-page");
        };
    },[]);

    let bucket_policy = JSON.stringify({
        "Version": "2012-10-17",
        "Statement": [
            {
                "Effect": "Allow",
                "Principal": {
                    "AWS": "arn:aws:iam::017733813652:role/vqaasLambdaRole88c705e0-dev"
                },
                "Action": [
                    "s3:ListBucket",
                    "s3:GetBucketLocation"
                ],
                "Resource": "arn:aws:s3:::" + s3Bucket
            },
            {
                "Effect": "Allow",
                "Principal": {
                    "AWS": "arn:aws:iam::017733813652:user/amplify-vqaas-ccoene"
                },
                "Action": "s3:GetObject",
                "Resource": "arn:aws:s3:::" + s3Bucket + "/*"
            }
        ]
    }, null, 4)

    React.useEffect(() => {
        async function getMyAccount() {
            const user = await Auth.currentAuthenticatedUser()
            const credentials = await Auth.currentCredentials();
            setUserIdentityId(credentials.identityId)
            // console.log("User identity id:",credentials)
            const result = await API.graphql(graphqlOperation(getAccount, {
                id: user.username
            }))
            if (!result.data.getAccount.user_identity_id) {
                const result_update = await API.graphql(
                    { query: updateAccount,
                        variables: {
                            input: {
                                id: user.username,
                                user_identity_id: credentials.identityId
                            } } })
                console.log(result_update)
            }
            setOwner(result.data.getAccount.owner)
            setFirstName(result.data.getAccount.first_name)
            setLastName(result.data.getAccount.last_name)
            setCompanyName(result.data.getAccount.company_name)
            setDescription(result.data.getAccount.description)
            setS3Bucket(result.data.getAccount.s3_bucket)
            setS3BucketRegion(result.data.getAccount.s3_bucket_region)
            setEmail(result.data.getAccount.email)
        }
        getMyAccount().then()
    }, [])

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        const updateMyAccount = {
            id: owner,
            first_name: firstName,
            last_name: lastName,
            company_name: companyName,
            s3_bucket: s3Bucket,
            s3_bucket_region: s3BucketRegion,
            description: description,
            user_identity_id: userIdentityId
        }
        await API.graphql({ query: updateAccount, variables: { input: updateMyAccount } });
        setReturnToProfilePage(true)
        // history.goBack()
    }

    return (
        <>
            { returnToProfilePage ?
                <Redirect to='/profile-page' /> :
                <>
                    <ColorNavbar account={account}/>
                    <div className="wrapper">
                        <MediaPageHeader />
                        <div className="profile-content section">
                            <Container>
                                <Row>
                                    <Col className="ml-auto mr-auto" md="6">
                                        <Form
                                            className="settings-form"
                                            onSubmit={handleSubmit}>
                                            <Row>
                                                <Col md="6" sm="6">
                                                    <FormGroup>
                                                        <label>First Name *</label>
                                                        <Input
                                                            className="border-input"
                                                            onChange={e => setFirstName(e.target.value)}
                                                            value={firstName}
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6" sm="6">
                                                    <FormGroup>
                                                        <label>Last Name *</label>
                                                        <Input
                                                            className="border-input"
                                                            onChange={e => setLastName(e.target.value)}
                                                            value={lastName}
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <FormGroup>
                                                <label>Company name *</label>
                                                <Input
                                                    className="border-input"
                                                    onChange={e => setCompanyName(e.target.value)}
                                                    value={companyName}
                                                    type="text"
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <label>Email</label>
                                                <Input
                                                    className="border-input"
                                                    value={email}
                                                    readOnly
                                                    type="text"
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <label>Description</label>
                                                <Input
                                                    onChange={e => setDescription(e.target.value)}
                                                    value={description}
                                                    className="textarea-limited"
                                                    type="textarea"
                                                    maxLength="150"
                                                    rows="3"
                                                />
                                                <h5>
                                                    <small>
                        <span
                            className="pull-right"
                            id="textarea-limited-message"
                        >
                          150 characters left
                        </span>
                                                    </small>
                                                </h5>
                                            </FormGroup>
                                            <FormGroup>
                                                <label>Cross-account S3 bucket name</label>
                                                <Input
                                                    className="border-input"
                                                    type="text"
                                                    onChange={e => setS3Bucket(e.target.value)}
                                                    value={s3Bucket}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <label>Cross-account S3 bucket region</label>
                                                <Input
                                                    className="border-input"
                                                    type="text"
                                                    onChange={e => setS3BucketRegion(e.target.value)}
                                                    value={s3BucketRegion}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <label>Cross-account S3 bucket policy</label>
                                                <pre >
                                            <code>
                                                {bucket_policy}
                                            </code>
                                        </pre>
                                            </FormGroup>
                                            <Row className="buttons-row">
                                                <Col md="4" sm="4">
                                                    <Button
                                                        block
                                                        className="btn-round"
                                                        color="danger"
                                                        onClick={() => setReturnToProfilePage(true)}
                                                        outline
                                                        type="reset"
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Col>
                                                <Col md="4" sm="4">
                                                    <Button
                                                        block
                                                        className="btn-round"
                                                        color="primary"
                                                        outline
                                                        type="submit"
                                                    >
                                                        Save
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                    <FooterBlack />
                </>}</>
    );
}

export default SettingsPage;
